<template>
  <v-expansion-panel-content>
    <v-card flat tile class="pt-4">
      <FilterEditDialog
        :columns="columns"
        :dataset="dataset"
        @metaRefresh="(value) => $emit('metaRefresh', value)"
        @filters="(value) => $emit('filters', value)"
      />
    </v-card>
  </v-expansion-panel-content>
</template>

<script>
import FilterEditDialog from '@/graphic/components/main/DashboardEditor/dataset/FilterEditDialog.vue';

export default {
  name: 'FiltersConfigStep',
  components: { FilterEditDialog },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    dataset: {
      type: Object,
      required: true,
    },
  },
  methods: {
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped>

</style>
