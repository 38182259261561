import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import it from 'vuetify/lib/locale/it';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { it },
    current: 'it',
  },
  theme: {
    options: {
      customProperties: true,
    },
    light: true,
    themes: {
      light: {
        primary: {
          lighten1: '#cfefe7',
          base: '#4FC0AB',
          darken1: '#F4F4F4',
        },
        secondary: {
          lighten1: '#c7ced8',
          lighten2: '#2f435e',
          base: '#808080',
          darken1: '#FFFFFF',
          darken2: '#1b2540',
          darken3: '#1b2540',
          darken4: '#1b2540',
        },
        iconColor: {
          base: '#727272',
        },
        activeNav: {
          base: '#35d1ad',
        },
        backgroundColor: {
          base: '#F4F4F4',
        },
        cardBackgroundColor: {
          base: '#FFFFFF',
        },
        footerColor: {
          base: '#FFFFFF',
        },
        accent: '#062b5e',
        error: '#FF5252',
        info: '#2196F3',
        missing: '#F57F17',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
  icons: {
    iconfont: 'mdi',
  },
});
