const list = async (userId) => {
  const response = await window.axios.get(`/graphic/dashboard/uid/${userId}`);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const details = async (dashboardId) => {
  const response = await window.axios.get(
    `/graphic/dashboard/${dashboardId}`,
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const create = async (dashboard) => {
  const response = await window.axios.post('/graphic/dashboard', dashboard);
  if (response.status === 201) {
    return response.data;
  }
  return 'error';
};

const clone = async (dashboardId) => {
  const response = await window.axios.post('/graphic/dashboard/clone', dashboardId);
  if (response.status === 201) {
    return response.data;
  }
  return 'error';
};

const update = async (dashboardId, dashboard) => {
  const response = await window.axios.put(
    `/graphic/dashboard/${dashboardId}`,
    dashboard,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 201) {
    return response.data;
  }
  return 'error';
};

const remove = async (dashboardId) => {
  const response = await window.axios.delete(
    `/graphic/dashboard/${dashboardId}`,
  );
  if (response.status === 201) {
    return response.data;
  }
  return 'error';
};

const listWidgets = async (dashboardId) => {
  try {
    const response = await window.axios.get(`/graphic/dashboard/${dashboardId}/widget`);
    if (response.status === 200) {
      return response.data;
    }
    return response.status;
  } catch (e) {
    return 'error';
  }
};

const addWidget = async (dashboardId, widgetId) => {
  const response = await window.axios.post(`/graphic/dashboard/${dashboardId}/widget/${widgetId}`);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const removeWidget = async (dashboardId, widgetId) => {
  const response = await window.axios.delete(`/graphic/dashboard/${dashboardId}/widget/${widgetId}`);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const fetchUsers = async (dashboardId) => {
  const response = await window.axios.get(`/graphic/dashboard/${dashboardId}/user`);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const grantAccess = async (dashboardId, userId) => {
  const response = await window.axios.post(`/graphic/dashboard/${dashboardId}/user/${userId}`);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const revokeAccess = async (dashboardId, widgetId) => {
  const response = await window.axios.delete(`/graphic/dashboard/${dashboardId}/user/${widgetId}`);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

export default {
  list,
  details,
  create,
  clone,
  remove,
  update,
  widgets: {
    list: listWidgets,
    add: addWidget,
    remove: removeWidget,
  },
  users: {
    list: fetchUsers,
    add: grantAccess,
    remove: revokeAccess,
  },
};
