var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"pa-0 ma-0"},_vm._l((_vm.filters.filter(function (f) { return f.isGlobal; })),function(filter){return _c('v-col',{key:filter.label,staticClass:"d-flex align-center"},[(!filter.isDate)?_c('v-autocomplete',{staticClass:"mt-0 pt-0",attrs:{"label":filter.label,"items":filter.items,"search-input":filter.search,"hide-details":"auto","multiple":""},on:{"update:searchInput":function($event){return _vm.$set(filter, "search", $event)},"update:search-input":function($event){return _vm.$set(filter, "search", $event)},"change":function($event){return _vm.applyFilter(filter)},"focus":function($event){filter.focus = true},"blur":function($event){filter.focus = false; filter.search=''}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(filter.search != '' && filter.search != null && filter.focus),expression:"filter.search != '' && filter.search != null && filter.focus"}],staticClass:"ml-1",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.selectAllVisible(filter)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-checkbox-marked")])],1)]}}],null,true)},[_c('span',[_vm._v("Seleziona tutti")])])]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index == 0)?_c('span',[_vm._v(" "+_vm._s(item)+" ")]):(index == 1)?_c('v-chip',{attrs:{"label":"","x-small":""}},[_c('span',{staticStyle:{"font-size":".95rem"}},[_vm._v("+"+_vm._s(filter.selected.length - 1))])]):_vm._e()]}}],null,true),model:{value:(filter.selected),callback:function ($$v) {_vm.$set(filter, "selected", $$v)},expression:"filter.selected"}}):_vm._e(),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.toggleAll(filter)}}},[(filter.selected.length == 0)?_c('v-icon',{staticClass:"mdi mdi-checkbox-blank-outline",attrs:{"size":"24","color":"secondary"}}):(filter.selected.length < filter.items.length)?_c('v-icon',{staticClass:"mdi mdi-checkbox-intermediate",attrs:{"size":"24","color":"secondary"}}):(filter.selected.length === filter.items.length )?_c('v-icon',{staticClass:"mdi mdi-checkbox-marked",attrs:{"size":"24","color":"secondary"}}):_vm._e()],1),(filter.isDate)?_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"text-body-2",attrs:{"dense":"","label":filter.label,"prepend-icon":"mdi-calendar","readonly":""},model:{value:(filter.selected),callback:function ($$v) {_vm.$set(filter, "selected", $$v)},expression:"filter.selected"}},on))]}}],null,true)},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"it-it","allowed-dates":function (val) { return filter.items.indexOf(val) !== -1; }},on:{"input":function($event){return _vm.applyFilter(filter)}},model:{value:(filter.selected),callback:function ($$v) {_vm.$set(filter, "selected", $$v)},expression:"filter.selected"}})],1):_vm._e()],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }