<template>
  <v-expansion-panel-header>
    <v-row class="pr-5">
      <span class="text-subtitle-1">{{sectionName}}</span>
      <v-spacer/>
      <v-tooltip color="error" bottom v-if="errors.length > 0"  class="mr-10">
        <template v-slot:activator="{ on, attrs }">
          <v-avatar small  color="red" size="24"
                    class="text--white " v-on="on" v-bind="attrs">
            <span class="white--text">{{errors.length}}</span>
          </v-avatar>
        </template>
        <ul>
          <li v-for="(e, i) in errors" :key="i">{{e}}</li>
        </ul>
      </v-tooltip>
      <v-icon v-else color="success"> mdi-checkbox-marked-circle  </v-icon>
    </v-row>
  </v-expansion-panel-header>
</template>

<script>
export default {
  name: 'BuilderStepHeader',
  props: {
    errors: {
      type: Array,
      required: true,
    },
    sectionName: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>

</style>
