<template>
  <!-- User Managemet Main Page -->
  <v-card class="ma-3 pa-3">
    <v-card-title>
      Users
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="users || []"
      :single-expand="true"
      :items-per-page="10"
      :loading="loadingUsers"
      :expanded.sync="expanded"
      item-key="userName"
      show-expand
      class="elevation-1"
      @click:row="expandRow"
      @item-expanded="handleRowSelection"
    >
      <template v-slot:expanded-item="{headers}">
        <td :colspan="headers.length">
          <UserDetails :user="user" :key="detailsKey"
          @delete="handleDashboardDelete"
          @new="handleDashboardCreate"/>
        </td>
      </template>
    </v-data-table>
    <v-row class="mt-1 pa-3">
      <v-spacer/>
      <UserCreator @created="handleNewUser"/>
    </v-row>
  </v-card>
</template>

<script>
import api from '@/api';
import UserCreator from '@/admin/components/main/UserManagement/UserCreator.vue';
import UserDetails from '@/admin/components/main/UserManagement/UserDetails.vue';

const tableHeaders = [
  { text: 'Username', align: 'start', value: 'userName' },
  { text: 'First Name', value: 'firstName' },
  { text: 'Last Name', value: 'lastName' },
  { text: 'e-mail', value: 'email' },
  { text: '', value: 'data-table-expand' },
];

export default {
  name: 'UserManagement',
  components: { UserDetails, UserCreator },
  created() {
    this.loadUsers();
  },
  methods: {
    async loadUsers() {
      this.loadingUsers = true;
      this.users = await api.admin.user.list();
      this.loadingUsers = false;
    },
    expandRow(item, row) {
      row.expand(!row.isExpanded);
    },
    handleRowSelection(row) {
      this.detailsKey = (this.detailsKey + 1) % 2;
      this.user = row.item;
    },
    handleNewUser(user) {
      const newAdd = user;
      newAdd.dashboards = 0;
      this.users.push(newAdd);
    },
    handleDashboardCreate(user) {
      this.users[this.users.indexOf(user)].dashboards += 1;
    },
    handleDashboardDelete(user) {
      this.users[this.users.indexOf(user)].dashboards -= 1;
    },
  },
  data() {
    return {
      expanded: [],
      search: '',
      loadingUsers: null,
      headers: tableHeaders,
      users: null,
      user: null,
      detailsKey: 0,
    };
  },
};
</script>

<style scoped>

</style>
