import moment from 'moment';

const anyMismatchEx = {};

const buildFilters = (targetColumns, rows) => {
  const filtersValues = targetColumns.reduce((acc, item) => {
    acc[item.name] = new Set();
    return acc;
  }, {});
  rows.forEach((row) => {
    targetColumns.forEach((col) => {
      filtersValues[col.name].add(row[col.name]);
    });
  });
  const filters = { };
  const initialState = { };
  Object.keys(filtersValues).forEach((key) => {
    filters[key] = [...filtersValues[key]];
    initialState[key] = [filters[key][0]];
  });
  return {
    filters,
    filterState: { ...initialState },
  };
};
const cartesian = (filterValues) => {
  const r = [];
  let values = Object.entries(filterValues).map(([k, v]) => {
    if (Array.isArray(v)) return v.map((c) => [k, c]);
    return [[k, v]];
  });
  values = values.filter((vals) => vals.length > 0);
  const max = values.length - 1;
  function helper(arr, i) {
    for (let j = 0, l = (values[i]?.length || 0); j < l; j += 1) {
      const a = arr.slice(0); // clone arr
      a.push(values[i][j]);
      if (i === max) r.push(a);
      else helper(a, i + 1);
    }
  }
  helper([], 0);
  return r.map((combination) => ({
    values: Object.fromEntries(combination),
    label: combination.map((b) => b.join(': ')).join(', '),
  }));
};

const applySingleFilter = (rows, filterValues) => rows.filter((row) => {
  try {
    Object.entries(filterValues)
      .forEach(([name, value]) => {
        if (row[name] !== value) throw anyMismatchEx;
      });
  } catch (e) {
    if (e === anyMismatchEx) return false;
    throw e;
  }
  return true;
});
const filterData = (rows, config, filterValues) => {
  if (!config.filtered || config.filterColumns.length === 0) {
    return rows;
  }
  const producedFilters = cartesian(filterValues);
  return producedFilters.map((filter) => ({
    label: filter.label,
    rows: applySingleFilter(rows, filter.values),
  }));
};

const generateDates = (start, end) => {
  const actual = moment(start, 'YYYY-MM-DD');
  const endDate = moment(end, 'YYYY-MM-DD').add(1, 'd');
  if (actual.format('YYYY-MM-DD') === 'Invalid date' || endDate.format('YYYY-MM-DD') === 'Invalid date') return [];
  const dates = [];
  while (actual.isSameOrBefore(endDate)) {
    dates.push(actual.format.format('YYYY-MM-DD'));
    actual.add(1, 'days');
  }
  return dates;
};
const generateYearlessDates = (start, end) => {
  moment.locale('it');
  const actual = moment(start, 'YYYY-MM-DD');
  const endDate = moment(end, 'YYYY-MM-DD');
  if (actual.format('YYYY-MM-DD') === 'Invalid date' || endDate.format('YYYY-MM-DD') === 'Invalid date') return [];
  const dates = [];
  while (actual.isSameOrBefore(endDate)) {
    dates.push(actual.format('DD-MM'));
    // dates.push(actual.format('DD-MMM'));
    actual.add(1, 'days');
  }
  return dates;
};

export default {
  filterData,
  buildFilters,
  generateDates,
  generateYearlessDates,
};
