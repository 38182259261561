<template>
  <div v-if="localWidget && localWidget.config">
    <v-progress-circular v-if="loading" indeterminate/>
    <v-list dense>
      <v-list-item dense class="mb-4" v-if="localDataset">
        <v-list-item-title>
          {{dataset.label}}
          <span class="grey--text">(ID: {{dataset.id}})</span>
        </v-list-item-title>
        <v-list-item-action>
          <v-dialog v-model="dialog" width="800">
            <template v-slot:activator="{on, attrs}">
              <v-btn icon color="primary" v-bind="attrs" v-on="on">
                <v-icon class="mdi mdi-pencil"/>
              </v-btn>
            </template>
            <v-card>
              <v-toolbar dense class="mb-4" color="primary" dark>
                <v-toolbar-title>
                  Configurazione Dataset
                </v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="save" :loading="saving">
                  <v-icon class="mdi mdi-upload"/>
                </v-btn>
                <v-btn icon @click="dialog = false">
                  <v-icon class="mdi mdi-close"/>
                </v-btn>
              </v-toolbar>
              <DatasetEditDialog
                :schema="schema"
                :dataset="localDataset"
                :widget="widget"
                :showTest="false"
                @close="dialog = false"
                @updatedColumns="handleUpdatedColumns"
                @modified="handleModification"/>
              <FilterEditDialog
                :dataset="localDataset"
                :columns="tableColumns"
                @close="dialog = false"
                @filters="handleFiltersChange"/>
            </v-card>
          </v-dialog>
        </v-list-item-action>
      </v-list-item>

      <v-list-item dense class="mb-4" v-if="localDataset && widget.type === 'multi_chart'">
        <v-list-item-title>
          {{dataset2.label}}
          <span class="grey--text">(ID: {{dataset2.id}})</span>
        </v-list-item-title>
        <v-list-item-action>
          <v-dialog v-model="dialog2" width="800">
            <template v-slot:activator="{on, attrs}">
              <v-btn icon color="primary" v-bind="attrs" v-on="on">
                <v-icon class="mdi mdi-pencil"/>
              </v-btn>
            </template>
            <v-card>
              <v-toolbar dense class="mb-4" color="primary" dark>
                <v-toolbar-title>
                  Configurazione Dataset
                </v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="save2" :loading="saving">
                  <v-icon class="mdi mdi-upload"/>
                </v-btn>
                <v-btn icon @click="dialog2 = false">
                  <v-icon class="mdi mdi-close"/>
                </v-btn>
              </v-toolbar>
              <DatasetEditDialog
                :schema="schema"
                :dataset="localDataset2"
                :widget="widget"
                @close="dialog2 = false"
                @modified="handleModification2"/>
            </v-card>
          </v-dialog>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import api from '@/api';
import DatasetEditDialog from '@/graphic/components/main/DashboardEditor/dataset/DatasetEditDialog.vue';
import FilterEditDialog from '@/graphic/components/main/DashboardEditor/dataset/FilterEditDialog.vue';

export default {
  name: 'DatasetConfig',
  components: { DatasetEditDialog, FilterEditDialog },
  props: {
    widget: {
      type: Object,
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
  },
  watch: {
    widget() {
      if (!this.widget) {
        this.localWidget = null;
        this.datasets = null;
      } else {
        this.localWidget = { ...this.widget };
        this.loadDatasets();
      }
    },
  },
  mounted() {
    this.localWidget = { ...this.widget };
    this.loadDatasets();
  },
  methods: {
    reset() {
      this.localDataset = { ...this.localDataset, widgetId: this.localWidget?.id, query: '' };
      this.queryChanged = false;
      this.queryFailed = false;
      this.queryError = null;
      this.loadingResults = false;
      this.paramState = {};
    },
    handleUpdatedColumns(cols) {
      this.tableColumns = cols;
    },
    handleModification(dataset) {
      this.localDataset = dataset;
    },
    handleModification2(dataset) {
      this.localDataset2 = dataset;
    },
    handleFiltersChange(conditions) {
      this.localDataset.query = conditions;
    },
    handleDatasetChange() {
      this.handleChange();
    },
    async save() {
      if (this.saving) {
        return;
      }
      if (this.localDataset.label.trim() === '') {
        this.localDataset.label = 'widget-query';
      }
      if (this.localDataset.query === '') {
        return;
      }
      this.saving = true;
      const saved = await api.graphic.dataset.save(this.localDataset);
      this.saving = false;
      this.dialog = false;
      this.$emit('change', saved);
    },
    async save2() {
      if (this.saving) {
        return;
      }
      if (this.localDataset2.label.trim() === '') {
        this.localDataset.label = 'widget-query';
      }
      if (this.localDataset2.query === '') {
        return;
      }
      this.saving = true;
      const saved = await api.graphic.dataset.save(this.localDataset2);
      this.saving = false;
      this.$emit('change', saved);
    },
    handleChange() {
      this.$emit('change', [this.dataset]);
    },
    async loadDatasets() {
      this.datasets = null;
      this.loading = true;
      const res = await api.graphic.widget.datasets.list(this.localWidget.id);
      [this.dataset] = res;
      this.localDataset = { ...this.dataset };
      if (this.widget.type === 'multi_chart') {
        // eslint-disable-next-line prefer-destructuring
        this.dataset2 = res[1];
        this.localDataset2 = { ...this.dataset2 };
      }
      this.loading = false;
    },
  },
  data() {
    return {
      saving: false,
      valid: true,
      localWidget: null,
      loading: false,
      dialog: false,
      dialog2: false,
      dataset: null,
      localDataset: null,
      tableColumns: [],
      rules: {
        titleRules: [(v) => !!v || 'Inserire titolo'],
      },
    };
  },
};
</script>

<style scoped>

</style>
