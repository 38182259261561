<template>
  <!-- Error App, shown only when logged user doesn't exist nor have permissions -->
  <v-app>
    <AppBar/>
    <v-main>
      <v-container fluid class="pa-0">
        <router-view :key="$route.name"></router-view>
      </v-container>
    </v-main>
    <!-- <Footer /> -->
  </v-app>
</template>

<script>
import AppBar from '@/error/AppBar.vue';
// import Footer from '@/error/Footer.vue';

export default {
  name: 'ErrorApp',
  components: { AppBar },
  created() {
  },
  methods: {
  },
};
</script>

<style lang="scss">
.v-application {
    font-family: $body-font-family, sans-serif !important;
    .title { // To pin point specific classes of some components
            font-family: $title-font, sans-serif !important;
    }
}
</style>
