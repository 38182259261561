<template>
  <div>
    <div v-if="dataLoaded && !failed">
      <v-row class="mt-3 mb-1 text-left mx-4" v-if="showOwnInfo">
        <v-col class="ma-n3">
            <p class="mt-n6 mb-n1">Totale {{config.values[0]}} {{config.multi_chart.name1}}
      {{widget.config.values}}

            </p>
            <h2>{{format(sum1)}}</h2>
        </v-col>
        <v-col class="ma-n3">
            <p class="mt-n6 mb-n1">Totale {{config.values[0]}} {{config.multi_chart.name2}}</p>
            <h2>{{format(sum2)}}</h2>
        </v-col>
        <v-col class="ma-n3">
          <p class="mt-n6 mb-n1">Confronto</p>
          <v-row class="ma-0">
            <v-btn color="info" icon small class="mt-n1 mr-2 ml-n1"
              @click="handlePercentual">
              <v-icon class="mdi mdi-label-percent"/>
            </v-btn>
            <h2 v-if="growth > 0" class="green--text">{{growth}}</h2>
            <h2 v-else-if="growth == 0">{{growth}}</h2>
            <h2 v-else class="red--text">{{growth}}</h2>
            <h2 v-if="percentual">%</h2>
          </v-row>
        </v-col>
      </v-row>
      <MultiChart
        ref="chart"
        :height="height - (tagsHeight) -
          (config.filtered ? 48 : 0) +
          (showOwnInfo ? 0 : 20)"
        :chart-data="data"
        :options="options"/>
    </div>
    <v-progress-circular v-else-if="!dataLoaded" indeterminate color="primary"/>
    <div style="height: 100%;" class="fill-height overflow-hidden"
        v-else-if="failed">
      <v-card
        shaped
        align="center"
        style="margin: 4% 8%;"
        class="error--text text-subtitle-1 pa-4 fluid"
      >
        <div v-if="adminView">
          <div class="font-weight-bold"> Errore durante l'estrazione dei dati.</div>
          <br/>
          <div v-if="error ">{{error }}</div>
          <div v-if="error2">{{error2}}</div>
        </div>
        <div  v-else> Errore Interno, contatta l'amministratore. </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import colors from '@/tools/colors';
import dataTypes from '@/tools/dataTypes';

import MultiChart from '@/graphic/components/main/DashboardEditor/widgets/multi_chart/MultiChart.vue';
import chartData from '@/tools/chartData';

export default {
  name: 'MultiChartWidget',
  components: { MultiChart },
  props: {
    widget: {
      type: Object,
      required: true,
    },
    queryResult: {
      type: Object,
      required: true,
    },
    queryResult2: {
      type: Object,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    filterState: {
      type: Object,
      required: false,
    },
    widgetFilters: {
      type: Object,
      required: false,
    },
    percentual: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    showOwnInfo: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    stack: {
      type: Object,
      required: true,
    },
    adminView: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    config() {
      return this.widget?.config?.multi_chart;
    },
    options() {
      const scales = {
        x: {
          type: dataTypes.isTime(this.labelDef?.type || '') ? ['time'] : 'text',
        },
      };
      const legend = {
        position: 'bottom',
      };
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales,
        legend,
      };
    },
  },
  mounted() {
    this.loadData();
  },
  watch: {
    dataset() {
      this.loadData();
    },
    'widget.config': {
      deep: true,
      handler() {
        this.loadData();
      },
    },
    queryResult: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
    queryResult2: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
    sum1: {
      handler() {
        this.$emit('infoBar', {
          sum1: this.sum1, sum2: this.sum2, growth: this.growth, percentual: this.percentual,
        });
      },
      deep: true,
    },
    sum2: {
      handler() {
        this.$emit('infoBar', {
          sum1: this.sum1, sum2: this.sum2, growth: this.growth, percentual: this.percentual,
        });
      },
      deep: true,
    },
    stack: {
      handler() {
        this.handleFilterChange();
      },
    },
    percentual: {
      handler() {
        this.calculateGrowth();
      },
    },
  },
  methods: {
    changeValue(sel) {
      this.widget.config.values[0] = sel;
    },
    format(num) {
      if (num % 1 === 0) return String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1.');
      return String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1.').replace(/.([^.]*)$/, ',$1');
    },
    async loadData() {
      if (!this.config) {
        return;
      }
      if (this.queryResult.failed || this.queryResult2.failed) {
        this.failed = true;
        if (this.queryResult.failed) this.error = this.queryResult.error;
        if (this.queryResult2.failed) this.error2 = this.queryResult2.error;
      } else {
        this.failed = false;
        this.error = null;
        this.buildData();
      }
      this.dataLoaded = true;
    },
    buildData() {
      if (!this.queryResult) {
        return;
      }
      this.validateResults();
      if (this.failed) {
        return;
      }
      this.filterRows();
      this.buildChartData();
      this.saveFilters();
      this.calculateGrowth();
    },
    handleFilterChange() {
      this.filterRows();
      this.buildChartData();
      this.saveFilters();
      this.calculateGrowth();
    },
    filterRows() {
      // Stack handler
      this.rows = [{ label: this.config.name1, rows: this.queryResult.values },
        { label: this.config.name2, rows: this.queryResult2.values }];
      // Filter rows without stack
      if (this.config.filtered) {
        Object.keys(this.filterState).forEach((filterKey) => {
          if (this.filterState[filterKey].length > 0) {
            this.rows.forEach((subrow) => {
              const sub = subrow.rows;
              // eslint-disable-next-line no-param-reassign
              subrow.rows = sub.filter((item) => this.filterState[filterKey]
                .includes(item[filterKey]));
            });
          }
        });
      }
      if (this.stack.active && this.filterState[this.stack.col].length > 0) {
        // Filter with a stacked-row
        this.stack.values = this.filterState[this.stack.col];
        const stackFilterState = {};
        stackFilterState[this.stack.col] = this.stack.values;
        // Generate extra lines in stack-row
        const rowsA = chartData.filterData(
          this.rows[0].rows,
          this.config,
          stackFilterState,
        );
        const rowsB = chartData.filterData(
          this.rows[1].rows,
          this.config,
          stackFilterState,
        );
        rowsA.forEach((row) => {
          // eslint-disable-next-line no-param-reassign
          row.label = `set 1: ${row.label}`;
        });
        rowsB.forEach((row) => {
          // eslint-disable-next-line no-param-reassign
          row.label = `set 2: ${row.label}`;
        });
        this.rows = rowsA.concat(rowsB);
      }
    },
    buildChartData() {
      const { labelColumn, values, order } = this.config;
      // Order rows data
      let labels = [];
      let s1labels = [];
      let s2labels = [];
      const builtRows = [];
      const midPoint = this.rows.length / 2;
      let counter = 0;
      this.rows.forEach((subrows) => {
        const builtSubrows = {};
        subrows.rows.forEach((row) => {
          if (builtSubrows[row[labelColumn]]) {
            values.forEach((tag) => {
              builtSubrows[row[labelColumn]][tag] += row[tag];
            });
          } else {
            builtSubrows[row[labelColumn]] = JSON.parse(JSON.stringify(row));
          }
        });
        let arrRes = [];
        Object.keys(builtSubrows).forEach((key) => {
          arrRes.push(builtSubrows[key]);
        });
        arrRes = arrRes.sort((it1, it2) => {
          if (it1[labelColumn] < it2[labelColumn]) return -1;
          if (it1[labelColumn] > it2[labelColumn]) return 1;
          return 0;
        });
        if (counter === midPoint) s2labels = Object.keys(builtSubrows);
        if (labels.length === 0) {
          s1labels = Object.keys(builtSubrows);
          labels = Object.keys(builtSubrows);
        } else {
          Object.keys(builtSubrows).forEach((key) => {
            if (!labels.includes(key)) {
              labels.push(key);
            }
            if (counter < midPoint) {
              s1labels.push(key);
            } else {
              s2labels.push(key);
            }
          });
        }
        labels.sort();
        s1labels.sort();
        s2labels.sort();
        s1labels = [...new Set(s1labels)];
        s2labels = [...new Set(s2labels)];
        builtRows.push({ label: subrows.label, rows: arrRes });
        counter += 1;
      });
      // Get name of object attribute from filters
      const highlight = Object.keys(this.filterState);
      const rowStats = [];
      builtRows.forEach((row) => {
        if (row.rows.length > 0) {
          const stats = {};
          highlight.forEach((key) => {
            stats[key] = row.rows[0][key];
          });
          rowStats.push(stats);
        }
      });
      // Check and fill 0's to correct data
      let index = 0;
      s1labels.forEach((label) => {
        let blankRows = 0;
        builtRows.forEach((row, idx) => {
          if (row.rows.length > 0 && idx < midPoint) {
            if (row.rows[index] === undefined || row.rows[index][labelColumn] !== label) {
              const obj = {};
              obj[labelColumn] = label;
              highlight.forEach((key) => {
                obj[key] = rowStats[idx - blankRows][key];
              });
              values.forEach((v) => {
                obj[v] = null;
              });
              if (row.rows[index] === undefined) row.rows.push(obj);
              else row.rows.splice(index, 0, obj);
            }
          } else {
            blankRows += 1;
          }
        });
        index += 1;
      });
      index = 0;
      s2labels.forEach((label) => {
        let blankRows = 0;
        builtRows.forEach((row, idx) => {
          if (row.rows.length > 0 && idx >= midPoint) {
            if (row.rows[index] === undefined || row.rows[index][labelColumn] !== label) {
              const obj = {};
              obj[labelColumn] = label;
              highlight.forEach((key) => {
                obj[key] = rowStats[idx - blankRows][key];
              });
              values.forEach((v) => {
                obj[v] = null;
              });
              if (row.rows[index] === undefined) row.rows.push(obj);
              else row.rows.splice(index, 0, obj);
            }
          } else {
            blankRows += 1;
          }
        });
        index += 1;
      });

      // Fit data by dates
      const halfDatasets = builtRows.length / 2;
      const tlabel = order ? s2labels : s1labels;
      const finalRows = [];
      builtRows.forEach((x) => {
        finalRows.push({ label: x.label, rows: [] });
      });
      for (let k = 0; k < builtRows.length / 2; k += 1) {
        const endDate = moment(tlabel[tlabel.length - 1], 'YYYY-MM-DD').add(1, 'd');
        const currentDate = moment(tlabel[0], 'YYYY-MM-DD');
        const h = k + halfDatasets;
        let i = 0;
        let j = 0;
        // search first index of label in data (cut start)
        if (!builtRows[k].rows.some((value) => {
          const cand = moment(value[labelColumn], 'YYYY-MM-DD');
          if (cand.month() === currentDate.month() && cand.date() === currentDate.date()) {
            return true;
          }
          i += 1;
          return false;
        })) i = 0;
        if (!builtRows[h].rows.some((value) => {
          const cand = moment(value[labelColumn], 'YYYY-MM-DD');
          if (cand.month() === currentDate.month() && cand.date() === currentDate.date()) {
            return true;
          }
          j += 1;
          return false;
        })) j = 0;
        while ((currentDate.month() !== endDate.month() || currentDate.date() !== endDate.date())
          && currentDate.format('YYYY-MM-DD') !== 'Invalid date' && endDate.format('YYYY-MM-DD') !== 'Invalid date') {
          const obj = {};
          obj[labelColumn] = currentDate.format('YYYY-MM-DD');
          values.forEach((v) => {
            obj[v] = null;
          });
          if (builtRows[k].rows.length > i) {
            const kDate = moment(builtRows[k].rows[i][labelColumn], 'YYYY-MM-DD');
            if (kDate.month() === currentDate.month() && kDate.date() === currentDate.date()) {
              finalRows[k].rows.push(builtRows[k].rows[i]);
              i += 1;
            } else {
              finalRows[k].rows.push(obj);
            }
          }
          if (builtRows[h].rows.length > j) {
            const hDate = moment(builtRows[h].rows[j][labelColumn], 'YYYY-MM-DD');
            if (hDate.month() === currentDate.month() && hDate.date() === currentDate.date()) {
              finalRows[h].rows.push(builtRows[h].rows[j]);
              j += 1;
            } else {
              finalRows[h].rows.push(obj);
            }
          }
          currentDate.add(1, 'days');
        }
      }

      this.rows = finalRows;
      // Build chart
      let color = -1;
      this.data = {
        // labels: tdata,
        labels: chartData.generateYearlessDates(tlabel[0], tlabel[tlabel.length - 1]),
        datasets: this.rows.flatMap((subset) => values.map((col) => {
          color += 1;
          let labelTag;
          labelTag = color % 2 === 0 ? this.config.tagChart1 : this.config.tagChart2;
          if (!labelTag) labelTag = `${col} - ${subset.label}`;
          return {
            spanGaps: true,
            label: labelTag,
            fill: false,
            borderColor: colors.getColor(color),
            backgroundColor: colors.getColor(color),
            data: subset.rows.map((r) => r[col]),
          };
        })),
      };
    },
    handlePercentual() {
      this.percentual = !this.percentual;
      this.calculateGrowth();
    },
    calculateGrowth() {
      // Calculate sums
      const half = this.data.datasets.length / 2;
      this.sum1 = 0;
      this.sum2 = 0;
      for (let i = 0; i < half; i += 1) {
        this.data.datasets[i].data.forEach((value) => {
          this.sum1 += value;
        });
        this.data.datasets[i + half].data.forEach((value) => {
          this.sum2 += value;
        });
      }
      const operation = this.config.operation ? this.config.operation : 0;
      // // Calculate growth
      // if (this.percentual) {
      //   this.growth = (((this.sum2 - this.sum1) / this.sum1) * 100).toFixed(2);
      // } else {
      //   this.growth = (this.sum2 - this.sum1).toFixed(2);
      // }
      this.percentual = false;
      // Sum
      if (operation === 0) {
        this.growth = (this.sum2 + this.sum1).toFixed(2);
      // Difference Numeric
      } else if (operation === 1) {
        this.growth = (this.sum2 - this.sum1).toFixed(2);
      // Difference %
      } else if (operation === 2) {
        this.percentual = true;
        this.growth = (((this.sum2 - this.sum1) / this.sum1) * 100).toFixed(2);
      // Product
      } else if (operation === 3) {
        this.growth = (this.sum2 * this.sum1).toFixed(2);
      // Quotient
      } else if (operation === 4) {
        this.growth = (this.sum2 / this.sum1).toFixed(2);
      // Average
      } else if (operation === 5) {
        this.growth = ((this.sum2 + this.sum1) / 2).toFixed(2);
      // Std. Deviation
      } else if (operation === 6) {
        // const mean = (numberArray.reduce((a, b) => a + b, 0) / numberArray.length);
        // const square = numberArray.map((x) => (x - mean) ** 2);
        // this.growth = Math.sqrt(square.reduce((acc, curr) => acc + curr, 0)).toFixed(2);
        this.growth = 0;
      }
      this.sum1 = this.sum1.toFixed(2);
      this.sum2 = this.sum2.toFixed(2);
    },
    validateResults() {
      if (!this.queryResult) {
        return;
      }
      const errors = [];
      const { columns } = this.queryResult.metas;
      const { labelColumn, values } = this.config;
      const valuesNames = new Set([...values]);
      [this.labelDef] = columns.filter((c) => c.name === labelColumn);
      const valuesDef = columns.filter((c) => valuesNames.has(c.name));
      const nonNumericValues = valuesDef.filter((i) => !dataTypes.isNumeric(i)).map((i) => i.name);
      if (!this.labelDef) {
        errors.push(`La colonna ${labelColumn} non é presente.`);
      }
      if (valuesDef.length === 0) {
        errors.push('Nessun valore selezionato da mostrare');
      }
      if (nonNumericValues.length > 0) {
        errors.push(`Le colonne ${nonNumericValues.join(',')} non sono di tipo numerico.`);
      }
      this.failed = errors.length !== 0;
      this.error = errors.join('\n');
    },
    saveFilters() {
      this.$emit('filterSave', { filters: this.filters, filterState: this.filterState });
    },
    compareKeys(x, y) {
      let key = true;
      Object.keys(x).forEach((ix, index) => {
        if (ix !== Object.keys(y)[index]) key = false;
      });
      return key;
    },
  },
  data() {
    return {
      data: null,
      failed: false,
      error: null,
      error2: null,
      dataLoaded: false,
      labelDef: null,
      tagsHeight: 30,
      sum1: 2,
      sum2: 3,
      growth: 0,
      filters: {},
      rows: [],
    };
  },
};
</script>

<style lang="scss">
</style>
