<template>
  <!-- Main Screen Dashboard Card -->
  <v-col md="4">
    <v-card class="dashboard-card">
      <v-card-title>

        <v-icon color="primary" :key="dashboard.id" :class="dashboardIcon"/>
        <span class="mx-2 px-2">{{dashboard.displayName}}</span>

        <v-spacer />

        <ConfirmDelete
          text="Confermi l'eliminazione di questa dashboard?"
          @confirm = "handleDeletionConfirm"/>

        <v-dialog
          max-width="400px"
          v-model="dialog"
        >
          <v-card>
            <v-card-text class="pt-3 text-h6 text-md-center">
              {{error[0]}}
              <br/>
              {{error[1]}}
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="info"
                text
                @click="dialog = false"
              >
                Ok
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon  v-bind="attrs" v-on="on" @click="handleClone">
              <v-icon color="secondary" class="mdi mdi-content-copy" />
            </v-btn>
          </template>
          <span>Clona Dashboard</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="secondary" v-bind="attrs" v-on="on" @click="openEditor(dashboard)">
              <v-icon class="mdi mdi-pencil" />
            </v-btn>
          </template>
          <span>Modifica</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="lightblue" v-bind="attrs"
            v-on="on" @mouseup.stop="toggleOpen">
              <v-icon v-if="open" class="mdi mdi-chevron-up" />
              <v-icon v-else class="mdi mdi-chevron-down" />
            </v-btn>
          </template>
          <span v-if="open">Riduci</span>
          <span v-else>Espandi</span>
        </v-tooltip>

      </v-card-title>
      <v-card-text v-if="open" class="px-2">
        <DashboardDetails
          :dashboard="dashboard"
          :actualIcon="dashboardIcon.split(/[ ]+/)[1]"
          @icon="changeIcon"
          @iconSave="saveIcon"
        />
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import api from '@/api';
import ConfirmDelete from '@/graphic/components/main/home/ConfirmDelete.vue';
import DashboardDetails from '@/graphic/components/main/home/DashboardDetails.vue';

export default {
  name: 'DashboardCard',
  components: { ConfirmDelete, DashboardDetails },
  props: {
    dashboard: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.dashboardIcon = `mdi ${this.dashboard.icon}`;
  },
  methods: {
    openEditor(dashboard) {
      this.$router.push(
        {
          name: 'DashboardEditor',
          params: { dashboard, id: dashboard.id },
        },
      );
    },
    cardClick() {
      if (!this.open) this.toggleOpen();
    },
    toggleOpen() {
      this.open = !this.open;
    },
    changeIcon(icon) {
      this.dashboardIcon = `mdi ${icon}`;
    },
    async saveIcon() {
      try {
        // eslint-disable-next-line prefer-destructuring
        this.dashboard.icon = this.dashboardIcon.split(/[ ]+/)[1];
        const result = await api.graphic.dashboard.update(this.dashboard.id, this.dashboard);
        return result;
      } catch (e) {
        this.dialog = true;
        this.error = ['Cannot save icon', '(Unexpected Error Occurred)'];
      }
      return 'error';
    },
    async handleClone() {
      const clone = await api.graphic.dashboard.clone(this.dashboard.id);
      this.$emit('cloned', clone);
    },
    async handleDeletionConfirm() {
      try {
        await api.graphic.dashboard.remove(this.dashboard.id);
        this.$emit('deleted', this.index);
        window.location.reload();
      } catch (e) {
        this.dialog = true;
        this.error = ['Cannot delete dashboard', '(Users or Widgets may still be associated)'];
      }
    },
  },
  data() {
    return {
      open: false,
      dialog: false,
      error: [],
      dashboardIcon: '',
    };
  },
};
</script>
