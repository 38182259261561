<template>
  <!-- Main Screen Dashboard Card On Delete Press -->
  <v-tooltip bottom>
    <template #activator="{ on: tooltip, attrs: tooltipAttrs }">
      <v-dialog
        max-width="400px"
        v-model="dialog">
        <template #activator="{ on: dialog, attrs: dialogAttrs }">
          <v-btn icon
                 color="error"
                 v-bind="{...tooltipAttrs,...dialogAttrs}"
                 v-on="{...tooltip, ...dialog}">
            <v-icon color="primary" class="mdi mdi-delete" medium></v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-text class="pt-3 text-h6 text-justify">
            {{text}}
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="blue"
              text
              @click="dialog = false"
            >
              No
            </v-btn>
            <v-spacer />
            <v-btn
              color="red"
              text
              @click="confirm"
            >
              Si
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <span>Elimina</span>
  </v-tooltip>
</template>

<script>

export default {
  name: 'ConfirmDelete',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  methods: {
    async confirm() {
      this.dialog = false;
      this.$emit('confirm');
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style lang="scss">
.confirm-dialog-title {
  text-align: justify;
  overflow: hidden;
}
</style>
