<template>
  <div>
    <div v-if="dataLoaded && !failed">
      <LineChart
        ref="chart"
        :height="height  -(config.filtered? 46 : 0)"
        :chart-data="data"
        :options="options"/>
    </div>
    <v-progress-circular v-else-if="!dataLoaded" indeterminate color="primary"/>
    <div style="height: 100%;" class="fill-height overflow-hidden"
        v-else-if="failed">
      <v-card
        shaped
        align="center"
        style="margin: 4% 8%;"
        class="error--text text-subtitle-1 pa-4 fluid"
      >
        <div v-if="adminView">
          <div class="font-weight-bold"> Errore durante l'estrazione dei dati.</div>
          <br/>
          {{error}}
        </div>
        <div  v-else> Errore Interno, contatta l'amministratore. </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import colors from '@/tools/colors';
import dataTypes from '@/tools/dataTypes';

import LineChart from '@/graphic/components/main/DashboardEditor/widgets/line_chart/LineChart.vue';
import chartData from '@/tools/chartData';

export default {
  name: 'LineChartWidget',
  components: { LineChart },
  props: {
    widget: {
      type: Object,
      required: true,
    },
    queryResult: {
      type: Object,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    filterState: {
      type: Object,
      required: false,
    },
    widgetFilters: {
      type: Object,
      required: false,
    },
    stack: {
      type: Object,
      required: false,
      default: () => ({ col: null, values: null, active: false }),
    },
    adminView: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    config() {
      return this.widget?.config?.line_chart;
    },
    options() {
      const scales = {
        x: {
          type: dataTypes.isTime(this.labelDef?.type || '') ? ['time'] : 'text',
        },
        xAxes: [{
          stacked: this.config?.stacked,
        }],
        yAxes: [{
          stacked: this.config?.stacked,
        }],
      };
      const legend = {
        position: 'bottom',
      };
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales,
        legend,
      };
    },
  },
  mounted() {
    this.loadData();
  },
  watch: {
    // dataset() {
    //   this.loadData();
    // },
    'widget.config': {
      deep: true,
      handler() {
        this.loadData();
      },
    },
    queryResult: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
    stack: {
      handler() {
        if (this.stack.active) {
          this.handleStackChange(this.stack.col, this.stack.values);
        }
      },
    },
  },
  methods: {
    loadData() {
      if (!this.config) {
        return;
      }
      if (this.queryResult.failed) {
        this.failed = true;
        this.error = this.queryResult.error;
      } else {
        this.failed = false;
        this.error = null;
        this.buildData();
      }
      this.dataLoaded = true;
    },
    buildData() {
      if (!this.queryResult) {
        return;
      }
      this.validateResults();
      if (this.failed) {
        return;
      }
      this.filterRows();
      this.buildChartData();
      this.saveFilters();
    },
    handleFilterChange() {
      this.filterRows();
      this.buildChartData();
      this.saveFilters();
    },
    // eslint-disable-next-line no-unused-vars
    handleStackChange(col, values) {
      const stackColors = [];
      Object.entries(this.data.datasets).forEach((data) => {
        const stackName = this.fetchDataActiveFilters(data[1], col, values);
        if (!stackColors.includes(stackName)) stackColors.push(stackName);
        // eslint-disable-next-line no-param-reassign
        data[1].stack = stackName;
        // eslint-disable-next-line no-param-reassign
        data[1].borderColor = colors.getColor(stackColors.indexOf(stackName));
        // eslint-disable-next-line no-param-reassign
        data[1].backgroundColor = colors.getColor(stackColors.indexOf(stackName));
      });
      this.$emit('resetStack');
    },
    fetchDataActiveFilters(data, col, values) {
      const { label } = data;
      const filterList = label.split(',');
      let stackName = '';
      filterList.forEach((filterStr) => {
        const exp = filterStr.replace(' ', '').split(':');
        if (exp[0] === col && values != null) stackName = stackName.concat(values.join('_'));
        else stackName = stackName.concat(exp[1]);
      });
      return stackName;
    },
    buildChartData() {
      const { labelColumn, values } = this.config;
      let labels = [...new Set(
        this.rows.flatMap((subset) => subset.rows.map((r) => r[labelColumn])),
      )].sort();
      // -----------------------------------------------------
      labels = [];
      const builtRows = [];
      this.rows.forEach((subrows) => {
        const builtSubrows = {};
        subrows.rows.forEach((row) => {
          if (builtSubrows[row[labelColumn]]) {
            values.forEach((tag) => {
              builtSubrows[row[labelColumn]][values] += row[tag];
            });
          } else {
            builtSubrows[row[labelColumn]] = JSON.parse(JSON.stringify(row));
          }
        });
        let arrRes = [];
        Object.keys(builtSubrows).forEach((key) => {
          arrRes.push(builtSubrows[key]);
        });
        arrRes = arrRes.sort((it1, it2) => {
          if (it1[labelColumn] < it2[labelColumn]) return -1;
          if (it1[labelColumn] > it2[labelColumn]) return 1;
          return 0;
        });
        if (labels.length === 0) labels = Object.keys(builtSubrows);
        else {
          Object.keys(builtSubrows).forEach((key) => {
            if (!labels.includes(key)) {
              labels.push(key);
            }
          });
        }
        labels.sort();
        builtRows.push({ label: subrows.label, rows: arrRes });
      });
      // Get name of object attribute from filters
      const highlight = Object.keys(this.filterState);
      const rowStats = [];
      builtRows.forEach((row) => {
        if (row.rows.length > 0) {
          const stats = {};
          highlight.forEach((key) => {
            stats[key] = row.rows[0][key];
          });
          rowStats.push(stats);
        }
      });
      // Check and fill 0's to correct data
      let index = 0;
      labels.forEach((label) => {
        let blankRows = 0;
        builtRows.forEach((row, idx) => {
          if (row.rows.length > 0) {
            if (row.rows[index] === undefined || row.rows[index][labelColumn] !== label) {
              const obj = {};
              obj[labelColumn] = label;
              highlight.forEach((key) => {
                obj[key] = rowStats[idx - blankRows][key];
              });
              values.forEach((v) => {
                obj[v] = null;
              });
              if (row.rows[index] === undefined) row.rows.push(obj);
              else row.rows.splice(index, 0, obj);
            }
          } else {
            blankRows += 1;
          }
        });
        index += 1;
      });
      this.rows = builtRows;
      // -----------------------------------------------------
      if (this.rows.length === 1) {
        this.data = {
          labels,
          datasets: values.map((col, idx) => ({
            spanGaps: true,
            label: col,
            borderColor: colors.getColor(idx),
            backgroundColor: colors.getColor(idx),
            fill: false,
            data: this.rows[0].rows.map((r) => r[col]),
            filters: Object.entries(this.filterState).map((val) => val),
            stack: col,
          })),
        };
      } else {
        let color = -1;
        this.data = {
          labels,
          datasets: this.rows.flatMap((subset, id) => values.map((col) => {
            color += 1;
            return {
              spanGaps: true,
              label: `${subset.label} ${col}`,
              fill: false,
              borderColor: colors.getColor(color),
              backgroundColor: colors.getColor(color),
              data: subset.rows.map((r) => r[col]),
              filters: Object.entries(this.filterState).map((val) => val),
              stack: col + id,
            };
          })),
        };
      }
    },
    filterRows() {
      const nofilters = Object.values(this.filterState).every((value) => value.length === 0);
      if (!this.config.filtered || this.config.filterColumns.length === 0 || nofilters) {
        this.rows = [{ label: '', rows: this.queryResult.values }];
      } else {
        this.rows = chartData.filterData(
          this.queryResult.values,
          this.config,
          this.filterState,
        );
      }
    },
    dateSorter1(a, b) {
      if (a.date < b.date) {
        return -1;
      }
      if (a.date > b.date) {
        return 1;
      }
      return 0;
    },
    dateSorter2(a, b) {
      if (a.Date < b.Date) {
        return -1;
      }
      if (a.Date > b.Date) {
        return 1;
      }
      return 0;
    },
    validateResults() {
      if (!this.queryResult) {
        return;
      }
      const errors = [];
      const { columns } = this.queryResult.metas;
      const { labelColumn, values } = this.config;
      const valuesNames = new Set([...values]);
      [this.labelDef] = columns.filter((c) => c.name === labelColumn);
      const valuesDef = columns.filter((c) => valuesNames.has(c.name));
      const nonNumericValues = valuesDef.filter((i) => !dataTypes.isNumeric(i)).map((i) => i.name);
      if (!this.labelDef) {
        errors.push(`La colonna ${labelColumn} non é presente.`);
      }
      if (valuesDef.length === 0) {
        errors.push('Nessun valore selezionato da mostrare');
      }
      if (nonNumericValues.length > 0) {
        errors.push(`Le colonne ${nonNumericValues.join(',')} non sono di tipo numerico.`);
      }
      this.failed = errors.length !== 0;
      this.error = errors.join('\n');
    },
    saveFilters() {
      this.$emit('filterSave', { filters: this.filters, filterState: this.filterState });
    },
    compareKeys(x, y) {
      let key = true;
      Object.keys(x).forEach((ix, index) => {
        if (ix !== Object.keys(y)[index]) key = false;
      });
      return key;
    },
  },
  data() {
    return {
      data: null,
      failed: false,
      error: null,
      dataLoaded: false,
      labelDef: null,
      filters: {},
      rows: [],
    };
  },
};
</script>

<style lang="scss">
</style>
