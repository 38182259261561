<template>
  <v-navigation-drawer
    app
    permanent
    fixed
    color="secondary darken-1"
    clipped
    class="left-navigation-drawer pt-0"
    expand-on-hover
    :value="$router.currentRoute"
    mini-variant
  >
    <v-list class="pt-0" dark>
      <v-list-item
          v-if="homeEnabled($store.state.user.userName)"
          exact
          exact-active-class="navActive--text text--accent-4"
          class="iconColor--text"
          :key="'home'"
          :to="'/'">
          <v-list-item-icon>
            <v-icon class='mdi mdi-home' color="iconColor"> </v-icon>
          </v-list-item-icon>
          <v-list-item-title> Home </v-list-item-title>
      </v-list-item>
      <div v-for="(route, idx) in
          $router.getRoutes().filter((r) =>
            (!r.meta.hidden && !r.meta.parent && r.meta.dashboard))"
          :key="idx">
        <v-list-group
          v-if="$router.getRoutes().filter((s) =>
              (route.meta.dashboard
              && s.meta.parent === route.meta.dashboard.id
              && !s.meta.hidden)
            ).length > 0"
          :key="idx"
        >
          <v-list-item
            exact
            exact-active-class="activeNav--text text--accent-4"
            class="iconColor--text ml-n4 mr-n6"
            slot='activator'
            :to="route">
            <v-list-item-icon>
                <v-icon>
                {{ route.meta.dashboard.icon }}
                </v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{  16 > route.name.length ? route.name : route.name.substring(0, 12) + '...' }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            dense
            exact
            exact-active-class="activeNav--text text--accent-4"
            class="iconColor--text ml-2 pl-2 rounded-l-xl"
            v-for="(subRoute, idxs) in
              $router.getRoutes().filter((s) =>
                (route.meta.dashboard
                && s.meta.parent === route.meta.dashboard.id
                && !s.meta.hidden)
              )"
            :key="idx+'sub'+idxs"
            :to="subRoute"
            link
          >
            <v-list-item-icon>
                <v-icon class="mdi mdi-chevron-right"/>
            </v-list-item-icon>
            <v-list-item-title>{{ subRoute.name.split("#")[0] }}</v-list-item-title>
            <v-list-item-icon>
                <v-icon>
                  {{ subRoute.meta.widget.icon }}
                </v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
        <v-list-item
          v-else
          exact
          exact-active-class="activeNav--text text--accent-4"
          class="iconColor--text"
          :key="idx"
          :to="route">
            <v-list-item-icon>
              <v-icon>
                {{ route.meta.dashboard.icon }}
              </v-icon>
            </v-list-item-icon>
          <v-list-item-title>
              {{ route.name }}
            </v-list-item-title>
      </v-list-item>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
const disabledList = ['euroristoro'];

export default {
  name: 'NavigationDrawer',
  props: {
  },
  mounted() {
    this.links = this.$router.getRoutes();
  },
  methods: {
    homeEnabled(username) {
      return !disabledList.includes(username);
    },
  },
  data() {
    return {
      links: [],
    };
  },
};
</script>
