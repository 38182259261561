<template>
  <v-app-bar
    app
    clipped-left
    :style="headerStyle">
    <img
      :src="userLogo"
      class="pa-3"
      style="cursor: pointer;"
      height="62px"
      @click="redirectHome()"
    />
    <v-spacer />
    <AccountMenu/>
  </v-app-bar>
</template>

<script>
import AccountMenu from '@/general/AccountMenu.vue';

export default {
  name: 'AppBar',
  components: { AccountMenu },
  created() {
    this.$store.subscribe(((mutation, state) => {
      if (mutation.type === 'setLogo') {
        this.userLogo = state.theme.logo;
      }
    }));
  },
  mounted() {
    const { userName } = this.$store.state.user;
    if (userName === 'batelco') {
      // eslint-disable-next-line global-require
      this.userLogo = require('@/assets/batelco-logo.png');
    } else if (userName === 'euroristoro') {
      // eslint-disable-next-line global-require
      this.userLogo = require('@/assets/vedrai-logo.png');
    } else {
      this.loadThemeLogo();
      // // eslint-disable-next-line global-require
      // this.userLogo = require('@/assets/vedrai-logo.png');
    }
  },
  computed: {
    headerStyle() {
      const { bannerColorR } = this.$store.state.theme;
      const { bannerColorL } = this.$store.state.theme;
      const { primaryColor } = this.$store.state.theme;
      const { secondaryColor } = this.$store.state.theme;
      if (bannerColorR) {
        if (bannerColorL) {
          // eslint-disable-next-line prefer-template
          return 'background-image: linear-gradient(to right, ' + bannerColorL + ',' + bannerColorR + ')';
        }
        // eslint-disable-next-line prefer-template
        return 'background-image: linear-gradient(to right, ' + bannerColorR + ',' + bannerColorR + ')';
      }
      if (bannerColorL) {
        // eslint-disable-next-line prefer-template
        return 'background-image: linear-gradient(to right, ' + bannerColorL + ',' + bannerColorL + ')';
      }
      // eslint-disable-next-line prefer-template
      return 'background-image: linear-gradient(to right, ' + secondaryColor + ',' + primaryColor + ')';
    },
  },
  methods: {
    async loadThemeLogo() {
      this.userLogo = this.$store.state.theme.logo;
    },
    redirectHome() {
      this.$router.push('/');
    },
  },
  data() {
    return {
      userLogo: null,
    };
  },
};
</script>
