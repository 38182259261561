<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        @click="dialog = true"
        color="primary" icon small>
        <v-icon color="primary" class="mdi mdi-delete"/>
      </v-btn>
    </template>
    <v-card>
      <v-card-text class="pt-3 text-h6">
        Confermi l'eliminazione di questo Widget?
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          color="primary"
          outlined
          @click="handleCancel"
        >
          Anulla
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="handleDelete"
        >
          Cancella
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteConfirm',
  methods: {
    handleDelete() {
      this.dialog = false;
      this.$emit('confirm');
    },
    handleCancel() {
      this.dialog = false;
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style>
</style>
