<template>
  <div>
    <div v-if="dataLoaded && !failed" class="pt-2 mb-n4">
      <DonutChart
        v-if="config && config.donut"
        ref="chart"
        :height="height -(config.filtered? 50 : 0)"
        :chart-data="data"
        :options="options"/>
      <PieChart
        v-else
        ref="chart"
        :height="height -(config.filtered? 50 : 0)"
        :chart-data="data"
        :options="options"/>
    </div>
    <v-progress-circular v-else-if="loadingData" indeterminate color="primary"/>
    <div style="height: 100%;" class="fill-height overflow-hidden"
        v-else-if="failed">
      <v-card
        shaped
        align="center"
        style="margin: 4% 8%;"
        class="error--text text-subtitle-1 pa-4 fluid"
      >
        <div v-if="adminView">
          <div class="font-weight-bold"> Errore durante l'estrazione dei dati.</div>
          <br/>
          {{error}}
        </div>
        <div  v-else> Errore Interno, contatta l'amministratore. </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import colors from '@/tools/colors';
import dataTypes from '@/tools/dataTypes';

import PieChart from '@/admin/components/main/DashboardEditor/widgets/pie_chart/PieChart.vue';
import DonutChart from '@/admin/components/main/DashboardEditor/widgets/pie_chart/DonutChart.vue';
import chartData from '@/tools/chartData';

export default {
  name: 'PieChartWidget',
  components: { PieChart, DonutChart },
  props: {
    widget: {
      type: Object,
      required: true,
    },
    dataset: {
      type: Object,
      required: true,
    },
    queryResult: {
      type: Object,
      required: true,
    },
    filterState: {
      type: Object,
      required: false,
    },
    height: {
      type: Number,
      required: true,
    },
    widgetFilters: {
      type: Object,
      required: false,
    },
    adminView: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    config() {
      return this.widget?.config?.pie_chart;
    },
    options() {
      const legend = {
        position: 'bottom',
      };
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend,
      };
    },
  },
  mounted() {
    this.loadData();
  },
  watch: {
    dataset() {
      this.loadData();
    },
    config: {
      handler() {
        this.buildData();
      },
      deep: true,
    },
  },
  methods: {
    async loadData() {
      if (this.loadingData || !this.queryResult || !this.config) {
        return;
      }
      this.loadingData = true;
      const data = this.queryResult;
      if (data.failed) {
        this.failed = true;
        this.error = data.error;
      } else {
        this.resultSet = data;
        this.buildData(data);
      }
      this.loadingData = false;
      this.dataLoaded = true;
    },
    buildData() {
      if (!this.resultSet) {
        return;
      }
      this.validateResults();
      if (this.failed) {
        return;
      }
      this.filterRows();
      this.buildChartData();
      this.saveFilters();
    },
    handleFilterChange() {
      this.filterRows();
      this.buildChartData();
      this.saveFilters();
    },
    buildChartData() {
      // missing validation

      const { labels, values } = this.config;
      const labelsName = [...new Set(this.rows.map((r) => r[labels]))];
      const resultData = [];
      labelsName.forEach(() => {
        resultData.push(0);
      });
      this.rows.forEach((r) => {
        resultData[labelsName.indexOf(r[labels])] += r[values];
      });
      // const resultData = this.rows.map((r) => r[values]);
      const graphColor = [];
      for (let i = 0; i < resultData.length; i += 1) graphColor.push(colors.getColor(i));
      this.data = {
        labels: labelsName,
        datasets: [values[0]].map((col) => ({
          label: col,
          borderColor: 'rgba(255, 255, 255, 0)',
          backgroundColor: graphColor,
          data: resultData,
        })),
      };
    },
    filterRows() {
      if (!this.config?.filtered || this.config?.filterColumns?.length === 0) {
        this.rows = this.queryResult.values;
        return;
      }
      const filtered = chartData.filterData(
        this.queryResult.values,
        this.config,
        this.filterState,
      );
      this.rows = filtered[0].rows;
    },
    validateResults() {
      if (!this.resultSet) {
        return;
      }
      const errors = [];
      const { columns } = this.resultSet.metas;
      const { labels, values } = this.config;
      [this.labelDef] = columns.filter((c) => c.name === labels);
      [this.valueDef] = columns.filter((c) => c.name === values);
      const nonNumericValue = !dataTypes.isNumeric(this.valueDef);
      if (!this.labelDef) {
        errors.push(`La colonna ${labels} non é presente.`);
      }
      if (!this.valueDef) {
        errors.push('Nessun valore selezionato da mostrare');
      }
      if (nonNumericValue) {
        errors.push(`La colonna ${this.valueDef.name} non è di tipo numerico.`);
      }
      this.failed = errors.length !== 0;
      this.error = errors.join('\\n');
    },
    saveFilters() {
      this.$emit('filterSave', { filters: this.filters, filterState: this.filterState });
    },
    compareKeys(x, y) {
      let key = true;
      Object.keys(x).forEach((ix, index) => {
        if (ix !== Object.keys(y)[index]) key = false;
      });
      return key;
    },
  },
  data() {
    return {
      data: null,
      failed: false,
      error: null,
      loadingData: false,
      dataLoaded: false,
      resultSet: null,
      labelDef: null,
      valueDef: null,
      rows: [],
    };
  },
};
</script>

<style lang="scss">
</style>
