<template>
  <!-- In Widget selector -->
  <v-col cols="6" v-if="metas && !adminPanel">
    <v-autocomplete
      v-model="valueCol"
      :items="getNumericColumns"
      required
      dense
      small-chips
      hide-details="auto"
      label="Ordinata"
      class="mb-7"
      @change="handleValueChange"/>
  </v-col>
  <!-- Widget Config Section -->
  <v-form v-else-if="metas" ref="form">
    <!-- Axis and rows to show -->
    <v-row>
      <v-col cols="6">
        <v-select
          v-model="value.labelColumn"
          :items="metas.columns"
          :item-text="(i) => `${i.name} (${i.type})`"
          item-value="name"
          required
          label="Etichetta"
          @select="updateValue"/>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="valueCol"
          :items="metas.columns.filter((val) => {return isNumeric(val)})"
          :item-text="(i) => `${i.name} (${i.type})`"
          item-value="name"
          required
          label="Values"
          @change="handleValueChange"/>
      </v-col>
    </v-row>
    <!-- Label text editors -->
    <v-row>
      <v-col cols=6>
        <v-text-field
          dense
          hide-details="auto"
          v-model="value.tagTotal1"
          @input="updateValue"
          label="Etichetta Totale 1"
        />
      </v-col>
      <v-col cols=6>
        <v-text-field
          dense
          hide-details="auto"
          v-model="value.tagTotal2"
          @input="updateValue"
          label="Etichetta Totale 2"
        />
      </v-col>
      <v-col cols=6>
        <v-text-field
          dense
          hide-details="auto"
          v-model="value.tagVersus"
          @input="updateValue"
          label="Etichetta Confronto"
        />
      </v-col>
      <v-col cols=12 class="flex-columns">
        <v-slider
          v-model="value.operation"
          :tick-labels="operationsLabel"
          :max="6"
          step="1"
          ticks="always"
          tick-size="4"
          :thumb-size="24"
          thumb-label="always"
          class="mx-2 mt-2"
          @input="updateOperationValue"
        >
          <template v-slot:thumb-label=""></template>
        </v-slider>
        <br>
        <p class="mx-4 mt-n6">Operazione: {{operations[value.operation]}}</p>
      </v-col>
      <v-col cols=6>
        <v-text-field
          dense
          hide-details="auto"
          v-model="value.tagChart1"
          @input="updateValue"
          label="Nome Legenda 1"
        />
      </v-col>
      <v-col cols=6>
        <v-text-field
          dense
          hide-details="auto"
          v-model="value.tagChart2"
          @input="updateValue"
          label="Nome Legenda 2"
        />
      </v-col>
    </v-row>
    <!-- Hide/show Info Rows -->
    <v-row class="mt-0">
      <v-col cols=12>
        <v-switch
          hide-details="auto"
          v-model="value.hideAxisSelector"
          dense
          class="my-0"
          @change="updateValue"
          label="Nascondi Selettore Ordinata"
        />
      </v-col>
      <v-col cols=12>
        <v-switch
          hide-details="auto"
          v-model="value.hideDetails"
          dense
          class="my-0"
          @change="updateValue"
          label="Nascondi Dettagli"
        />
      </v-col>
    </v-row>
    <!-- Main Dataset Selector -->
    <v-row class="pa-0">
      <v-col cols="auto" class="d-flex align-center mx-1">
          <h3 class="mr-2">Focus Data:</h3>
          <div class="mx-1">Dataset 1</div>
          <v-switch
            class=""
            v-model="value.order"
            @change="handleValueChange"
          />
          <div class="mx-1">Dataset 2</div>
      </v-col>
    </v-row>
    <!-- Filter Config -->
    <v-row>
      <v-col cols="4">
        <v-switch
          v-model="value.filtered"
          @change="updateValue"
          label="Mostra Filtri"/>
      </v-col>
      <v-col cols="8" v-if="value.filtered">
        <v-combobox
          dense
          v-model="value.filterColumns"
          item-text="name"
          @change="updateValue"
          :items="metas.columns"
          label="Colonne filtrabili"
          multiple
          small-chips
        />
      </v-col>
    </v-row>
    <v-row>
      <v-spacer/>
      <v-btn class="rounded-pill primary font-weight-bold white--text py-1 px-2 mb-1 mr-3"
        @click="saveDefaults">
        Salva la selezione come Default
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import dataTypes from '@/tools/dataTypes';

export default {
  name: 'MultiChartConfig',
  props: {
    adminPanel: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    dataset: {
      type: Object,
      required: true,
    },
    metas: {
      type: Object,
      required: false,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  watch: {
    // Changes on config from outside the widget
    value: {
      deep: true,
      handler() {
        this.valuesCols = this.value.values;
      },
    },
  },
  computed: {
    // Returns the list of columns that have numeric values
    getNumericColumns() {
      const filtered = this.metas.columns.filter((item) => this.isNumeric(item));
      return filtered.map((x) => x.name);
    },
  },
  methods: {
    isNumeric: dataTypes.isNumeric,
    // Unused, resets config to empty values
    reset() {
      this.$refs.form.reset();
    },
    updateValue() {
      this.$emit('input', this.value);
    },
    updateOperationValue() {
      // this.value.operation = this.operationNumber;
      this.$emit('input', this.value);
    },
    handleValueChange() {
      this.openMenu = false;
      this.valuesCols = [this.valueCol];
      this.value.values = this.valuesCols;
      this.updateValue();
    },
    // Set the current in-widget selected filter values as its default values
    saveDefaults() {
      this.$emit('saveDef');
    },
  },
  data() {
    return {
      openMenu: false,
      multiValue: this.value.multiValue,
      numericCols: [],
      valuesCols: this.value.values,
      valueCol: this.value.values[0],
      operationNumber: 0,
      operationsLabel: ['+', '-', '- (%)', '×', '÷', 'x̄', 'σ'],
      operations: ['Somma', 'Differenza', 'Differenza (Percentuale)', 'Prodotto', 'Rapporto', 'Media', 'Deviazione standard'],
    };
  },
};
</script>

<style scoped>

</style>
