<template>
  <v-row class="pb-4 px-2" heigth=2>
    <!-- Query Params -->
    <v-col class="px-2"
      v-for="param in filteredParams"
      :key="param.name"
    >
      <!-- Selector Expression -->
      <v-select
        dense
        hide-details
        :label="param.displayAs"
        v-if="param.type === 'query_expression'"
        :items="param.allowedValues"
        item-text="displayAs"
        v-model="value.queryParams[param.name]"
        item-value="expression"
        @change="$emit('input', value)"/>
      <!-- Text Expression -->
      <v-text-field
        v-else-if="param.type === 'text'"
        hide-details
        dense
        v-model="value.queryParams[param.name]"
        :label="param.displayAs"
        @change="$emit('input', value)"/>
      <!-- Single Data Expression -->
      <v-menu
        v-else-if="param.type === 'date'"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        class="py-0"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="value.queryParams[param.name]"
            :label="param.displayAs"
            prepend-icon="mdi-calendar"
            readonly
            dense
            hide-details
            v-bind="attrs"
            v-on="on"/>
        </template>
        <v-date-picker
          v-model="value.queryParams[param.name]"
          @input="() => {param.open = false;}"/>
      </v-menu>
      <!-- Data Range Expression -->
      <v-menu
        v-else
        v-model="param.open"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        class="py-0"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="multiDate[param.name]"
            :label="param.displayAs"
            prepend-icon="mdi-calendar"
            readonly
            dense
            hide-details
            v-bind="attrs"
            v-on="on"/>
        </template>
        <v-list flat>
          <v-list-item-group
            color="primary"
          >
            <v-menu
              v-for="item in ['Anno', 'Mese', 'Intervallo']"
              :key="item"
              :close-on-content-click="item !== 'Intervallo' ? true : false"
              :nudge-right="4"
              offset-x
              min-width="auto"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="$vuetify.lang.t(item)"/>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <!-- Year -->
              <v-card v-if="item == 'Anno'" style="max-height: 242px">
                <v-list>
                  <v-list-item
                    v-for="item in yearList"
                    :key="item+param.name"
                    dense
                    @click="yearToAnd(item, param)"
                    class="px-16"
                    :class="years[param.name] === item ? 'grey': 'white'"
                  >
                    <v-list-item-title class="text-center">
                      <h3 class="font-weight-medium">{{ item }}</h3>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
              <!-- Month -->
              <v-date-picker
                v-if="item == 'Mese'"
                v-model="months[param.name]"
                no-title
                type="month"
                @click:month="monthToAnd(param.name)"
                class="justify-center"
              />
              <!-- Daily -->
              <v-date-picker
                v-if="item == 'Intervallo'"
                no-title
                v-model="mp[param.name]"
                range
                @change="daysToAnd(param.name)"
              />
            </v-menu>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-col>
    <v-col class="px-2" v-for="param in params2" :key="param.name">
      <v-select
        dense
        hide-details
        :label="param.displayAs"
        v-if="param.type === 'query_expression'"
        :items="param.allowedValues"
        item-text="displayAs"
        v-model="value.queryParams2[param.name]"
        item-value="expression"
        @change="$emit('input', value)"/>
      <v-text-field
        v-else-if="param.type === 'text'"
        hide-details
        dense
        v-model="value.queryParams2[param.name]"
        :label="param.displayAs"
        @change="$emit('input', value);"/>
      <v-menu
        v-else
        v-model="param.open"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        class="py-0"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="value.queryParams2[param.name]"
            :label="param.displayAs"
            prepend-icon="mdi-calendar"
            readonly
            dense
            hide-details
            v-bind="attrs"
            v-on="on"/>
        </template>
        <v-date-picker
          v-model="value.queryParams2[param.name]"
          @input="() => {param.open = false; $emit('input', value);}"/>
      </v-menu>
    </v-col>
    <!-- Filter State -->
    <v-col
      v-for="col in filterKeys.filter((x) => !(globalFilters || []).includes(x))"
      :key="col" class="px-2"
    >
      <v-row no-gutters class="flex-wrap d-flex flex-nowrap">
        <v-col class="pa-0  flex-grow-1 d-inline-flex">
          <v-autocomplete
            class="text-body-2 newSelect"
            :items="filterValues[col]"
            :multiple="multiValue"
            dense
            v-model="value.filterState[col]"
            hide-details
            flat
            :label="col"
            :search-input.sync="search[col]"
            @change="sendValue(value)"
            @focus="focus[col] = true"
            @blur="focus[col] = false; search[col]=''; $emit('syncf')"
          >
            <template v-slot:prepend-inner>
              <v-tooltip top >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                v-show="search[col] != '' && search[col] != null && focus[col]"
                small
                icon
                class="ml-1"
                v-on="on"
                v-bind="attrs"
                @click="pushAll(search[col], filterValues[col], value.filterState[col])"
              >
                <v-icon color="primary">mdi-checkbox-marked</v-icon>
              </v-btn>
                </template>
                <span>Seleziona tutti</span>
              </v-tooltip>
            </template>
            <template v-slot:selection="{ item, index }">
              <span v-if="index == 0">
                {{item}}
              </span>
              <v-chip label x-small v-else-if="index == 1">
                <span style="font-size: .95rem">+{{value.filterState[col].length - 1}}</span>
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col v-if="value.filterState[col]" class="pa-0 mt-1 mb-n1 flex-grow-0 d-inline-flex">
          <v-btn icon small @click="toggleAll(col)">
            {{value.filterState[col].length}}
            <v-icon
              size="24"
              v-if="multiValue && value.filterState[col].length == 0"
              color="secondary"
              class="mdi mdi-checkbox-blank-outline"
            />
            <v-icon
              size="24"
              v-else-if="multiValue &&
                value.filterState[col].length < (filterValues[col]||[]).length"
              color="secondary"
              class="mdi mdi-checkbox-intermediate"
            />
            <v-icon
              size="24"
              v-else-if="multiValue"
              color="secondary"
              class="mdi mdi-checkbox-marked"
            />
          </v-btn>
          <v-btn
            v-if="showStacked"
            :color="stacked && stackedCol == col ? 'info' : 'gray'"
            icon small @click="toggleStack(col)">
            <v-icon class="mdi mdi-chart-bar-stacked"/>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-tooltip bottom
    v-if="(filterKeys.length - globalFilters.length) > 1">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon small
          @click="deselectAllFilters" class="mt-4 mr-1"
          v-on="on"
          v-bind="attrs"
        >
          <v-icon
            size="24"
            color="secondary"
            class="mdi mdi-eraser-variant"
          />
        </v-btn>
      </template>
      <span>Cancella Filtri</span>
    </v-tooltip>
    <v-progress-linear v-if="loading" class="mt-n2" indeterminate/>
  </v-row>
</template>

<script>
import moment from 'moment';
import dataTypes from '@/tools/dataTypes';

export default {
  name: 'WidgetQueryParams',
  props: {
    loading: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    value: {
      type: Object,
      required: true,
    },
    params: {
      type: Array,
      required: true,
    },
    params2: {
      type: Array,
      required: true,
    },
    globalFilters: {
      type: Array,
      required: true,
    },
    globalParams: {
      type: Array,
      required: true,
    },
    filterValues: {
      type: Object,
      required: true,
    },
    multiValue: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    showStacked: {
      required: false,
      type: Boolean,
      default: () => false,
    },
  },
  created() {
    this.convertDateRanges();
  },
  mounted() {
    const y = [];
    for (let i = 2000; i < 2040; i += 1) {
      y.push(String(i));
    }
    this.yearList = y;
    this.pushGlobalFilters();
    this.pushGlobalParams();
    this.sortValues();
  },
  computed: {
    filterKeys() {
      return Object.keys(this.filterValues);
    },
    filteredParams() {
      return this.params.filter((x) => !(this.globalParams || []).includes(x.name));
    },
  },
  methods: {
    convertDateRanges() {
      this.params.forEach((param) => {
        if (param.type === 'date-range') {
          if (param.useDefaultValues) {
            this.multiDate[param.name] = dataTypes.convertDefaultInterval(param.defaultValue);
            this.value.queryParams[param.name] = this.arrayToAnd(this.multiDate[param.name]);
          } else {
            this.multiDate[param.name] = param.defaultValue;
            this.value.queryParams[param.name] = this.arrayToAnd(param.defaultValue);
          }
        }
      });
    },
    andToArray(x) {
      const array = [x.substring(6, 16), x.substring(28, 38)];
      return array;
    },
    yearToAnd(year, param) {
      // eslint-disable-next-line no-param-reassign
      param.open = false;
      this.years[param.name] = year;

      const firstDay = moment(this.years[param.name]).startOf('year').format('YYYY-MM-DD');
      const lastDay = moment(this.years[param.name]).endOf('year').format('YYYY-MM-DD');
      this.multiDate[param.name] = [firstDay, lastDay];
      this.mp[param.name] = [firstDay, lastDay];
      this.value.queryParams[param.name] = this.arrayToAnd(this.multiDate[param.name]);
    },
    monthToAnd(name) {
      const firstDay = moment(this.months[name]).startOf('month').format('YYYY-MM-DD');
      const lastDay = moment(this.months[name]).endOf('month').format('YYYY-MM-DD');
      this.multiDate[name] = [firstDay, lastDay];
      this.mp[name] = [firstDay, lastDay];
      this.value.queryParams[name] = this.arrayToAnd(this.multiDate[name]);
    },
    daysToAnd(name) {
      if (this.mp[name].length === 2) {
        this.multiDate[name] = this.mp[name];
        this.months[name] = null;
      }
      this.value.queryParams[name] = this.arrayToAnd(this.multiDate[name]);
    },
    arrayToAnd(x) {
      if (!x[0]) return 'err';
      let string = "DATE('";
      string += x[0];
      string += "') AND DATE('";
      if (x[1]) string += x[1];
      else string += x[0];
      string += "')";
      return string;
    },
    pushAll(searchVal, itemsList, selection) {
      itemsList.forEach((item) => {
        if (item.toUpperCase().includes(searchVal.toUpperCase()) && !selection.includes(item)) {
          selection.push(item);
        }
      });
    },
    sortValues() {
      Object.keys(this.filterValues).forEach((k) => {
        this.filterValues[k] = this.filterValues[k].sort();
      });
    },
    emitThis(value) {
      this.$emit('input', value);
    },
    toggleAll(col) {
      // Copy all v-select's items in your selectedItem array
      if (this.value.filterState[col].length !== this.filterValues[col].length) {
        // eslint-disable-next-line prefer-destructuring
        this.value.filterState[col] = [...this.filterValues[col]];
      } else {
        this.value.filterState[col] = [];
      }
      this.$emit('input', this.value);
    },
    toggleStack(col) {
      if (this.stackedCol !== col) {
        this.stacked = this.stackedCol !== col;
      } else {
        this.stacked = !this.stacked;
      }
      this.stackedCol = col;
      const values = this.stacked ? this.value.filterState[col] : null;
      this.$emit('stack', col, values);
    },
    pushGlobalFilters() {
      this.$root.$emit('pushGlobalFilters', this.filterValues);
    },
    pushGlobalParams() {
      const params = {};
      Object.keys(this.value.queryParams).forEach((key) => {
        if (this.multiDate[key]) params[key] = this.multiDate[key];
        else params[key] = this.value.queryParams[key];
      });

      this.$root.$emit('pushGlobalParams', params);
    },
    async sendValue(value) {
      this.$emit('input', value);
      this.sortValues();
    },
    deselectAllFilters() {
      Object.keys(this.value.filterState).forEach((filter) => {
        this.value.filterState[filter] = [];
      });
      this.$emit('input', this.value);
      this.$emit('syncf');
    },
  },
  data() {
    return {
      console,
      stacked: false,
      stackedCol: '',
      months: {},
      yearList: [],
      years: {},
      multiDate: {},
      mp: {},
      search: {},
      focus: {},
    };
  },
};
</script>

<style>
.newSelect .v-select__selections {
  max-width: 100%;
  overflow: clip;
  max-height: 26px;
}
</style>
