<template>
  <div :style="'height: ' + (height - 42) + 'px'" class="d-flex justify-center align-center mt-1">
    <v-row v-if="!error && !detailed">
      <v-spacer/>
        <div
          v-if="showVariation"
          class="mt-4"
          :style="{
            fontSize: textSize +'px', height: textSize+8+'px', fontWeight: 600, whiteSpace: 'nowrap'
          }"
        >
          <span> {{numberFormat(growth)}} </span>
          <span> {{symbol}}</span>
          <span v-if="
            widget.config.number.value1 !== widget.config.number.value2 &&
            widget.config.number.value2"
          >
            <v-icon v-if="grow === 1" :size="textSize" color="green"
            class="mdi mdi-code-less-than mdi-rotate-90 mt-n3"/>
            <v-icon v-else-if="grow === -1" :size="textSize" color="red"
            class="mdi mdi-code-less-than mdi-rotate-270  mt-n3"/>
            <v-icon v-else-if="grow === 0" :size="textSize" color="gray"
            class="mdi mdi-equal-box mt-n3"/>
            <v-icon v-else :size="textSize" color="blue"
            class="mdi mdi-minus mt-n3"/>
          </span>
        </div>
      <v-spacer/>
    </v-row>
    <v-row v-else-if="!error && detailed" class="d-flex justify-center">
      <v-col class="mx-8">
        <v-row
          v-if="showVariation"
          class="mt-3 mb-5 mx-0 justify-center align-center"
          style="width: 100%"
          :style="{fontSize: textSize +'px', fontWeight: 600, whiteSpace: 'nowrap'}"
        >
          <span v-if="
            widget.config.number.value1 !== widget.config.number.value2 &&
            widget.config.number.value2"
          >
            <v-icon v-if="grow === 1" :size="textSize" color="green"
            class="mdi mdi-arrow-top-right-thin my-n2"/>
            <v-icon v-else-if="grow === -1" :size="textSize" color="red"
            class="mdi mdi-arrow-bottom-right-thin my-n2"/>
            <v-icon v-else-if="grow === 0" :size="textSize" color="gray"
            class="mdi mdi-equal my-n2"/>
            <v-icon v-else :size="textSize" color="blue"
            class="mdi mdi-minus"/>
          </span>
          <span
            class="mx-1"
            :class="(grow === 1 ? 'green' : grow === -1 ? 'red' : 'gray')+'--text text--lighten-1'"
          >
            {{numberFormat(growth)}} {{symbol}}
          </span>
        </v-row>
        <v-row
          class="mt-n2 mb-0 mx-0"
          :style="
          {fontSize: textSize/2 +'px', height: textSize+'px', whiteSpace: 'nowrap', width: '100%'}"
        >
          <v-col cols=6 class="ma-0 d-flex flex-column justify-center">
            <span :style="'font-weight: 300; height:' + textSize/2.3 + 'px'">
              {{actualLabel}}
            </span>
            <span class="mt-1" :style="'font-weight: 600; height:' + textSize/2 + 'px'">
              {{numberFormat(actualValue)}}
            </span>
          </v-col>
          <v-divider vertical class="mt-2"/>
          <v-col cols=6 class="ma-0 d-flex flex-column justify-center">
            <span :style="'px; font-weight: 300; height:' + textSize/2.3 + 'px'">
              {{forecastLabel}}
            </span>
            <span class="mt-1" :style="'font-weight: 600; height:' + textSize/2 + 'px'">
              {{numberFormat(forecastValue)}}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <div class="d-flex flex-column justify-start">
      </div>
    </v-row>
    <div class="error--text" v-else-if="adminView">
      Errore durante l'estrazione dei dati.
      <br/>
      {{error}}
    </div>
    <div class="error--text" v-else-if="failed && !adminView">
      Errore Interno, contatta l'amministratore.
    </div>
  </div>
</template>

<script>
export default {
  name: 'NumberWidget',
  components: { },
  props: {
    widget: {
      type: Object,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    queryResult: {
      type: Object,
      required: true,
    },
    fullscreen: {
      type: Boolean,
      required: false,
    },
    widgetFilters: {
      type: Object,
      required: false,
    },
    filterState: {
      type: Object,
      required: false,
    },
    adminView: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    config() {
      return this.widget?.config?.number;
    },
    options() {
      const legend = {
        position: 'bottom',
      };
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend,
      };
    },
  },
  mounted() {
    this.resize();
    this.loadData();
  },
  methods: {
    resize() {
      // 35 : 6
      if (this.fullscreen) {
        this.textSize = (window.innerWidth / 35) * 6;
      } else {
        const scalar = 2;
        this.textSize = Math.min(
          (this.widget.config.cols * 7 * scalar), ((this.widget.config.height - 1) * 14 * scalar),
        );
      }
    },
    rowClass() {
      return (`mt-${this.widget.config.height}`);
    },
    loadData() {
      if (!this.config) {
        return;
      }
      if (this.queryResult.failed) {
        this.failed = true;
        this.error = this.queryResult.error;
      } else {
        this.failed = false;
        this.error = null;
        this.buildData();
      }
      this.dataLoaded = true;
    },
    buildData() {
      // missing validation

      this.label = this.config.labels;
      this.detailed = this.config.detailed;
      const { numeric } = this.config;
      // ['Somma', 'Differenza', 'Differenza (Percentuale)', 'Prodotto', 'Rapporto', 'Media', 'Deviazione standard']
      //   0     ,  1          , 2                         , 3         , 4         ,  5     ,  6
      let { operation } = this.config;
      if (!operation && operation !== 0) operation = numeric ? 1 : 2;
      // Fit from old model
      const { symbol } = this.config;
      this.showVariation = !this.widget.config.number.hideVariation;

      // Chop data to filter selections here
      // Contains the data to display/make the growth operation
      let numberArray;
      if (this.config.filtered) {
        this.filterRows();
      }
      if (this.config.filtered && this.rows.length > 0) {
        numberArray = this.rows;
      } else numberArray = this.queryResult.values.map((a) => (a[this.config.value1]));
      if (this.config.value2 && !this.config.filtered) {
        numberArray = numberArray.concat(
          this.queryResult.values.map((a) => (a[this.config.value2])),
        );
      }

      if (numberArray.length > 1) {
        // eslint-disable-next-line prefer-destructuring
        const val1 = numberArray[0];
        const val2 = numberArray[numberArray.length - 1];
        // eslint-disable-next-line prefer-destructuring
        this.actualValue = numberArray[0];
        this.actualLabel = this.config.labelVal1 || 'Actual';
        this.forecastValue = numberArray[numberArray.length - 1];
        this.forecastLabel = this.config.labelVal2 || 'Forecast';
        // get max of selected config number, vs most recent number, display difference in %

        // TODO if (numeric) {
        // Sum
        if (operation === 0) {
          this.growth = (val2 + val1).toFixed(2);
          this.grow = 0;
          this.growth = `${this.growth}`;
          this.symbol = symbol || '';
        // Difference Numeric
        } else if (operation === 1) {
          this.growth = (val2 - val1).toFixed(2);
          if (this.growth < 0) this.grow = -1;
          else if (this.growth > 0) this.grow = 1;
          else this.grow = 0;

          this.growth = `${this.growth}`;
          this.symbol = symbol || '';
        // Difference %
        } else if (operation === 2) {
          this.growth = (((val2 - val1) / val1) * 100).toFixed(2);
          if (this.growth < 0) this.grow = -1;
          else if (this.growth > 0) this.grow = 1;
          else this.grow = 0;

          this.growth = `${this.growth}`;
          this.symbol = symbol || '%';
        // Product
        } else if (operation === 3) {
          this.growth = ((val2 === 0 ? 1 : val2) * (val1 === 0 ? 1 : val1)).toFixed(2);
          this.grow = 0;

          this.growth = `${this.growth}`;
          this.symbol = symbol || '';
        // Quotient
        } else if (operation === 4) {
          this.growth = (val2 / (val1 === 0 ? 1 : val1)).toFixed(2);
          this.grow = 0;

          this.growth = `${this.growth}`;
          this.symbol = symbol || '';
        // Average
        } else if (operation === 5) {
          const realLength = (numberArray.length - (this.rows.includes(0) ? 1 : 0));
          this.growth = (numberArray.reduce((a, b) => a + b, 0) / realLength).toFixed(2);
          this.grow = 0;

          this.growth = `${this.growth}`;
          this.symbol = symbol || '';
        // Std. Deviation
        } else if (operation === 6) {
          const realLength = (numberArray.length - (this.rows.includes(0) ? 1 : 0));
          const mean = (numberArray.reduce((a, b) => a + b, 0) / realLength);
          const square = numberArray.map((x) => (x - mean) ** 2);
          this.growth = Math.sqrt(square.reduce((acc, curr) => acc + curr, 0)).toFixed(2);
          this.grow = 0;

          this.growth = `${this.growth}`;
          this.symbol = symbol || '';
        }
      } else {
        // eslint-disable-next-line prefer-destructuring
        this.growth = numberArray[0];
        this.grow = 2;
        if (numberArray.length === 0) this.symbol = '';
        else if (numeric) this.symbol = symbol || '';
        else this.symbol = symbol || '%';
      }
    },
    numberFormat(num) {
      if (!num) return num;
      if (num % 1 === 0) return String(Math.round(num)).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1.');
      return String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1.').replace(/.([^.]*)$/, ',$1');
    },
    filterRows() {
      this.rows = [];
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const el in this.filterState) {
        this.rows = this.rows.concat(
          this.queryResult.values.filter((v) => this.filterState[el].includes(v[el])),
        );
      }
      if (!(this.config.filtered && this.config.filterColumns.length > 0)) {
        this.rows = this.queryResult.values.map((a) => (a[this.config.value1]));
      } else {
        this.rows = this.rows.map((a) => (a[this.config.value1]));
        if (this.queryResult.values.length === 2 && this.rows.length === 1) {
          if (this.queryResult.values[0][this.config.value1] === this.rows[0]) this.rows.push(0);
          else this.rows.unshift(0);
        }
      }
    },
    handleFilterChange() {
      this.buildData();
    },
  },
  watch: {
    widget: {
      handler() {
        this.loadData();
        this.resize();
      },
      deep: true,
    },
    queryResult: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
  },
  data() {
    return {
      tab: 'code',
      grow: true,
      growth: null,
      symbol: '',
      showVariation: true,
      detailed: false,
      actualValue: 0,
      actualLabel: '',
      forecastValue: 0,
      forecastLabel: '',
      label: null,
      textSize: this.height,
      filters: {},
      rows: null,
      error: null,
    };
  },
};
</script>
