<template>
  <v-dialog v-model="dialog" width="700">
    <template v-slot:activator="{ on, attrs}">
      <v-btn
        class="mx-2"
        color="primary"
        v-bind="attrs"
        v-on="on"
        outlined
        @click="openDialog"
      >
        <v-icon>mdi-account</v-icon>
        Utenti
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span>Menu Utente</span>
        <v-spacer/>
        <v-btn icon @click="dialog = false">
          <v-icon class="mdi mdi-close"/>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-tabs v-model="tab">
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab key="actual">
            Utenti Associati
          </v-tab>
          <v-tab key="existing">
            Utente Esistente
          </v-tab>
          <v-tab key="newUser">
            Nuovo Utente
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab"  class="pt-5">
          <v-tab-item key="actual">
            <v-list dense>
              <v-list-item
                v-for="(user, i) in users.filter((u) => u.themeId === theme.id)"
                :key="i"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    Name: {{user.firstName}}
                    {{user.lastName}}
                  </v-list-item-title>
                  <v-list-item-content>
                    Username: {{user.userName}}
                  </v-list-item-content>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    icon
                    color="primary"
                    :disabled="theme.id == 0"
                    @click="handleUserDelete(user)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-tab-item>
          <v-tab-item key="existing">
            <v-autocomplete
              v-model="selectedUser"
              :items="users.filter((u) => u.themeId !== theme.id)"
              dense
              :loading="loading"
              item-text="userName"
              item-value="API"
              label="Utente"
              placeholder="Cerca utenti"
              prepend-icon="mdi-database-search"
              return-object
            />
          </v-tab-item>
          <v-tab-item key="newUser">
            <v-form ref="form" lazy-validation v-model="valid">
              <v-text-field
                type="text"
                v-model="userModel.userName"
                label="Username"
                required
                :rules="formRules.userNameRules"
              />
              <v-text-field
                type="text"
                v-model="userModel.firstName"
                label="Nome"
                required
                :rules="formRules.firstNameRules"
              />
              <v-text-field
                type="text"
                v-model="userModel.lastName"
                label="Cognome"
                hint="Cognome dell'utente"
                required
                :rules="formRules.lastNameRules"
              />
              <v-text-field
                type="text"
                v-model="userModel.email"
                label="Email"
                required
                :rules="formRules.emailRules"
              />
            </v-form>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary"
               v-if="tab === 2"
               :disabled="!valid"
               @click="saveUser">
          <v-progress-circular indeterminate v-if="creatingUser"/>
          <span v-else>Crea Utente</span>
        </v-btn>
        <v-btn v-else-if="tab === 1"
               color="primary"
               @click="selectUser"
               :disabled="!selectedUser"
        >
          Seleziona Utente
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '@/api';

const userModel = {
  userName: '',
  lastName: '',
  firstName: '',
  email: '',
};

export default {
  name: 'UserSelector',
  props: {
    theme: {
      type: Object,
      require: true,
    },
  },
  mounted() {
    this.loadExistingUsers();
  },
  methods: {
    async openDialog() {
      this.dialog = true;
      await this.loadExistingUsers();
    },
    async loadExistingUsers() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.users = await api.admin.user.list();
      this.loading = false;
    },
    resetForm() {
      this.userModel = { ...userModel };
      this.$refs.form.reset();
    },
    async selectUser() {
      if (!this.selectedUser) {
        return;
      }
      await api.admin.theme.addUser(this.selectedUser.id, this.theme.id);
      this.selectedUser.themeId = this.theme.id;
      this.selectedUser = null;
    },
    async saveUser() {
      if (!this.$refs.form.validate() || this.creatingUser) {
        return;
      }
      this.creatingUser = true;
      const user = await api.admin.user.create(this.userModel);
      await api.admin.theme.addUser(user.id, this.theme.id);
      user.themeId = this.themeId;
      this.users.push(user);
      this.creatingUser = false;
      this.tab = 0;
      this.resetForm();
    },
    async handleUserDelete(user) {
      if (!user) {
        return;
      }
      await api.admin.theme.removeUser(user.id);
      user.themeId = 0;
    },
  },
  data() {
    // eslint-disable-next-line no-useless-escape
    const emailRegex = /^[^@\s]+@[^@\s]+\.[^@\s]{2,}$/;
    const userNameRegex = /^[\w-]+$/;
    return {
      formRules: {
        userNameRules: [
          (v) => !!v || 'Inserire Username',
          (v) => userNameRegex.test(v || '') || 'Username non valido',
        ],
        firstNameRules: [
          (v) => !!v || 'Inserire Nome',
        ],
        lastNameRules: [
          (v) => !!v || 'Inserire Cognome',
        ],
        emailRules: [
          (v) => !!v || 'Inserire email',
          (v) => emailRegex.test(v || '') || 'Indirizzo email non valido',
        ],
      },
      valid: true,
      dialog: false,
      tab: 'existing',
      creatingUser: false,
      users: [],
      loading: false,
      userModel: { ...userModel },
      selectedUser: null,
    };
  },
};
</script>
