const paramTypes = [
  { name: 'Espressione', value: 'query_expression' },
  { name: 'Testo', value: 'text', defaultValue: '' },
  { name: 'Data', value: 'date', defaultValue: '' },
  { name: 'Intervallo Data', value: 'date-range', defaultValue: '' },
];

const defaultDates = [
  { name: 'Data Corrente', value: 'current_date' },
  { name: 'Ieri', value: 'yesterday' },
  { name: 'Inizio Mese Corrente', value: 'current_month_start' },
  { name: 'Fine Mese Corrente', value: 'current_month_end' },
  { name: 'Fine Mese Precedente', value: 'past_month_start' },
  { name: 'Inizio Mese Precedente', value: 'past_month_end' },
  { name: 'Inizio Trimeste Corrente', value: 'current_quarter_start' },
  { name: 'Fine Trimeste Corrente', value: 'current_quarter_end' },
  { name: 'Inizio Trimeste Precedente', value: 'past_quarter_start' },
  { name: 'Fine Trimeste Precedente', value: 'past_quarter_end' },
  { name: 'Inizio Anno Corrente', value: 'current_year_start' },
  { name: 'Fine Anno Corrente', value: 'current_year_end' },
  { name: 'Inizio Anno Precedente', value: 'past_year_start' },
  { name: 'Fine Anno Precedente', value: 'past_year_end' },
];

const defaultIntervals = [
  { name: 'Data Corrente', value: 'current_date' },
  { name: 'Ieri e Oggi', value: 'yesterday' },
  { name: 'Mese Corrente', value: 'current_month' },
  { name: 'Mese Precedente', value: 'past_month' },
  { name: 'Trimeste Corrente', value: 'current_quarter' },
  { name: 'Trimeste Precedente', value: 'past_quarter' },
  { name: 'Anno Corrente', value: 'current_year' },
  { name: 'Anno Precedente', value: 'past_year' },
];

const dataTypes = [
  'CHAR',
  'VARCHAR',
  'BINARY',
  'VARBINARY',
  'TINYBLOB',
  'TINYTEXT',
  'TEXT',
  'BLOB',
  'MEDIUMTEXT',
  'MEDIUMBLOB',
  'LONGTEXT',
  'LONGBLOB',
  'ENUM',
  'SET',
  'BIT',
  'TINYINT',
  'BOOL',
  'BOOLEAN',
  'SMALLINT',
  'MEDIUMINT',
  'INT',
  'INTEGER',
  'BIGINT',
  'FLOAT',
  'DOUBLE',
  'DECIMAL',
  'DEC',
  'DATE',
  'DATETIME',
  'TIMESTAMP',
  'TIME',
  'YEAR',
];

const requireSizeTypes = [
  'CHAR',
  'VARCHAR',
  'BINARY',
  'VARBINARY',
  'TEXT',
  'BLOB',
  'BIT',
  'TINYINT',
  'SMALLINT',
  'MEDIUMINT',
  'INTEGER',
  'BIGINT',
  'FLOAT',
  'DOUBLE',
  'DECIMAL',
];

export default {
  paramTypes,
  defaultIntervals,
  defaultDates,
  dataTypes,
  requireSizeTypes,
};
