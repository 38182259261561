const list = async (page = 0, size = 10, titleFilter = null) => {
  const response = await window.axios.get(
    '/graphic/widget',
    {
      params: {
        page,
        size,
        titleFilter,
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const create = async (widget) => {
  const response = await window.axios.post('/graphic/widget', widget);
  if (response.status === 200) {
    if (response.status === 200) {
      return response.data;
    }
  }
  return 'error';
};

const clone = async (dashboardId) => {
  const response = await window.axios.post('/graphic/widget/clone', dashboardId);
  if (response.status === 201) {
    return response.data;
  }
  return 'error';
};

const update = async (widget) => {
  const response = await window.axios.put(`/graphic/widget/${widget.id}`, widget);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const listDatasets = async (widgetId) => {
  const response = await window.axios.get(`/graphic/widget/${widgetId}/dataset`);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const addDataset = async (widgetId, dataset) => {
  const response = await window.axios.post(`/graphic/widget/${widgetId}/dataset`, dataset);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

export default {
  list,
  create,
  clone,
  update,
  datasets: {
    list: listDatasets,
    add: addDataset,
  },
};
