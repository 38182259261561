<template>
  <v-card  class="my-1">
    <v-card-title v-if="!open">
      <v-spacer/>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon color="blue"
          @click="initCreator()"
          >
              <v-icon large>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add Dashboard</span>
      </v-tooltip>
      <v-spacer/>
    </v-card-title>
    <div v-else>
      <v-tabs  v-model="tab" >
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab key="create">
          Nuovo Dashboard
        </v-tab>
        <v-tab key="add">
          Dashboard Esistente
        </v-tab>
      </v-tabs>
      <v-tabs-items  v-model="tab"  class="pa-2">
        <v-tab-item key="create" :loading="processing">
            <v-row>
              <v-col :cols=1></v-col>
              <v-col :cols=10>
                <v-form v-model="valid">
                  <v-container>
                        <v-text-field
                          v-model="model.displayName"
                          :rules="nameRules"
                          label="Nome"
                          hint="Il nome che verrà mostrato agli utenti"
                          required
                        />
                        <v-select
                          v-model="model.schemaId"
                          :items="schemas"
                          item-text="displayName"
                          item-value="id"
                          required
                          label="Schema DB"
                          hint="Lo schema DB usato da questa dashboard"
                          :loading="loadingSchemas"
                          :rules="schemaRules"
                        />
                  </v-container>
                </v-form>
              </v-col>
              <v-col :cols=1></v-col>
            </v-row>
            <v-card-actions>
              <v-btn color="error" @click="cancel" :disabled="processing">Cancel</v-btn>
              <v-spacer />
              <v-btn color="info" :disabled="!valid || processing" @click="save">Save</v-btn>
            </v-card-actions>
        </v-tab-item >
        <v-tab-item key="add">
          <v-container class="my-4">
            <v-row>
              <v-col :cols=1></v-col>
              <v-col :cols=10>
                <v-autocomplete
                  v-model="selectedDashboard"
                  :items="dashboards"
                  dense
                  :loading="loadingDashboards"
                  item-text="displayName"
                  item-value="API"
                  label="Dashboard"
                  placeholder="Cerca dashboards"
                  prepend-icon="mdi-database-search"
                  return-object
                ></v-autocomplete>
              </v-col>
              <v-col :cols=1></v-col>
            </v-row>
          <v-row>
          <v-spacer/>
          <v-btn
                color="primary"
                @click="addSelectedDashboard()"
          >
            Seleziona Dashboard
          </v-btn>
          <v-spacer/>
          </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </div>
    </v-card>
</template>

<script>
import api from '@/api';

export default {
  name: 'UserDashboardCreator',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.loadDatabaseSchemas();
    this.loadExistingDashboards();
  },
  methods: {
    async loadExistingDashboards() {
      if (this.loading) {
        return;
      }
      this.loadingDashboards = true;
      this.dashboards = await api.admin.dashboard.list();
      this.loadingDashboards = false;
    },
    async loadDatabaseSchemas() {
      this.loadingSchemas = true;
      this.schemas = await api.admin.databaseSchema.list();
      this.loadingSchemas = false;
    },
    initCreator() {
      this.open = true;
      this.model = {
        displayName: '',
        schemaId: null,
      };
    },
    async save() {
      this.processing = true;
      const newDashboard = await api.admin.dashboard.create(this.model);
      await api.admin.dashboard.users.add(newDashboard.id, this.user.id);
      this.open = false;
      this.model = false;
      this.processing = false;
      await this.loadDatabaseSchemas();
      await this.loadExistingDashboards();
      this.$emit('newDashboard', newDashboard);
    },
    async addSelectedDashboard() {
      if (!this.selectedDashboard) {
        return;
      }
      this.dialog = false;
      await api.admin.dashboard.users.add(this.selectedDashboard.id, this.user.id);
      this.open = false;
      this.$emit('newDashboard', this.selectedDashboard);
      this.selectedDashboard = null;
    },
    cancel() {
      this.open = false;
      this.model = null;
    },
    async selectDashboard() {
      await this.loadDatabaseSchemas();
      await this.loadExistingDashboards();
    },
  },
  data() {
    return {
      tab: 'existing',
      selectedDashboard: null,
      valid: null,
      schemas: null,
      dashboards: null,
      loadingSchemas: false,
      loadingDashboards: false,
      processing: false,
      model: null,
      open: false,
      nameRules: [
        (v) => !!v || 'Inserire Nome',
      ],
      schemaRules: [
        (v) => !!v || 'Selezionare uno schema',
      ],
    };
  },
};
</script>

<style lang="scss">
.dashboard-card {
  cursor: pointer;
}
.dashboard-creator-body {
  min-height: 100px;
}
</style>
