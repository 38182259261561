const test = async (dataset, params) => {
  const response = await window.axios.post('/admin/dataset/test', dataset, { params });
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const fetchData = async (datasetId, params = {}) => {
  const response = await window.axios.get(`/admin/dataset/${datasetId}/data`, { params });
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const save = async (dataset) => {
  let response;
  if (!dataset.id) {
    response = await window.axios.post('/admin/dataset', dataset);
  } else {
    response = await window.axios.put(`/admin/dataset/${dataset.id}`, dataset);
  }
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const metadata = async (datasetId, params) => {
  const response = await window.axios.get(`/admin/dataset/${datasetId}/metadata`, { params });
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const insertTableRow = async (tableName, item, schemaId) => {
  const response = await window.axios.post(`/admin/dataset/insert/${tableName}/${schemaId}`, item);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const updateTableRow = async (tableName, id, item, schemaId) => {
  const response = await window.axios.post(`/admin/dataset/update/${tableName}/${id}/${schemaId}`, item);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const updateTableBulk = async (tableName, item, schemaId) => {
  const response = await window.axios.post(`/admin/dataset/update-bulk/${tableName}/${schemaId}`, item);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const deleteTableRow = async (tableName, id, schemaId) => {
  const response = await window.axios.post(`/admin/dataset/delete/${tableName}/${id}/${schemaId}`);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const createTable = async (tableName, schemaId, fields) => {
  const response = await window.axios.post(`/admin/dataset/createTable/${tableName}/${schemaId}`, fields);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

export default {
  test,
  save,
  metadata,
  fetchData,
  insertTableRow,
  updateTableRow,
  updateTableBulk,
  deleteTableRow,
  createTable,
};
