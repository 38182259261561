<template>
  <v-app>
    <NavigationDrawer/>
    <AppBar/>
    <v-main class="backgroundColor">
      <v-container fluid class="pa-0">
        <router-view :key="$route.name"></router-view>
      </v-container>
    </v-main>
    <!-- <Footer /> -->
  </v-app>
</template>

<script>
import NavigationDrawer from '@/user/components/main/NavigationDrawer.vue';
import AppBar from '@/user/components/main/AppBar.vue';
// import Footer from '@/user/components/main/Footer.vue';
import colors from '@/tools/colors';

export default {
  name: 'UserApp',
  components: { AppBar, NavigationDrawer },
  created() {
  },
  mounted() {
    const { theme } = (this.$store.state || {});
    if (theme.primaryColor) {
      this.$vuetify.theme.themes.light.primary.base = theme.primaryColor;
    }
    if (theme.secondaryColor) {
      this.$vuetify.theme.themes.light.secondary.base = theme.secondaryColor;
    }
    if (theme.primaryColor) {
      this.$vuetify.theme.themes.light.secondary.darken1 = theme.navbarColor;
    }
    if (theme.navbarActiveColor) {
      this.$vuetify.theme.themes.light.activeNav.base = theme.navbarActiveColor;
    }
    if (theme.iconColor) {
      this.$vuetify.theme.themes.light.iconColor.base = theme.iconColor;
    }
    if (theme.backgroundColor) {
      this.$vuetify.theme.themes.light.backgroundColor.base = theme.backgroundColor;
    }
    if (theme.cardBackgroundColor) {
      this.$vuetify.theme.themes.light.cardBackgroundColor.base = theme.cardBackgroundColor;
    }
    if (theme.footerColor) {
      this.$vuetify.theme.themes.light.footerColor.base = theme.footerColor;
    }
    if (theme.chartColors) {
      colors.setColors(JSON.parse(theme.chartColors));
    }
    // = theme.logo;
    // = theme.footerText;
    // = theme.textSize;
    if (this.$store.state.user.userName === 'roma') {
      this.$vuetify.theme.themes.light.primary.base = '#de900b';
      this.$vuetify.theme.themes.light.secondary.base = '#980328';
      colors.setRomaColors('roma');
    } else if (this.$store.state.user.userName === 'milan') {
      this.$vuetify.theme.themes.light.primary.base = '#de900b';
      this.$vuetify.theme.themes.light.secondary.base = '#980328';
      colors.setColors('milan');
    }
  },
  methods: {
  },
  data() {
    return {
      views: null,
    };
  },
};
</script>

<style lang="scss">
.v-application {
    font-family: $body-font-family, sans-serif !important;
    .title { // To pin point specific classes of some components
            font-family: $title-font, sans-serif !important;
    }
}
</style>
