var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.updater)?_c('v-row',{staticClass:"pa-0 ma-0"},_vm._l((_vm.params.filter(function (f) { return f.isGlobal; })),function(param){return _c('v-col',{key:param.name,staticClass:"d-flex align-center"},[(param.type === 'query_expression')?_c('v-select',{attrs:{"dense":"","hide-details":"","label":param.displayAs,"items":param.allowedValues,"item-text":"displayAs","item-value":"expression"},on:{"change":_vm.applyParams},model:{value:(param.selection),callback:function ($$v) {_vm.$set(param, "selection", $$v)},expression:"param.selection"}}):(param.type === 'text')?_c('v-text-field',{attrs:{"hide-details":"","dense":"","label":param.displayAs},on:{"change":_vm.applyParams},model:{value:(param.selection),callback:function ($$v) {_vm.$set(param, "selection", $$v)},expression:"param.selection"}}):(param.type === 'date')?_c('v-menu',{staticClass:"py-0",attrs:{"close-on-content-click":false,"nudge-right":32,"transition":"scale-transition","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":param.displayAs,"prepend-icon":"mdi-calendar","readonly":"","dense":"","hide-details":""},model:{value:(param.selection),callback:function ($$v) {_vm.$set(param, "selection", $$v)},expression:"param.selection"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(param.open),callback:function ($$v) {_vm.$set(param, "open", $$v)},expression:"param.open"}},[_c('v-date-picker',{attrs:{"no-title":"","show-current":"false"},on:{"input":function () {_vm.applyParam(param);},"blur":function($event){return _vm.updateViewItems()}},model:{value:(param.selection),callback:function ($$v) {_vm.$set(param, "selection", $$v)},expression:"param.selection"}})],1):(param.type === 'date-range')?_c('v-menu',{staticClass:"py-0",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.multiDate[param.name] || param.selection,"label":param.displayAs,"prepend-icon":"mdi-calendar","readonly":"","dense":"","hide-details":""}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(param.open),callback:function ($$v) {_vm.$set(param, "open", $$v)},expression:"param.open"}},[_c('v-list',{attrs:{"flat":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((['Anno', 'Mese', 'Intervallo']),function(item){return _c('v-menu',{key:item,attrs:{"close-on-content-click":item !== 'Intervallo' ? true : false,"nudge-right":4,"offset-x":"","min-width":"auto","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$vuetify.lang.t(item))}})],1)],1)]}}],null,true)},[(item == 'Anno')?_c('v-card',{staticStyle:{"max-height":"242px"}},[_c('v-list',_vm._l((_vm.yearList),function(item){return _c('v-list-item',{key:item+param.name,staticClass:"px-16",class:_vm.years[param.name] === item ? 'grey': 'white',attrs:{"dense":""},on:{"click":function($event){return _vm.yearToAnd(item, param)}}},[_c('v-list-item-title',{staticClass:"text-center"},[_c('h3',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item))])])],1)}),1)],1):_vm._e(),(item == 'Mese')?_c('v-date-picker',{staticClass:"justify-center",attrs:{"no-title":"","type":"month"},on:{"click:month":function($event){return _vm.monthToAnd(param.name, param)}},model:{value:(_vm.months[param.name]),callback:function ($$v) {_vm.$set(_vm.months, param.name, $$v)},expression:"months[param.name]"}}):_vm._e(),(item == 'Intervallo')?_c('v-date-picker',{attrs:{"no-title":"","range":""},on:{"change":function($event){return _vm.daysToAnd(param.name, param)}},model:{value:(_vm.mp[param.name]),callback:function ($$v) {_vm.$set(_vm.mp, param.name, $$v)},expression:"mp[param.name]"}}):_vm._e()],1)}),1)],1)],1):_vm._e()],1)}),1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }