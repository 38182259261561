<template>
  <v-navigation-drawer
    app
    permanent
    fixed
    color="secondary darken-1"
    clipped
    class="left-navigation-drawer pt-0"
    expand-on-hover
    mini-variant
  >
    <v-list class="pt-0">
      <v-list-item-group
        :value="$router.currentRoute"
        active-class="primary--text text--accent-4"
        class="white--text"
      >
        <v-list-item
          v-for="route in routes"
          :key="route.path"
          :to="route"
        >
          <v-list-item-icon>
            <v-icon>
              {{ route.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ route.name }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'NavigationDrawer',
  data() {
    return {
      routes: this.$router.options.routes.filter((r) => !r.hideInMenu),
    };
  },
};
</script>
