import moment from 'moment';

const numericTypes = /^(DOUBLE( PRECISION)?|FLOAT|REAL|DECIMAL|DEC|INT|BIGINT|SMALLINT|BIT)( UNSIGNED)?$/i;
const textTypes = /^(VARCHAR|TEXT|BLOB)$/i;
const timeTypes = /^(DATE|DATETIME|TIMESTAMP)$/i;

const isNumeric = (o) => {
  if (typeof o === 'string') {
    return numericTypes.test(o);
  }
  if (o.type && typeof o.type === 'string') {
    return numericTypes.test(o.type);
  }
  return false;
};

const isText = (o) => {
  if (typeof o === 'string') {
    return textTypes.test(o);
  }
  if (o.type && typeof o.type === 'string') {
    return textTypes.test(o.type);
  }
  return false;
};

const isTime = (o) => {
  if (typeof o === 'string') {
    return timeTypes.test(o);
  }
  if (o.type && typeof o.type === 'string') {
    return timeTypes.test(o.type);
  }
  return false;
};

const convertDefaultDate = (val) => {
  let result;
  switch (val) {
    case 'current_date': result = moment(); break;
    case 'yesterday': result = moment().subtract(1, 'day'); break;
    case 'current_month_start': result = moment().startOf('month'); break;
    case 'current_month_end': result = moment().endOf('month'); break;
    case 'past_month_start': result = moment()
      .startOf('month')
      .subtract(1, 'month');
      break;
    case 'past_month_end': result = moment()
      .startOf('month')
      .subtract(1, 'day');
      break;
    case 'current_quarter_start': return moment();
    case 'current_quarter_end': return moment();
    case 'past_quarter_start': return moment();
    case 'past_quarter_end': return moment();
    case 'current_year_start': result = moment().startOf('year'); break;
    case 'current_year_end': result = moment().endOf('year'); break;
    case 'past_year_start': result = moment()
      .subtract(1, 'year')
      .startOf('year');
      break;
    case 'past_year_end': result = moment()
      .subtract(1, 'year')
      .endOf('year');
      break;
    default: result = moment();
  }
  return result.format('YYYY-MM-DD');
};

const convertDefaultInterval = (val) => {
  let result;
  switch (val) {
    case 'current_date':
      result = [
        moment(),
        moment(),
      ];
      break;
    case 'yesterday':
      result = [
        moment().subtract(1, 'day'),
        moment(),
      ];
      break;
    case 'current_month':
      result = [
        moment().startOf('month'),
        moment().endOf('month'),
      ];
      break;
    case 'past_month':
      result = [
        moment().startOf('month').subtract(1, 'month'),
        moment().startOf('month').subtract(1, 'day'),
      ];
      break;
    case 'current_quarter': return [moment(), moment()];
    case 'past_quarter': return [moment(), moment()];
    case 'current_year':
      result = [
        moment().startOf('year'),
        moment().endOf('year'),
      ];
      break;
    case 'past_year':
      result = [
        moment().subtract(1, 'year').startOf('year'),
        moment().subtract(1, 'year').endOf('year'),
      ];
      break;
    default: result = [
      moment(),
      moment(),
    ];
  }
  return result.map((d) => d.format('YYYY-MM-DD'));
};

const buildInitialParamState = (params) => {
  if (params.length === 0) {
    return {};
  }
  return Object.fromEntries(
    params.map((param) => {
      if (param.type === 'query_expression') {
        return [param.name, param.allowedValues[0]?.expression];
      }
      if (param.type === 'text' && param.defaultValue) {
        return [param.name, param.defaultValue];
      }
      if (param.type === 'date' && param.defaultValue) {
        let value = param.defaultValue;
        if (param.useDefaultValues) {
          value = convertDefaultDate(value);
        }
        return [param.name, value];
      }
      if (param.type === 'date-range' && param.defaultValue) {
        let x = param.defaultValue;
        if (param.useDefaultValues) {
          x = convertDefaultInterval(x);
        }
        if (!x[0]) return "DATE('2000-01-01') AND DATE('2000-01-01')";
        let string = "DATE('";
        string += x[0];
        string += "') AND DATE('";
        if (x[1]) string += x[1];
        else string += x[0];
        string += "')";
        return [param.name, string];
      }
      return [param.name, true];
    }),
  );
};

export default {
  isNumeric,
  isText,
  isTime,
  convertDefaultDate,
  convertDefaultInterval,
  buildInitialParamState,
};
