<template>
  <v-expansion-panels flat v-if="value && value.length" class="pa-0 param-config">
    <v-expansion-panel class="pa-0" v-model="paramExpansionOpen">
      <v-expansion-panel-header class="pa-0">Parametri</v-expansion-panel-header>
      <v-expansion-panel-content style="width: 100%" class="pa-0">
        <div v-for="(param, idx) in value" :key="idx">
          <b>{{param.name}}</b>
          <v-row>
            <v-col md="3" class="px-3">
              <v-select
                :items="paramTypes"
                item-text="name"
                item-value="value"
                label="Tipo"
                v-model="param.type"
                hide-details
                @change="handleTypeChange(param, idx)"/>
            </v-col>
            <v-col md="3" class="px-3">
              <v-text-field @change="change"
                            label="Etichetta"
                            hide-details
                            v-model="param.displayAs"/>
            </v-col>
            <v-col md="2" class="px-3" v-if="param.type !== 'query_expression'">
              <v-checkbox  @change="handleOptionalChange"
                           label="Opzionale"
                           v-model="param.optional"/>
            </v-col>
          </v-row>
          <v-row v-if="param.type === 'date' && param.optional">
            <v-col cols="3" class="pa-0">
              <v-checkbox v-model="param.useDefaultValues"
                          @change="toggleDefaultDateValues(param)"
                          label="Usa Valori Default"/>
            </v-col>
            <v-col cols="3" class="pa-0">
              <v-select v-if="param.useDefaultValues"
                        v-model="param.defaultValue"
                        :items="defaultDates"
                        item-text="name"
                        item-value="value"
                        label="Valore"/>
              <v-menu
                v-else
                v-model="param.open"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="param.defaultValue"
                    label="Default Value"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"/>
                </template>
                <v-date-picker
                  v-model="param.defaultValue"
                  @input="param.open = false"/>
              </v-menu>
            </v-col>
          </v-row>
          <v-row v-if="param.type === 'date-range' && param.optional">
            <v-col cols="3" class="pa-0">
              <v-checkbox v-model="param.useDefaultValues"
                          @change="toggleDefaultDateValues(param)"
                          label="Usa Valori Default"/>
            </v-col>
            <v-col cols="5" class="pa-0">
          <!-- XXX add new ranges? -->
              <v-select v-if="param.useDefaultValues"
                v-model="param.defaultValue"
                :items="defaultIntervals"
                item-text="name"
                item-value="value"
                label="Valore"/>
              <v-menu
                v-else
                v-model="param.open"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="param.defaultValue"
                    label="Default Value"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"/>
                </template>
                <v-date-picker
                  v-model="param.defaultValue"
                  range
                  @input="param.defaultValue.length < 2 ? param.open = true : param.open = false"/>
              </v-menu>
          <!-- XXX end of add new ranges? -->
            </v-col>
          </v-row>
          <v-expansion-panels flat class="pa-0" v-if="param.type === 'query_expression'">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-0 pt-0">
                <b>Valori</b>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0">
                <v-row no-gutters
                       class="mt-2"
                       v-for="(value, idx) in param.allowedValues"
                       :key="`${idx}-label`">
                  <v-col md="5" class="px-3 ">
                    <v-text-field
                      @change="change"
                      type="text"
                      label="Etichetta"
                      dense
                      v-model="value.displayAs"/>
                  </v-col>
                  <v-col md="5" class="px-3 ">
                    <v-text-field
                      @change="change"
                      type="text"
                      label="Espressione"
                      dense
                      v-model="value.expression"/>
                  </v-col>
                  <v-col md="2" class="px-3 text-right">
                    <v-btn small icon color="error" @click="removeAllowedValue(param, idx)">
                      <v-icon class="mdi mdi-close"/>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-spacer />
                  <v-btn small icon color="primary" @click="addAllowedValue(param)">
                    <v-icon class="mdi mdi-plus" />
                  </v-btn>
                  <v-spacer />
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import defaultMappigns from '@/tools/defaultMappings';

const { paramTypes, defaultDates, defaultIntervals } = defaultMappigns;

const queryExprValue = { displayAs: '', expression: '' };

export default {
  name: 'DatasetParamsConfig',
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  methods: {
    change() {
      this.$emit('input', this.value);
    },
    handleTypeChange(param, idx) {
      if (param.type === 'text') {
        this.value[idx].allowedValues = null;
        this.value[idx].defaultValue = '';
        this.value[idx].optional = false;
      } else if (param.type === 'query_expression') {
        this.value[idx].allowedValues = [
          { ...queryExprValue },
        ];
        this.value[idx].defaultValue = 0;
        this.value[idx].optional = false;
        this.valuesExpansionOpen[idx] = true;
      } else {
        this.value[idx].allowedValues = null;
        this.value[idx].defaultValue = '';
        this.value[idx].optional = false;
      }
      this.change();
    },
    handleOptionalChange(param, idx) {
      if (param.type === 'text' && param.optional) {
        this.value[idx].defaultValue = '';
      }
      this.change();
    },
    toggleDefaultDateValues(param) {
      if (!param.useDefaultValues) {
        // eslint-disable-next-line no-param-reassign
        param.defaultValue = '';
      } else {
        // eslint-disable-next-line no-param-reassign
        param.open = false;
      }
    },
    addAllowedValue(param) {
      param.allowedValues.push({ ...queryExprValue });
      this.$forceUpdate();
      this.change();
    },
    removeAllowedValue(param, idx) {
      param.allowedValues.splice(idx, 1);
      this.$forceUpdate();
      this.change();
    },
  },
  data() {
    return {
      paramTypes,
      defaultDates,
      defaultIntervals,
      paramExpansionOpen: true,
      valuesExpansionOpen: this.value.map(() => true),
    };
  },
};
</script>

<style scoped>
</style>
