var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"md":"4"}},[_c('v-card',{staticClass:"dashboard-card"},[_c('v-card-title',[_c('v-icon',{key:_vm.dashboard.id,class:_vm.dashboardIcon,attrs:{"color":"primary"}}),_c('span',{staticClass:"mx-2 px-2"},[_vm._v(_vm._s(_vm.dashboard.displayName))]),_c('v-spacer'),_c('ConfirmDelete',{attrs:{"text":"Confermi l'eliminazione di questa dashboard?"},on:{"confirm":_vm.handleDeletionConfirm}}),_c('v-dialog',{attrs:{"max-width":"400px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',{staticClass:"pt-3 text-h6 text-md-center"},[_vm._v(" "+_vm._s(_vm.error[0])+" "),_c('br'),_vm._v(" "+_vm._s(_vm.error[1])+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"info","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Ok ")]),_c('v-spacer')],1)],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.handleClone}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mdi mdi-content-copy",attrs:{"color":"secondary"}})],1)]}}])},[_c('span',[_vm._v("Clona Dashboard")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"},on:{"click":function($event){return _vm.openEditor(_vm.dashboard)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mdi mdi-pencil"})],1)]}}])},[_c('span',[_vm._v("Modifica")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"lightblue"},on:{"mouseup":function($event){$event.stopPropagation();return _vm.toggleOpen.apply(null, arguments)}}},'v-btn',attrs,false),on),[(_vm.open)?_c('v-icon',{staticClass:"mdi mdi-chevron-up"}):_c('v-icon',{staticClass:"mdi mdi-chevron-down"})],1)]}}])},[(_vm.open)?_c('span',[_vm._v("Riduci")]):_c('span',[_vm._v("Espandi")])])],1),(_vm.open)?_c('v-card-text',{staticClass:"px-2"},[_c('DashboardDetails',{attrs:{"dashboard":_vm.dashboard,"actualIcon":_vm.dashboardIcon.split(/[ ]+/)[1]},on:{"icon":_vm.changeIcon,"iconSave":_vm.saveIcon}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }