<template>
  <v-row class="pa-0 ma-0" v-if="updater">
    <v-col
      v-for="param in params.filter((f) => f.isGlobal)"
      :key="param.name"
      class="d-flex align-center"
    >
      <!-- Selector Expression -->
      <v-select
        v-if="param.type === 'query_expression'"
        dense
        hide-details
        :label="param.displayAs"
        :items="param.allowedValues"
        item-text="displayAs"
        v-model="param.selection"
        item-value="expression"
        @change="applyParams"/>
      <!-- Text Expression -->
      <v-text-field
        v-else-if="param.type === 'text'"
        hide-details
        dense
        v-model="param.selection"
        :label="param.displayAs"
        @change="applyParams"/>
      <!-- Single Data Expression -->
      <v-menu
        v-else-if="param.type === 'date'"
        v-model="param.open"
        :close-on-content-click="false"
        :nudge-right="32"
        transition="scale-transition"
        class="py-0"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="param.selection"
            :label="param.displayAs"
            prepend-icon="mdi-calendar"
            readonly
            dense
            hide-details
            v-bind="attrs"
            v-on="on"/>
        </template>
        <v-date-picker
          v-model="param.selection"
          no-title
          show-current="false"
          @input="() => {applyParam(param);}"
          @blur="updateViewItems()"
        />
      </v-menu>
      <!-- Data Range Expression -->
      <v-menu
        v-else-if="param.type === 'date-range'"
        v-model="param.open"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        class="py-0"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="multiDate[param.name] || param.selection"
            :label="param.displayAs"
            prepend-icon="mdi-calendar"
            readonly
            dense
            hide-details
            v-bind="attrs"
            v-on="on"/>
        </template>
        <v-list flat>
          <v-list-item-group
            color="primary"
          >
            <v-menu
              v-for="item in ['Anno', 'Mese', 'Intervallo']"
              :key="item"
              :close-on-content-click="item !== 'Intervallo' ? true : false"
              :nudge-right="4"
              offset-x
              min-width="auto"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="$vuetify.lang.t(item)"/>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <!-- Year -->
              <v-card v-if="item == 'Anno'" style="max-height: 242px">
                <v-list>
                  <v-list-item
                    v-for="item in yearList"
                    :key="item+param.name"
                    dense
                    @click="yearToAnd(item, param)"
                    class="px-16"
                    :class="years[param.name] === item ? 'grey': 'white'"
                  >
                    <v-list-item-title class="text-center">
                      <h3 class="font-weight-medium">{{ item }}</h3>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
              <!-- Month -->
              <v-date-picker
                v-if="item == 'Mese'"
                v-model="months[param.name]"
                no-title
                type="month"
                @click:month="monthToAnd(param.name, param)"
                class="justify-center"
              />
              <!-- Daily -->
              <v-date-picker
                v-if="item == 'Intervallo'"
                no-title
                v-model="mp[param.name]"
                range
                @change="daysToAnd(param.name, param)"
              />
            </v-menu>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment';

export default {
  name: 'GlobalParams',
  props: {
    visibleParams: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.$root.$on('pushGlobalParams', this.loadGlobalParams);
    // this.$root.$on('cleanGlobalParams', this.cleanGlobalParams);
    const y = [];
    for (let i = 2000; i < 2040; i += 1) {
      y.push(String(i));
    }
    this.yearList = y;
  },
  methods: {
    isGlobal(filter) {
      return this.visibleParams.includes(filter);
    },
    isDate() {
      return false;
    },
    cleanGlobalParams() {
      // eslint-disable-next-line no-param-reassign
      this.params.forEach((f) => { f.selection = []; });
    },
    loadGlobalParams(globalParams) {
      if (Object.entries(globalParams).length > 0) {
        Object.entries(globalParams).forEach((value) => {
          const filterFound = this.params.find((param) => param.name === value[0]);
          if (!filterFound) {
            if (value[1].type === 'date-range') this.params.push({ ...value[1] });
            else this.params.push(value[1]);
          // eslint-disable-next-line prefer-destructuring
          } else if (!value[1].type) filterFound.selection = value[1];
        });
      }

      if (this.firstLoad) {
        this.params.forEach((param) => {
          if (param.type === 'date-range') this.multiDate[param.name] = param.selection;
        });
        this.updater = false;
        this.$nextTick(() => {
          this.updater = true;
        });
        this.firstLoad = false;
      }

      this.$store.commit('setGlobalParams', this.params);
    },
    selectAllVisible(filter) {
      filter.items.forEach((item) => {
        if (item.toUpperCase().includes(filter.search.toUpperCase())
          && !filter.selection.includes(item)) {
          filter.selection.push(item);
        }
      });
    },
    arrayToAnd(x) {
      if (!x[0]) return 'err';
      let string = "DATE('";
      string += x[0];
      string += "') AND DATE('";
      if (x[1]) string += x[1];
      else string += x[0];
      string += "')";
      return string;
    },
    yearToAnd(year, param) {
      // eslint-disable-next-line no-param-reassign
      param.open = false;
      this.years[param.name] = year;

      const firstDay = moment(this.years[param.name]).startOf('year').format('YYYY-MM-DD');
      const lastDay = moment(this.years[param.name]).endOf('year').format('YYYY-MM-DD');
      this.multiDate[param.name] = [firstDay, lastDay];
      this.mp[param.name] = [firstDay, lastDay];
      param.selection = this.arrayToAnd(this.multiDate[param.name]);
      this.applyParam(param);
    },
    monthToAnd(name, param) {
      const firstDay = moment(this.months[name]).startOf('month').format('YYYY-MM-DD');
      const lastDay = moment(this.months[name]).endOf('month').format('YYYY-MM-DD');
      this.multiDate[name] = [firstDay, lastDay];
      this.mp[name] = [firstDay, lastDay];
      param.selection = this.arrayToAnd(this.multiDate[name]);
      this.applyParam(param);
    },
    daysToAnd(name, param) {
      if (this.mp[name].length === 2) {
        this.multiDate[name] = this.mp[name];
        this.months[name] = null;
      }
      param.selection = this.arrayToAnd(this.multiDate[name]);
      this.applyParam(param);
    },
    applyParam(param) {
      this.$root.$emit('updateParamFromGlobal', {
        name: param.name,
        selection: param.selection,
      });
    },
    applyParams() {
      this.$root.$emit('updateParamsFromGlobal', this.params.map((param) => ({
        name: param.name,
        selection: param.selection,
      })));
    },
    updateViewItems() {
      this.updater = false;
      this.$nextTick(() => {
        this.updater = true;
      });
      this.firstLoad = false;
    },
  },
  data() {
    return {
      params: [],
      selected: [],
      rangeDate: [],
      yearList: [],
      years: {},
      months: {},
      multiDate: {},
      updater: true,
      mp: {},
      firstLoad: true,
    };
  },
};
</script>
