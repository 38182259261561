<template>
  <v-data-table
    :height="height*0.9"
    :headers="tableHeaders"
    :items="tData"
    :items-per-page="nRows"
    item-key="userName"
  />
</template>

<script>
/*
const tableHeaders = [
  { text: 'Username', align: 'start', value: 'userName' },
  { text: 'First Name', value: 'firstName' },
  { text: 'Last Name', value: 'lastName' },
  { text: 'e-mail', value: 'email' },
  { text: '', value: 'data-table-expand' },
]; */

export default {
  name: 'WidgetDataTable',
  mounted() {
    if (this.data2 !== null) this.displayMultiData();
    else this.displayData();
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    data2: {
      type: Array,
      required: false,
      default: () => null,
    },
    headers: {
      type: Array,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
  },
  computed: {
    nRows() {
      const totalH = this.height * 0.9;
      const rows = Math.floor((totalH - 48) / 48);
      return rows;
    },
  },
  methods: {
    displayData() {
      this.tableHeaders = [];
      this.headers.forEach((item) => {
        this.tableHeaders.push({ text: item, value: item });
      });
      this.tData = JSON.parse(JSON.stringify(this.data));
      // Sum same labeled Data
      for (let i = 0; i < this.tData.length; i += 1) {
        const value = this.tData[i];
        for (let j = i + 1; j < this.tData.length; j += 1) {
          if (this.tData[i][this.headers[0]] === this.tData[j][this.headers[0]]) {
            // eslint-disable-next-line no-loop-func
            Object.keys(value).forEach((key) => {
              if (typeof (this.tData[i][key]) === 'number') {
                this.tData[i][key] += this.tData[j][key];
                this.tData[i][key] = this.tData[i][key].toFixed(2);
              }
            });
            this.tData.splice(j, 1);
            j -= 1;
          }
        }
      }
      this.$emit('data', this.tData);
    },
    displayMultiData() {
      this.tData = JSON.parse(JSON.stringify(this.data));
      this.tData2 = JSON.parse(JSON.stringify(this.data2));
      this.tableHeaders = [];
      let first = true;
      this.headers.forEach((item) => {
        if (first) {
          this.tableHeaders.push({ text: `${item}-Set 1`, value: item });
          first = false;
        } else {
          this.tableHeaders.push({ text: `${item}-Set 1`, value: item, divider: true });
        }
      });
      first = true;
      this.headers.forEach((item) => {
        if (first) {
          first = false;
        } else {
          this.tableHeaders.push({ text: `${item}-Set 2`, value: `${item}2` });
        }
      });
      this.tableHeaders.push({ text: `${this.headers[0]}-Set 2`, value: `${this.headers[0]}2` });
      // Sum data on same column label
      for (let i = 0; i < this.tData.length; i += 1) {
        const value = this.tData[i];
        for (let j = i + 1; j < this.tData.length; j += 1) {
          // if same labelColumn keyname sum
          if (this.tData[i][this.headers[0]] === this.tData[j][this.headers[0]]) {
            // eslint-disable-next-line no-loop-func
            Object.keys(value).forEach((key) => {
              if (typeof (this.tData[i][key]) === 'number') {
                this.tData[i][key] += this.tData[j][key];
                this.tData[i][key] = this.tData[i][key].toFixed(2);
              }
            });
            this.tData.splice(j, 1);
            j -= 1;
          }
        }
      }
      for (let i = 0; i < this.tData2.length; i += 1) {
        const value = this.tData2[i];
        for (let j = i + 1; j < this.tData2.length; j += 1) {
          // if same labelColumn keyname sum
          if (this.tData2[i][this.headers[0]] === this.tData2[j][this.headers[0]]) {
            // eslint-disable-next-line no-loop-func
            Object.keys(value).forEach((key) => {
              if (typeof (this.tData2[i][key]) === 'number') {
                this.tData2[i][key] += this.tData2[j][key];
                this.tData2[i][key] = this.tData2[i][key].toFixed(2);
              }
            });
            this.tData2.splice(j, 1);
          }
        }
      }
      // Merge multi data
      for (let i = 0; i < this.tData2.length && i < this.tData.length; i += 1) {
        const value = this.tData[i];
        Object.keys(value).forEach((key) => {
          this.tData[i][`${key}2`] = this.tData2[i][key];
        });
      }
      this.$emit('data', this.tData);
    },
  },
  data() {
    return {
      tableHeaders: [],
      tableRows: [],
      tData: [],
      tData2: [],
    };
  },
};
</script>

<style scoped>

</style>
