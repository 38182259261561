<template>
  <v-container fluid class="fill-height justify-center">
    <v-col class="fill-height">
      <v-layout fill-height justify-center align-center class="mx-4">
        <div class="d-flex justify-center flex-column">
          <h2>Utente non registrato</h2>
          <v-btn @click="logout()">Log  Out</v-btn>
        </div>
      </v-layout>
    </v-col>
  </v-container>
</template>

<script>
export default {
  name: 'MainView',
  methods: {
    logout() {
      this.$keycloak.logout();
    },
  },
};
</script>

<style scoped>

</style>
