<template>
  <!-- Dashboard Edit Admin Page -->
  <v-container fluid class="pa-0 mb-12">
    <div v-if="expandedWidget != null">
      <WidgetExpanded
      class="fill-height"
      :widget="expandedWidget"
      :editable="true"
      :widgetFilters="expandedFilters"
      :actualFilters="actualFilters[expandedWidget.id]"
      @collapse="collapseWidget"
      @edit="editWidget(expandedWidget, 1)"
      />
      <WidgetConfig
        :open="inspectedOpen"
        :widget="inspectedWidget"
        :schema="dashboard.databaseSchema"
        @close="handleWidgetChangeCancel"
        @change="handleWidgetChange"
        @saveDef="saveDefault"
      />
    </div>
    <div v-else>
      <div v-if="!loadingWidgets" >
        <v-app-bar color="lightgray" dense>
          <v-btn icon @click="goBack" >
            <v-icon class="mdi mdi-chevron-left"/>
          </v-btn>
          <v-spacer />
          <v-btn rounded small @click="saveAll">
            Salva
          </v-btn>
          <div class="mr-3"/>
          <WidgetSelector class
            @selected="handleAddWidget"
            :schema="dashboard.databaseSchema"
            :position="nextPos"/>
          <div class="mr-3"/>
          <WidgetEditGlobalFilters
            :dashboard="dashboard"
            @close="reloadPage()"
          />
        </v-app-bar>
        <v-row class="px-6 pt-6 mb-n6" v-if="Object.keys(widgets).length > 0">
          <v-col cols="11">
            <v-row>
              <GlobalFilters :visibleFilters="globalFilters" v-show="globalFilters.length > 0"/>
              <GlobalParams :visibleParams="globalParams" v-show="globalParams.length > 0"/>
            </v-row>
          </v-col>
          <v-col cols="1">
            <ExportData
              :title="dashboard.displayName"
              :onlyPDF="true"
              :bigButton="true"
              @click="bsDisable=true"
              @close="bsDisable=false"
              @printPDF="printDashboard"
            />
          </v-col>
        </v-row>
        <v-row class="px-6 pt-0" id="dashboard" v-if="Object.keys(widgets).length > 0"
          :style="[ bsDisable ? {'boxShadow': 'unset !important'} : {'boxShadow': ''} ]"
        >
          <GridLayout
            class="col-12 pt-0"
            :layout.sync="layout"
            :col-num="12"
            :row-height="50"
            :is-draggable="true"
            :is-resizable="true"
            :is-mirrored="false"
            :vertical-compact="true"
            :margin="[15,15]"
            :use-css-transforms="true"
          >
            <GridItem v-for="(item, index) in layout"
                      :x="item.x"
                      :y="item.y"
                      :w="item.w"
                      :h="item.h"
                      :i="item.i"
                      :key="item.widgetId"
                      @moved="handleMove(item)"
                      @resized="handleResize(item)">
              <WidgetPreview
                :widget="item.widget"
                :editable="true"
                :actualFilters="actualFilters[item.widget.id]"
                :widgetFilters="filters[item.widget.id]||{}"
                :globalFilters="JSON.parse((globalFilters || '[]'))"
                :globalParams="JSON.parse((globalParams || '[]'))"
                @expand="expandWidget"
                @cloned="handleCloneWidget"
                @edit="editWidget(widgets[item.widgetId], index)"
                @delete="deleteWidget(item.widgetId, index)"
              />
              <!-- TODO parsedFilters parsedParams -->
            </GridItem>
          </GridLayout>
        </v-row>
        <v-row class="pa-5" v-else-if="!loadingWidgets">
          <v-col cols="12" class="text-center mt-3">
            <span class="title text--lighten-2">Nessun Widget in questa dasboard </span>
            <br/>
            <br/>
            <WidgetSelector @selected="handleAddWidget"
                            :schema="dashboard.databaseSchema"
                            :position="nextPos"/>
          </v-col>
        </v-row>
        <v-row class="pa-5" v-else>
          <v-col cols="12" class="text-center">
            <v-progress-circular indeterminate/>
          </v-col>
        </v-row>
        <WidgetConfig
          :open="inspectedOpen"
          :widget="inspectedWidget"
          :schema="dashboard.databaseSchema"
          @close="handleWidgetChangeCancel"
          @change="handleWidgetChange"
          @saveDef="saveDefault"
        />
      </div>
      <v-progress-circular v-else indeterminate color="primary"/>
    </div>
  </v-container>
</template>

<script>
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import VueGridLayout from 'vue-grid-layout';
import api from '@/api';
import layout from '@/tools/layout';

import ExportData from '@/graphic/components/main/DashboardEditor/export/ExportData.vue';
import WidgetExpanded from '@/graphic/components/main/DashboardEditor/widgets/expanded/WidgetExpanded.vue';
import WidgetConfig from '@/graphic/components/main/DashboardEditor/widgets/WidgetConfig.vue';
import WidgetPreview from '@/graphic/components/main/DashboardEditor/widgets/WidgetPreview.vue';
import WidgetSelector from '@/graphic/components/main/DashboardEditor/builder/WidgetSelector.vue';
import GlobalFilters from '@/graphic/components/main/DashboardEditor/widgets/GlobalFilters.vue';
import GlobalParams from '@/graphic/components/main/DashboardEditor/widgets/GlobalParams.vue';
import WidgetEditGlobalFilters from '@/graphic/components/main/DashboardEditor/builder/WidgetEditGlobalFilters.vue';

export default {
  name: 'DashboardEditor',
  components: {
    ExportData,
    WidgetSelector,
    WidgetPreview,
    WidgetConfig,
    WidgetExpanded,
    GlobalFilters,
    GlobalParams,
    WidgetEditGlobalFilters,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
  mounted() {
    this.loadWidgets();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async loadWidgets() {
      this.loadingWidgets = true;
      if (!this.dashboard) {
        this.dashboard = await api.graphic.dashboard.details(this.dashboardId);
      }
      const widgets = await api.graphic.dashboard.widgets.list(this.dashboardId);
      // eslint-disable-next-line no-return-assign
      this.widgets = widgets.reduce((acc, w) => {
        acc[w.id] = w;
        return acc;
      }, {});
      this.updateLayout();
      this.loadingWidgets = false;
      Object.keys(this.widgets).forEach((wig) => {
        this.actualFilters[this.widgets[wig].id] = {};
      });
      this.globalFilters = this.dashboard.globalFilters ?? '';
      this.globalParams = this.dashboard.globalParams ?? '';
    },
    handleResize(item) {
      this.widgets[item.widgetId].config.cols = item.w;
      this.widgets[item.widgetId].config.height = item.h;
      this.updateLayout();
    },
    handleMove(item) {
      this.widgets[item.widgetId].config.x = item.x;
      this.widgets[item.widgetId].config.y = item.y;
      this.updateLayout();
    },
    updateLayout() {
      this.layout = layout.buildLayout(this.widgets);
      let nextY = 0;
      for (let i = 0; i < this.layout; i += 1) {
        nextY = Math.max(nextY, this.layout[i].y + this.layout[i].h);
      }
      this.nextPos = { y: nextY, x: 0 };
    },
    expandWidget(widget, filters) {
      this.expandedWidget = widget;
      this.expandedFilters = filters;
    },
    collapseWidget(widget, filters) {
      if (widget && filters) {
        this.filters[widget.id] = filters;
      }
      this.expandedWidget = null;
    },
    editWidget(widget, index) {
      this.inspectedWidget = { ...widget };
      this.inspectedOpen = !!this.inspectedWidget;
      this.inspectedWidgetOriginal = { ...widget, config: { ...widget.config } };
      this.inspectedWidgetIndex = index;
    },
    async deleteWidget(widgetId) {
      await api.graphic.dashboard.widgets.remove(this.dashboardId, widgetId);
      delete this.widgets[widgetId];
      this.updateLayout();
    },
    async saveDefault(widget) {
      widget.config[widget.type].filterColumns.forEach((column) => {
        // eslint-disable-next-line no-param-reassign
        column.default = this.actualFilters[widget.id][column.name];
      });
      await api.graphic.widget.update(widget, widget.id);
    },
    async saveAll() {
      const promises = Object.keys(this.widgets)
        .map((id) => api.graphic.widget.update(this.widgets[id]));
      await Promise.all(promises);
    },
    handleWidgetChange(widget) {
      this.widgets[this.inspectedWidget.id] = widget;
      this.updateLayout();
      this.$forceUpdate();
    },
    async handleAddWidget(widget) {
      await api.graphic.dashboard.widgets.add(this.dashboardId, widget.id);
      this.widgets[widget.id] = { ...widget };
      this.updateLayout();
      this.$forceUpdate();
    },
    async handleCloneWidget() {
      this.loadWidgets();
      this.$forceUpdate();
    },
    handleWidgetChangeCancel() {
      this.inspectedOpen = false;
      this.inspectedWidget = null;
      this.inspectedWidgetOriginal = null;
      this.inspectedWidgetIndex = null;
      this.updateLayout();
      this.$forceUpdate();
    },
    printDashboard(name) {
      const element = document.getElementById('dashboard');
      const height = element.clientHeight;
      const width = element.clientWidth;

      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('landscape', 'px', [height + 10, width + 10]);
      html2canvas(element).then((canvas) => {
        const image = canvas.toDataURL('image/png');
        pdf.addImage(image, 'PNG', 5, 5, width, height);
        pdf.setFillColor(255, 255, 255, 255);
        pdf.setDrawColor(255, 255, 255, 255);
        pdf.save(name);
      });
    },
    reloadPage() {
      this.$router.go();
    },
  },
  data() {
    return {
      bsDisable: false,
      dashboard: this.$router.currentRoute.params.dashboard,
      dashboardId: this.$router.currentRoute.params.id,
      globalFilters: [],
      globalParams: [],
      widgets: {},
      filters: {},
      actualFilters: {},
      loadingWidgets: true,
      inspectedOpen: false,
      inspectedWidget: null,
      inspectedWidgetOriginal: null,
      inspectedWidgetIndex: null,
      layout: [],
      nextPos: null,
      expandedWidget: null,
      expandedFilters: null,
      thingy: true,
    };
  },
};
</script>

<style lang="scss">
@import "src/scss/variables";
@import "src/scss/mixins";
.vue-grid-item.vue-grid-placeholder {
  background: $turquoise-dark !important;
  @include border_radius(4px);
  border: 3px #107793 dashed;
}
.vue-grid-item.vue-resizable{
  touch-action: none;
}
</style>
