import user from '@/api/client/user';
import dashboard from '@/api/client/dashboard';
import theme from '@/api/client/theme';
import dataset from '@/api/client/dataset';

export default {
  user,
  dashboard,
  theme,
  dataset,
};
