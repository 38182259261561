<template>
  <v-tooltip bottom>
    <template #activator="{ on: tooltip, attrs: tooltipAttrs }">
      <v-dialog
        v-model="dialog"
        max-width="600px"
        @click:outside="$emit('close')"
      >
        <template #activator="{ on: dialog, attrs: dialogAttrs }">
          <v-btn
            v-if="bigButton"
            class="d-flex flex-nowrap rounded-pill primary font-weight-bold
              subtitle-2 white--text pl-4 pr-2 pt-1"
            @click="$emit('click')"
            v-bind="{...tooltipAttrs,...dialogAttrs}"
            v-on="{...tooltip, ...dialog}"
          >
            <v-spacer/>
            ESPORTA
            <v-icon color="white" class="mdi mdi-download" medium></v-icon>
            <v-spacer/>
          </v-btn>
          <v-btn
            v-else
            color="secondary" icon small
            @click="$emit('click')"
            v-bind="{...tooltipAttrs,...dialogAttrs}"
            v-on="{...tooltip, ...dialog}"
          >
            <v-icon color="primary" class="mdi mdi-download" medium></v-icon>
          </v-btn>
        </template>
        <v-card id="facture" style="background:white;">
          <v-card-text class="pt-3 text-h5 text-justify">
            <v-icon color="primary" class="mdi mdi-download" large /> Esporta
            <v-row>
              <v-col cols='10'>
              <v-text-field
                label="Nome File"
                v-model="fileName"
                :rules="fileNameRules"
              />
              </v-col>
              <v-col cols='2'>
              <v-text-field
                label=".CSV"
                :disabled="true"
                v-if="onlyCSV"
              />
              <v-text-field
                label=".pdf"
                :disabled="true"
                v-else-if="onlyPDF"
              />
              <v-select
                v-model="select"
                :hint="`${select.ext}`"
                :items="items"
                item-text="file"
                item-value="ext"
                label="Select"
                persistent-hint
                return-object
                single-line
                v-else
              />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="red"
              text
              @click="() => {dialog = false; $emit('close')}"
            >
              Cancella
            </v-btn>
            <v-spacer />
            <v-btn
              color="blue"
              text
              @click="handleExport"
            >
              SCARICA
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <span v-if="!onlyPDF">Esporta dati</span>
    <span v-else>Esporta come PDF</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'ExportData',
  props: {
    title: {
      type: String,
      required: true,
    },
    query: {
      type: [Object, Array],
      required: false,
    },
    onlyCSV: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    onlyPDF: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isKPI: {
      type: Boolean,
      default: () => false,
    },
    bigButton: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  mounted() {
    // PDF as image of the widget as seen on the browser
    if (this.onlyPDF) {
      this.select = { file: 'Print', ext: '.pdf' };
    // CSV as a .csv file with the formatted data used by the widget
    } else if (this.onlyCSV) {
      this.select = { file: 'CSV', ext: '.csv' };
    }
  },
  methods: {
    handleExport() {
      if (this.select.file === 'CSV' && !this.onlyPDF) {
        const data = Object.entries(JSON.parse(JSON.stringify(this.query)));
        const jsonData = data.map((val) => val[1]);
        const labelrow = [];
        const rows = [];
        jsonData.forEach(() => {
          rows.push([]);
        });

        // eslint-disable-next-line no-restricted-syntax
        for (const key in jsonData[0]) {
          // eslint-disable-next-line no-prototype-builtins
          if (jsonData[0].hasOwnProperty(key)) {
            labelrow.push(key.replace(/(\r\n|\n|\r)/gm, ''));
            jsonData.forEach((element, index) => {
              rows[index].push(element[key]);
            });
          }
        }

        let csvContent = '';
        csvContent += labelrow.toString();
        csvContent += '\n';
        csvContent += rows.map((e) => e.join(',')).join('\n');

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csvContent)}`;
        anchor.target = '_blank';
        if (this.fileName === '') anchor.download = `widget_${this.title}_data.csv`;
        else anchor.download = `${this.fileName}.csv`;
        anchor.click();
        this.dialog = false;
      } else if (this.select.file === 'Print') {
        this.dialog = false;
        let name;
        if (this.fileName === '') name = `widget_${this.title}.pdf`;
        else name = `${this.fileName}.pdf`;
        this.$emit('printPDF', name);
      }
    },
  },
  data() {
    return {
      dialog: false,
      select: { file: 'CSV', ext: '.csv' },
      items: [
        { file: 'CSV', ext: '.csv' },
        { file: 'Print', ext: '.pdf' },
      ],
      fileNameRules: [
        (value) => (value || '').length <= 30 || 'Max 30 characters',
        (value) => {
          // Windows filename constraints
          const pattern = /(^(?!\.)(?!com[0-9]$)(?!con$)(?!lpt[0-9]$)(?!nul$)(?!prn$)[^|*?\\:<>/$"]*[^.|*?\\:<>/$"]+$)|(^$)/;
          return pattern.test(value) || 'Invalid filename.';
        },
      ],
      fileName: '',
    };
  },
};
</script>

<style lang="scss">
.confirm-dialog-title {
  text-align: justify;
  overflow: hidden;
}
</style>
