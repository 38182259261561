<template>
  <v-card flat tile>
    <v-form v-model="valid">
      <v-text-field
        v-model="value.title"
        label="Titolo"
        hide-details="auto"
        required
        @input="handleChange"
        :rules="rules.titleRules"
      />
      <span class="grey--text">ID: {{value.id}}</span>
      <v-textarea
        v-model="value.description"
        @input="handleChange"
        label="Descrizione"
        rows="2"
        counter="250"
        :rules="rules.descriptionRules"
      />
      <v-text-field
        v-model="value.icon"
        label="Icon"
        required
        @input="handleChange"
        :rules="rules.iconRules"
      />
        Preview:
        <v-icon>
          {{value.icon}}
        </v-icon>
    </v-form>
  </v-card>
</template>

<script>
import models from '@/tools/models';

export default {
  name: 'GeneralConfig',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleChange() {
      if (this.valid) {
        this.$emit('input', this.value);
      }
    },
  },
  data() {
    return {
      valid: true,
      widgetTypeMap: models.widgetTypeMap,
      rules: {
        titleRules: [(v) => !!v || 'Inserire titolo'],
        descriptionRules: [(v) => (v || '').length <= 250 || 'Massimo 250 caratteri'],
        iconRules: [(v) => !!v || 'Inserire titolo'],
      },
    };
  },
};
</script>

<style scoped>

</style>
