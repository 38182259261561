<template>
    <v-card :loading="dashboardLoading" class="my-3 pa-3">
        <v-card v-if="noData" class="my-1" style="height: 80px;">
            <v-card-title class="pa-0">
                <v-spacer/>
                <v-card class="d-flex justify-start mb-6 pa-2" flat>
                    There are no dashboards.
                </v-card>
                <v-spacer/>
            </v-card-title>
        </v-card>
        <v-card
            v-else
            v-for="(item, i) in dashboards"
            :key="i"
            class="my-1">
            <v-card-title>
                <v-row  no-gutters>
                    <v-col :cols=1 align-self="center">
                        <v-card class="pa-0 text-subtitle-1" flat tile >
                            <v-icon>mdi-view-dashboard</v-icon>
                        </v-card>
                    </v-col>
                    <v-col :cols=4 align-self="center">
                        <v-card class="pa-0 text-subtitle-1" flat tile align-center fill-height>
                            Dashboard: {{item.displayName}}
                        </v-card>
                    </v-col>
                    <v-col :cols=4 align-self="center">
                        <v-card v-if="item.databaseSchema" class="pa-0 text-subtitle-1"
                        flat tile align-center fill-height
                        >
                          <v-icon color="info" class="px-1">mdi-database</v-icon>
                            {{item.databaseSchema.displayName}}
                        </v-card>
                        <v-card v-else class="pa-0 text-subtitle-1"
                        flat tile align-center fill-height>
                          <v-icon color="missing" class="px-1">mdi-database-remove</v-icon>
                            No Schema
                        </v-card>
                    </v-col>
                </v-row>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="red" v-bind="attrs" v-on="on" icon
                            @click="handleDashboardDelete(i)"
                        >
                            <v-icon color="primary">mdi-delete</v-icon>
                        </v-btn>
                    </template>
                            <span>Rimuovi Accesso</span>
                    <v-spacer/>
                </v-tooltip>
                <v-tooltip bottom>
                    <v-spacer/>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon
                            @click="handleDashboardEdit(item)"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                            <span>Modifica Dashboard</span>
                    <v-spacer/>
                </v-tooltip>
            </v-card-title>
        </v-card>
        <UserDashboardCreator
        :user="user"
        @newDashboard="handleNewDashboard"
        />
    </v-card>
</template>

<script>
import api from '@/api';
import UserDashboardCreator from '@/admin/components/main/UserManagement/UserDashboardCreator.vue';

export default {
  name: 'UserDetails',
  components: { UserDashboardCreator },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.handleDashboardsTabAccess();
  },
  methods: {
    async handleDashboardsTabAccess() {
      this.dashboardLoading = true;
      this.dashboards = await api.admin.user.listDashboards(this.user.id);
      if (this.dashboards.length === 0) {
        this.noData = true;
      }
      this.dashboardLoaded = true;
      this.dashboardLoading = false;
    },
    async handleDashboardDelete(index) {
      await api.admin.dashboard.users.remove(this.dashboards[index].id, this.user.id);
      this.dashboards.splice(index, 1);
      this.$emit('delete', this.user);
    },
    async handleDashboardSchema(user, index) {
      // on user id, edit the dashboard.schema at dashboards[index]
      if (!user || !index) {
        return 'null';
      }
      return 'notnull';
    },
    async handleDashboardEdit(dashboard) {
      // on user id, edit the dashboard widgets at dashboards[index], link to widgets screen
      this.$router.push(
        {
          name: 'DashboardEditor',
          params: { dashboard, id: dashboard.id },
        },
      );
    },
    async handleNewDashboard(data) {
      this.dashboards.push(data);
      this.handleDashboardsTabAccess();
      this.$emit('new', this.user);
    },
  },
  data() {
    return {
      tab: 0,
      noData: false,
      dashboardLoaded: false,
      dashboardLoading: true,
      dashboards: null,
    };
  },
};
</script>

<style scoped>

</style>
