<template>
  <v-row no-gutters >
    <v-col class="col-12 text-left" >
      <div class="text-widget" v-html="markdown"/>
    </v-col>
  </v-row>
</template>

<script>
import marked from 'marked';

export default {
  name: 'TextWidget',
  components: { },
  props: {
    widget: {
      type: Object,
      required: true,
    },
  },
  watch: {
    widget: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal?.config?.text?.text !== oldVal?.config?.text?.text) {
          marked(this?.widget?.config?.text?.text || '');
        }
      },
    },
  },
  data() {
    return {
      tab: 'code',
      markdown: marked(this?.widget?.config?.text?.text || ''),
    };
  },
};
</script>

<style lang="scss">
.text-widget {
 h1{
  padding: 15px 0;
 }
  h2{
    padding: 12px 0;
  }
  h3{
    padding: 10px 0;
  }
  h4,h5{
    padding: 7px 0;
  }
  h6 {
    padding: 5px 0;
  }
 table {
   border-collapse:collapse;
   thead {
     background-color: #d4d4d4;
     th {
       border-right: 0;
       border-left: 0;
       padding: 5px 7px;
     }
   }
   tr {
     border-bottom: 1px solid lightgrey;
     td {
       padding: 3px 5px;
     }
   }
 }
}
</style>
