const list = async () => {
  const response = await window.axios.get('/admin/theme');
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const create = async (theme) => {
  const response = await window.axios.post('/admin/theme/create', theme);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const update = async (theme) => {
  if (!theme.logoFile) {
    const response = await window.axios.post('/admin/theme/update', theme);
    if (response.status === 200) {
      return response.data;
    }
    return 'error';
  }

  const formData = new FormData();
  const fileName = theme.logoFile.name;
  const file = theme.logoFile;
  formData.append('file', file, fileName);

  formData.append('id', theme.id);
  formData.append('name', theme.name);
  formData.append('primaryColor', theme.primaryColor);
  formData.append('secondaryColor', theme.secondaryColor);
  formData.append('iconColor', theme.iconColor);
  formData.append('backgroundColor', theme.backgroundColor);
  formData.append('bannerColorR', theme.bannerColorR);
  formData.append('bannerColorL', theme.bannerColorL);
  formData.append('navbarActiveColor', theme.navbarActiveColor);
  formData.append('navbarColor', theme.navbarColor);
  formData.append('footerColor', theme.footerColor);
  formData.append('cardBackgroundColor', theme.cardBackgroundColor);
  formData.append('chartColors', JSON.stringify(theme.chartColors));
  formData.append('footerText', theme.footerText);
  formData.append('textSize', theme.textSize);

  const options = { headers: { 'Content-Type': 'multipart/form-data' } };

  const response = await window.axios.post('/admin/theme/update-logo', formData, options);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const remove = async (themeId) => {
  const response = await window.axios.delete(`/admin/theme/${themeId}`);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const addUser = async (userId, themeId) => {
  const response = await window.axios.post(`/admin/theme/join-user/${userId}/${themeId}`);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const removeUser = async (userId) => {
  const response = await window.axios.post(`/admin/theme/remove-user/${userId}`);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

export default {
  list,
  create,
  update,
  remove,
  addUser,
  removeUser,
};
