<template>
  <!-- Main Screen Dashboard Card dropdown -->
  <div>
    <v-tabs v-model="tab">
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab key="details">Dettagli</v-tab>
      <v-tab @change="handleUserTabAccess" key="users">Utenti</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item key="details">
        <v-form>
          <v-row>
            <v-col class="mx-2 mt-2 mb-n4 align-center">
              <v-icon color="secondary" v-if="dashboard.databaseSchema" class="mdi mdi-database" />
              <v-icon color="missing" v-else class="mdi mdi-database-plus" />
              Db Schema: {{dashboard.databaseSchema.displayName}}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1">
              <v-btn
                :href="'https://pictogrammers.github.io/@mdi/font/6.5.95/'"
                :target="'._blank'"
                icon
                class="rounded-pill mb- mt-3" color="info" @click="handleIconSave">
                <v-icon class="mdi mdi-information-outline"/>
              </v-btn>
            </v-col>
            <v-col cols="10">
              <v-text-field
                v-model="icon"
                label="Icon"
                required
                @blur="handleIconChange"
              />
            </v-col>
          </v-row>
          <v-row>
              <v-spacer/>
              <v-btn class="rounded-pill mb-5 mr-5" color="info" @click="handleIconSave">
                Save <v-icon class="mdi mdi-content-save"/>
              </v-btn>
          </v-row>
        </v-form>
      </v-tab-item>
      <v-tab-item key="users">
        <v-card flat :loading="userLoading">
          <v-list dense>
            <v-list-item
              v-for="(item, i) in users"
              :key="i"
            >
              <v-list-item-icon>
                <v-icon large>mdi-account-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text-body-1">
                  {{item.firstName}}
                  {{item.lastName}}
                </v-list-item-title>
                <v-list-item-content>
                  {{item.userName}}
                </v-list-item-content>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon color="primary" @click="handleUserDelete(item, i)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <UserSelector @selected="handleUserSelection"/>
            </v-list-item>
          </v-list>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import api from '@/api';
import UserSelector from '@/admin/components/main/UserManagement/UserSelector.vue';

export default {
  name: 'DashboardDetails',
  components: { UserSelector },
  props: {
    actualIcon: {
      type: String,
      required: true,
    },
    dashboard: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async handleUserTabAccess() {
      if (this.userLoaded || this.userLoading) {
        return;
      }
      this.userLoading = true;
      this.users = await api.admin.dashboard.users.list(this.dashboard.id);
      this.userLoaded = true;
      this.userLoading = false;
    },
    async handleUserSelection(user) {
      if (!user) {
        return;
      }
      await api.admin.dashboard.users.add(this.dashboard.id, user.id);
      this.users.push(user);
    },
    async handleUserDelete(user, index) {
      if (!user) {
        return;
      }
      await api.admin.dashboard.users.remove(this.dashboard.id, user.id);
      this.users.splice(index, 1);
    },
    handleIconChange() {
      this.$emit('icon', this.icon);
    },
    handleIconSave() {
      this.$emit('iconSave', this.icon);
    },
  },
  data() {
    return {
      tab: 0,
      userLoaded: false,
      userLoading: false,
      users: null,
      icon: this.actualIcon,
    };
  },
};
</script>

<style scoped>

</style>
