<template>
  <!-- Main Admin Page -->
  <v-container fluid>
    <v-row class="mb-4">
      <DashboardCreator
        @created="handleCreation"
      />
      <DashboardCard
        v-for="(dashboard, index) in dashboards"
        :key="`dashb-${index}`"
        :dashboard="dashboard"
        :index="index"
        @cloned="handleClonation"
        @deleted="handleDeletion"
      />
    </v-row>
    <v-expansion-panels >
      <v-expansion-panel
        v-for="(user, index) in users"
        :key="`usr-${index}`"
      >
        <v-expansion-panel-header>
          <v-row class="ma-0">
            <h4> Utente:&nbsp; </h4> {{user}}
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <DashboardCard
              v-for="(dashboard, index) in dashboards.filter((dash) => {
                if (dash.users)
                  return dash.users.includes(user);
              })"
              :key="`dashb-${index}`"
              :dashboard="dashboard"
              :index="index"
              @deleted="handleDeletion"
            />
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel :key="'no-usr'">
        <v-expansion-panel-header>
          <h4> Senza utenti </h4>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <DashboardCard
              v-for="(dashboard, index) in dashboardsNoUser"
              :key="`dashb-${index}`"
              :dashboard="dashboard"
              :index="index"
              @deleted="handleDeletion"
            />
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import api from '@/api';
import DashboardCard from '@/admin/components/main/home/DashboardCard.vue';
import DashboardCreator from '@/admin/components/main/home/DashboardCreator.vue';

export default {
  name: 'Home',
  components: { DashboardCreator, DashboardCard },
  async mounted() {
    await this.loadDashboards();
    this.dashboards = this.dashboards.sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });
  },
  methods: {
    handleCreation(data) {
      this.dashboards.push(data);
    },
    handleClonation(data) {
      this.dashboards.push(data);
    },
    handleDeletion(index) {
      this.dashboards.splice(index, 1);
    },
    async loadDashboards() {
      if (this.loading) return;
      this.loading = true;
      this.dashboards = await api.admin.dashboard.list();
      this.users = [];
      this.dashboards.forEach(async (dash) => {
        const users = await api.admin.dashboard.users.list(dash.id);
        // eslint-disable-next-line no-param-reassign
        dash.users = [];
        users.forEach((user) => {
          dash.users.push(user.userName);
          if (!this.users.includes(user.userName)) {
            this.users.push(user.userName);
          }
        });
        this.users = this.users.sort();
        if (dash.users.length === 0) {
          this.dashboardsNoUser.push(dash);
        }
      });
      this.loading = false;
    },
  },
  data() {
    return {
      loading: false,
      dashboards: null,
      dashboardsNoUser: [],
      users: null,
    };
  },
};
</script>

<style scoped>

</style>
