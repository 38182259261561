<template>
  <!-- User Managemet Main Page -->
  <v-card class="ma-3 pa-3">
    <v-card-title>
      Themes
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="themes || []"
      :single-expand="true"
      :items-per-page="10"
      :loading="loadingThemes"
      :expanded.sync="expanded"
      item-key="id"
      class="elevation-1"
    >
      <!-- Table row, controls -->
      <template v-slot:item="{ item, expand, isExpanded }">
        <tr>
          <td>{{item.name}}</td>
          <td>
            <v-chip
              style="border: inset 1px"
              :color="item.primaryColor"
              dark
            >
              {{ item.primaryColor }}
            </v-chip>
          </td>
          <td>
            <v-chip
              style="border: inset 1px"
              :color="item.secondaryColor"
              dark
            >
              {{ item.secondaryColor }}
            </v-chip>
          </td>
          <td style="text-align: right">
            <UserSelector
              :theme="item"
            />
            <v-btn
              v-if="!isExpanded"
              color="primary"
              outlined
              @click="expand(!isExpanded)"
            >
              <v-icon>mdi-pencil</v-icon>
              Edit
            </v-btn>
            <v-btn
              v-else
              color="info"
              outlined
              @click="saveTheme(item)"
            >
              <v-icon>mdi-content-save-edit</v-icon>
              Salva
            </v-btn>
            <v-btn
              v-if="!isExpanded"
              class="mx-2"
              color="error"
              outlined
              :disabled="item.id === 0"
              @click="handleDeleteTheme(false, item)"
            >
              <v-icon>mdi-delete</v-icon>
              Cancella
            </v-btn>
            <v-btn
              v-else
              class="mx-2"
              color="warning"
              outlined
              @click="expand(!isExpanded)"
            >
              <v-icon>mdi-close-thick</v-icon>
              Chiudi
            </v-btn>
            <!-- Delete dialog -->
            <v-dialog
              max-width="400px"
              v-model="openDeleteConfirm">
              <v-card>
                <v-card-text class="pt-3 text-h6 text-justify">
                  Confermi l'eliminazione di questo tema?
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    color="red"
                    text
                    @click="handleDeleteTheme(true, item)"
                  >
                    Si
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    color="blue"
                    text
                    @click="openDeleteConfirm = false"
                  >
                    No
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </td>
        </tr>
      </template>

      <!-- Expanded, Edition menu -->
      <template v-slot:expanded-item="{ item, headers}">
        <td :colspan="headers.length">
          <v-row class="ma-0 pa-0">
            <v-col cols=12 v-if="saving">
              <v-alert
                border="top"
                color="secondary"
                dark
              >
                Saving...
              </v-alert>
            </v-col>
            <v-col cols=9>
              <v-row class="ma-0 pa-0">
                <v-col cols=3>
                  <v-text-field
                    dense
                    outlined
                    hide-details="auto"
                    v-model="item.name"
                    label="Name"
                    @focus="active = null"
                  />
                </v-col>
                <v-col cols=3
                  :class="active === 'primaryColor' ? 'selected' : ''"
                >
                  <v-text-field
                    dense
                    outlined
                    hide-details="auto"
                    v-model="item.primaryColor"
                    @focus="setActive('primaryColor')"
                    label="Primary Color"
                  >
                    <template v-slot:append>
                      <v-icon :color="item.primaryColor">mdi-checkbox-blank-circle</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols=3
                  :class="active === 'secondaryColor' ? 'selected' : ''"
                >
                  <v-text-field
                    dense
                    outlined
                    hide-details="auto"
                    v-model="item.secondaryColor"
                    @focus="setActive('secondaryColor')"
                    label="Secondary Color"
                  >
                    <template v-slot:append>
                      <v-icon :color="item.secondaryColor">mdi-checkbox-blank-circle</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols=3
                  :class="active === 'iconColor' ? 'selected' : ''"
                >
                  <v-text-field
                    dense
                    outlined
                    hide-details="auto"
                    v-model="item.iconColor"
                    @focus="setActive('iconColor')"
                    label="Icon Color"
                  >
                    <template v-slot:append>
                      <v-icon :color="item.iconColor">mdi-checkbox-blank-circle</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols=12> Optional Settings:</v-col>
                <v-col cols=3
                  :class="active === 'backgroundColor' ? 'selected' : ''"
                >
                  <v-text-field
                    clearable
                    dense
                    outlined
                    hide-details="auto"
                    v-model="item.backgroundColor"
                    @focus="setActive('backgroundColor')"
                    label="Background Color"
                  >
                    <template v-slot:append>
                      <v-icon :color="item.backgroundColor">mdi-checkbox-blank-circle</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols=3
                  :class="active === 'cardBackgroundColor' ? 'selected' : ''"
                >
                  <v-text-field
                    clearable
                    dense
                    outlined
                    hide-details="auto"
                    v-model="item.cardBackgroundColor"
                    @focus="setActive('cardBackgroundColor')"
                    label="Card Background"
                  >
                    <template v-slot:append>
                      <v-icon :color="item.cardBackgroundColor">mdi-checkbox-blank-circle</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols=3
                  :class="active === 'bannerColorL' ? 'selected' : ''"
                >
                  <v-text-field
                    clearable
                    dense
                    outlined
                    hide-details="auto"
                    v-model="item.bannerColorL"
                    @focus="setActive('bannerColorL')"
                    label="Banner Color Left"
                  >
                    <template v-slot:append>
                      <v-icon :color="item.bannerColorL">mdi-checkbox-blank-circle</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols=3
                  :class="active === 'bannerColorR' ? 'selected' : ''"
                >
                  <v-text-field
                    clearable
                    dense
                    outlined
                    hide-details="auto"
                    v-model="item.bannerColorR"
                    @focus="setActive('bannerColorR')"
                    label="Banner Color Right"
                  >
                    <template v-slot:append>
                      <v-icon :color="item.bannerColorR">mdi-checkbox-blank-circle</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols=3
                  :class="active === 'navbarColor' ? 'selected' : ''"
                >
                  <v-text-field
                    clearable
                    dense
                    outlined
                    hide-details="auto"
                    v-model="item.navbarColor"
                    @focus="setActive('navbarColor')"
                    label="Navigation Bar Color"
                  >
                    <template v-slot:append>
                      <v-icon :color="item.navbarColor">mdi-checkbox-blank-circle</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols=3
                  :class="active === 'navbarActiveColor' ? 'selected' : ''"
                >
                  <v-text-field
                    clearable
                    dense
                    outlined
                    hide-details="auto"
                    v-model="item.navbarActiveColor"
                    @focus="setActive('navbarActiveColor')"
                    label="Navigation Bar Active Color"
                  >
                    <template v-slot:append>
                      <v-icon :color="item.navbarActiveColor">mdi-checkbox-blank-circle</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols=3
                  :class="active === 'footerColor' ? 'selected' : ''"
                >
                  <v-text-field
                    clearable
                    dense
                    outlined
                    hide-details="auto"
                    v-model="item.footerColor"
                    @focus="setActive('footerColor')"
                    label="Footer Color"
                  >
                    <template v-slot:append>
                      <v-icon :color="item.footerColor">mdi-checkbox-blank-circle</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols=3>
                  <v-text-field
                    clearable
                    dense
                    outlined
                    hide-details="auto"
                    v-model="item.chartColors"
                    label="Chart Color List"
                    @focus="active = null"
                  />
                </v-col>
                <v-col cols=3>
                  <v-file-input
                    clearable
                    dense
                    outlined
                    hide-details="auto"
                    v-model="item.logoFile"
                    label="logo"
                    @change="(file) => loadLogo(item, file)"
                    @focus="active = null"
                  />
                </v-col>
                <v-col cols=3>
                  <v-text-field
                    clearable
                    dense
                    outlined
                    hide-details="auto"
                    v-model="item.footerText"
                    label="Footer Text"
                    @focus="active = null"
                  />
                </v-col>
                <v-col cols=3>
                  <v-text-field
                    v-show="false"
                    clearable
                    dense
                    outlined
                    hide-details="auto"
                    v-model="item.textSize"
                    label="Font Size"
                    @focus="active = null"
                  />
                </v-col>
                <v-col cols=3>
                  <span class="grey--text mt-2">(Theme Id: {{item.id}})</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols=3
              :class="active == null ? '' : 'selected'"
            >
              <v-color-picker
                class="ma-3"
                :disabled="active == null"
                v-model="colorPickerColor"
                :mode="pickerMode"
                @update:color="setActiveColor(item)"
              />
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col cols=12>
              Anteprima:
            </v-col>
            <v-col cols="12">
              <ThemePreview :theme="item" :logo="(item.logoUrl || null)"/>
            </v-col>
          </v-row>
          <v-divider/>
        </td>
      </template>
    </v-data-table>
    <v-row class="mt-1 pa-3">
      <v-spacer/>
      <ThemeCreator @created="handleNewTheme"/>
    </v-row>
  </v-card>
</template>

<script>
import api from '@/api';
import ThemeCreator from '@/admin/components/main/ThemeManagement/ThemeCreator.vue';
import ThemePreview from '@/admin/components/main/ThemeManagement/ThemePreview.vue';
import UserSelector from '@/admin/components/main/ThemeManagement/UserSelector.vue';

const tableHeaders = [
  { text: 'Nome', align: 'start', value: 'name' },
  { text: 'Colore Primario', value: 'primaryColor' },
  { text: 'Colore Secondario', value: 'secondaryColor' },
  { text: 'Azioni', align: 'center', value: 'actions' },
];

export default {
  name: 'ThemeManagement',
  components: { ThemeCreator, ThemePreview, UserSelector },
  created() {
    this.loadThemes();
  },
  methods: {
    async loadThemes() {
      this.loadingThemes = true;
      this.themes = await api.admin.theme.list();

      for (let i = 0; i < this.themes.length; i += 1) {
        if (this.themes[i].logo) {
          // eslint-disable-next-line no-await-in-loop
          const base64 = await fetch(`data:@file/png;base64,${this.themes[i].logo}`);
          // eslint-disable-next-line no-await-in-loop
          this.themes[i].logoUrl = URL.createObjectURL(await base64.blob());
        } else this.themes[i].logoUrl = null;
      }

      this.loadingThemes = false;
    },
    async loadLogo(theme, file) {
      const blob = new Blob([file], { type: file.type });
      theme.logo = blob;
      theme.logoUrl = URL.createObjectURL(blob);
    },
    setActive(selector) {
      this.active = selector;
      if (this.item[selector] == null) this.colorPickerColor = '#808080';
      else this.colorPickerColor = this.item[selector];
    },
    setActiveColor(item) {
      if (this.active) item[this.active] = this.colorPickerColor;
    },
    async saveTheme(theme) {
      this.saving = true;
      await api.admin.theme.update(theme);
      this.themes = await api.admin.theme.list();
      this.saving = false;
    },
    async handleNewTheme() {
      const newTheme = await api.admin.theme.create({
        name: 'newTheme',
        primaryColor: '#35d1ad',
        secondaryColor: '#062b5e',
        iconColor: '#ffffff',
      });
      this.themes.push(newTheme);
    },
    async handleDeleteTheme(deleteConfirmation, theme) {
      if (deleteConfirmation) {
        await api.admin.theme.remove(theme.id);
        const updatedThemes = (await api.admin.theme.list()).map((x) => x.id);
        this.themes = this.themes.filter((x) => updatedThemes.includes(x.id));
        this.openDeleteConfirm = false;
      } else this.openDeleteConfirm = true;
    },
  },
  data() {
    return {
      overlay: true,
      expanded: [],
      saving: false,
      search: '',
      loadingThemes: null,
      headers: tableHeaders,
      active: null,
      colorPickerColor: '#808080',
      openDeleteConfirm: false,
      // themes: null,
      themes: [],
      user: null,
      pickerMode: 'hexa',
      detailsKey: 0,
    };
  },
};
</script>

<style scoped>
.selected {
  border: solid var(--v-primary-base);
}
</style>
