<template>
  <v-row no-gutters class="table-widget">
    <v-col cols="12" class="table-widget--table-wrap">
      <v-data-table
        v-if="ready && resultLoaded && !failed"
        dense
        :headers="resultHeaders"
        :items="resultValues"
        :server-items-length="totalRowCount"
        :loading="loading"
        :options.sync="options"
      >
        <template v-slot:footer.prepend>
          <v-spacer/>
          <span>Items:</span>
          <v-select
            dense
            hide-details="auto"
            class="my-n3 mx-2 text-caption"
            style="max-width: 80px"
            :items="[5,10,20,50,100,options.itemsPerPage].sort((a,b) => a-b)"
            v-model="options.itemsPerPage"
          />
        </template>
      </v-data-table>
      <v-progress-circular
        v-if="!resultLoaded"
        indeterminate
        color="primary"
        class="justify-center"/>
      <div v-else-if="failed" class="error--text text-subtitle-1">
        Impossibile estrarre dati.
      </div>
    </v-col>
  </v-row>
</template>

<script>
import api from '@/api';
import WidgetQueryParams from '@/admin/components/main/DashboardEditor/widgets/WidgetQueryParams.vue';

const computeItemPerPage = (height, showFilters) => {
  const availableHeight = height - 37 - (showFilters ? 38 : 0);
  return Math.max(1, Math.floor(availableHeight / 32) - 1);
};

export default {
  name: 'TableWidget',
  // eslint-disable-next-line vue/no-unused-components
  components: { WidgetQueryParams },
  props: {
    widget: {
      type: Object,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    paramState: {
      type: Object,
      required: false,
    },
  },
  watch: {
    queryResult: {
      deep: true,
      handler() {
        this.handleData();
      },
    },
    height(newVal) {
      this.options.itemsPerPage = computeItemPerPage(newVal, this.showFilters);
      this.options.page = 1;
      this.extractData();
    },
    paramState: {
      handler() {
        this.options.page = 1;
        this.extractData();
      },
      deep: true,
    },
    options: {
      handler(newVal, oldVal) {
        if (JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
          this.extractData();
        }
      },
      deep: true,
    },
  },
  computed: {
  },
  mounted() {
    this.ready = true;
    this.showFilters = this.widget.datasets[0].params.length > 0;
    this.options.itemsPerPage = computeItemPerPage(this.height, this.showFilters);
    this.extractData();
  },
  methods: {
    handleData() {
      if (this.queryResult?.failed) {
        this.queryFailed = true;
        this.queryError = this.queryResult.error;
        this.resultValues = null;
        this.resultHeaders = null;
      } else {
        this.totalRowCount = this.queryResult.metas.rowCount || this.totalRowCount;
        this.resultHeaders = this.queryResult.metas.columns.map((col) => ({
          text: col.name,
          value: col.name,
        }));
        this.headerPositions = Object.fromEntries(
          this.resultHeaders.map((col, idx) => [col.text, idx + 1]),
        );
        this.resultValues = this.queryResult.values;
      }
      this.loading = false;
      this.resultLoaded = true;
    },
    async extractData() {
      if (!this.ready || this.loading || !this.height) {
        return;
      }
      this.loading = true;
      const admin = this.$store.state.session.roles.indexOf('admin') >= 0;
      const datasetEndpoints = admin ? api.admin.dataset : api.client.dataset;
      const params = {
        ...this.paramState,
        limit: this.options.itemsPerPage,
        offset: (this.options.page - 1) * this.options.itemsPerPage,
      };
      if (this.options.sortBy.length > 0) {
        params.orderBy = this.options.sortBy
          .map((name, idx) => {
            const pos = this.headerPositions[name];
            if (this.options.sortDesc[idx]) return `${pos} desc`;
            return `${pos} asc`;
          })
          .join(',');
      }
      this.queryResult = await datasetEndpoints.fetchData(this.widget.datasets[0].id, params);
      this.$emit('query', this.queryResult);
      this.handleData();
    },
  },
  data() {
    return {
      ready: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortByDesc: [],
      },
      queryResult: null,
      resultHeaders: null,
      headerPositions: {},
      resultValues: null,
      loading: false,
      resultLoaded: false,
      failed: false,
      totalRowCount: null,
      showFilters: false,
    };
  },
};
</script>

<style lang="scss">
  .table-widget {
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    & > .table-widget--table-wrap {
      flex:1
    }
    .v-data-footer__select {
      display: none;
    }
    .v-data-footer__pagination {
      margin-left: auto;
    }
    .v-data-table__wrapper{
      thead{
        th {
          white-space: nowrap;
        }
      }
      tbody {
        td {
          white-space: nowrap;
        }
      }
    }
  }
</style>
