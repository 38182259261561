<template>
  <v-card flat tile>
    <v-card-text>
    <v-expansion-panels>
      <v-expansion-panel key="general">
        <BuilderStepHeader section-name="Generale" :errors="generalErrors"/>
        <GeneralConfigStep
          v-model="widget"
          :step="step"
          :widget-types="widgetTypes"/>
      </v-expansion-panel>
      <v-expansion-panel key="data" :disabled="!widget.type">
        <BuilderStepHeader section-name="Dati" :errors="datasetErrors"/>
        <DatasetConfigStep
          v-if="widget.type && widget.type !== 'text'"
          v-model="dataset"
          :schema="schema"
          @metaRefresh="handleMetasRefresh"
          @input="handleDatasetChange"/>
        <!-- On Multiwidget Only -->
        <DatasetConfigStep
          v-if="widget.type && widget.type === 'multi_chart'"
          v-model="dataset2"
          :step="step"
          :schema="schema"
          @metaRefresh="handleMetasRefresh"
          @input="handleDataset2Change"/>
      </v-expansion-panel>
      <v-expansion-panel key="widget" :disabled="!metas && widget.type!=='text'">
        <BuilderStepHeader section-name="Widget" :errors="widgetErrors"/>
        <WidgetConfigStep
          v-if="metas || widget.type==='text'"
          v-model="widget.config"
          :step="step"
          :metas="metas"
          :dataset="dataset"
          :type="widget.type"
          @input="checkWidgetErrors" />
      </v-expansion-panel>
    </v-expansion-panels>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="primary" @click="createWidget">
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import api from '@/api';
import models from '@/tools/models';
import GeneralConfigStep from '@/admin/components/main/DashboardEditor/builder/GeneralConfigStep.vue';
import DatasetConfigStep from '@/admin/components/main/DashboardEditor/builder/DatasetConfigStep.vue';
import WidgetConfigStep from '@/admin/components/main/DashboardEditor/builder/WidgetConfigStep.vue';
import BuilderStepHeader from '@/admin/components/main/DashboardEditor/builder/BuilderStepHeader.vue';

export default {
  name: 'WidgetBuilder',
  components: {
    BuilderStepHeader, WidgetConfigStep, DatasetConfigStep, GeneralConfigStep,
  },
  props: {
    schema: {
      type: Object,
      required: true,
    },
    position: {
      type: Object,
      required: true,
    },
  },
  computed: {
    generalErrors() {
      const errors = [];
      if (!this.widget.type) {
        errors.push('Nessun tipo selezionato');
      }
      if (!this.widget.title) {
        errors.push('Titolo del widget non specificato');
      }
      return errors;
    },
    datasetErrors() {
      if (!this.widget.type) {
        return [];
      }
      const errors = [];
      if (this.widget.type === 'text' && !this.dataset.query && !this.dataset.label) {
        return [];
      }
      if (!this.dataset.label) { errors.push('Indicare un\'etichetta'); }
      if (!this.dataset.query) { errors.push('Inserire una query'); }
      // On Multiwidget Only
      if (this.widget.type === 'multi_chart') {
        if (!this.dataset2.label) { errors.push('Indicare un\'etichetta'); }
        if (!this.dataset2.query) { errors.push('Inserire una query'); }
      }
      if (!this.metas || this.metas.length === 0) {
        errors.push('Impossibile stabilire le colonne estratte dalla query');
      }
      return errors;
    },
  },
  methods: {
    checkWidgetErrors() {
      if (!this.widget?.type) this.widgetErrors = [];
      this.widgetErrors = models.widgetConfigValidators[this.widget.type](this.widget);
    },
    handleMetasRefresh(metas) {
      this.metas = metas;
      this.widget.config[this.widget.type] = {
        ...models.widgetTypeMap[this.widget.type].defaultConfig,
      };
      this.checkWidgetErrors();
    },
    handleDatasetChange(value) {
      this.dataset = value;
      this.checkWidgetErrors();
    },
    // On Multiwidget Only
    handleDataset2Change(value) {
      this.dataset2 = value;
      this.checkWidgetErrors();
    },
    async createWidget() {
      console.log(this.dataset);
      if ((this.dataset.creator || {}).active === true) {
        await api.admin.dataset.createTable(
          this.dataset.creator.tableName,
          this.dataset.schemaId,
          this.dataset.creator.fields,
        );
        return;
      }

      this.widget.showOnHome = true;
      this.widget.config.x = this.position.x;
      this.widget.config.y = this.position.y;
      if (this.widget.type === 'editable_table') {
        this.widget.config.query = this.dataset.query;
        this.widget.config.schemaId = this.dataset.schemaId;
      }
      const created = await api.admin.widget.create(this.widget);
      this.dataset.widgetId = created.id;
      const dataset = await api.admin.widget.datasets.add(created.id, this.dataset);
      created.datasets = [dataset];
      if (this.widget.type === 'multi_chart') {
        const dataset2 = await api.admin.widget.datasets.add(created.id, this.dataset2);
        created.datasets.push(dataset2);
      }
      this.$emit('created', created);
    },
    reset() {
      this.step = 1;
      this.metas = null;
      this.widget = { ...models.widgetModel };
      this.dataset = { ...models.datasetModel, schemaId: this.schema.id };
      // On Multiwidget Only
      this.dataset2 = { ...models.datasetModel, schemaId: this.schema.id };
    },
  },
  data() {
    return {
      step: 1,
      widgetTypes: models.widgetTypes,
      metas: null,
      widget: { ...models.widgetModel },
      dataset: { ...models.datasetModel, schemaId: this.schema.id },
      // On Multiwidget Only
      dataset2: { ...models.datasetModel, schemaId: this.schema.id },
      errors: {
        general: null,
        dataset: null,
        widget: null,
      },
      widgetErrors: [],
    };
  },
};
</script>

<style scoped>

</style>
