<template>
  <v-expansion-panel-content>
    <v-card flat tile class="pt-4">
      <DatasetEditDialog
        :schema="schema"
        :dataset="value"
        @modified="updateValue"
        @updatedColumns="updateColumns"
        @metaRefresh="handleMetaRefresh"/>
    </v-card>
  </v-expansion-panel-content>
</template>

<script>
import DatasetEditDialog from '@/graphic/components/main/DashboardEditor/dataset/DatasetEditDialog.vue';

export default {
  name: 'DatasetConfigStep',
  components: { DatasetEditDialog },
  props: {
    value: {
      type: Object,
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleMetaRefresh(metas) {
      this.columns = metas;
      this.$emit('metaRefresh', metas);
    },
    updateValue(value) {
      console.log(this.value);
      this.value = value;
      this.$emit('input', value);
    },
    updateCreator(creator) {
      this.value.creator = creator;
      this.updateValue(this.value);
    },
    updateColumns(columns) {
      this.$emit('updateColumns', columns);
    },
  },
  data() {
    return {
      queryFailed: false,
      queryChanged: false,
      queryError: null,
      columns: null,
      loadingResults: false,
      params: [],
    };
  },
};
</script>

<style scoped>

</style>
