<template>
  <v-card>
    <v-card-title>Parametri</v-card-title>
    <v-card-text>
      <v-row v-for="(param, idx) in params" :key="`ds-p${idx}`">
        <v-col md="12" v-if="param.name">
            <v-select
            :label="param.displayAs"
            v-if="param.type === 'query_expression'"
            :items="param.allowedValues"
            item-text="displayAs"
            v-model="value[param.name]"
            item-value="expression"/>
          <v-text-field
            v-else-if="param.type === 'text'"
            v-model="value[param.name]"
            :label="param.displayAs"/>
          <v-menu
            v-else-if="param.type === 'date'"
            v-model="param.dialogOpen"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="value[param.name]"
                :label="param.name"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"/>
            </template>
            <v-date-picker
              v-model="value[param.name]"
              @input="param.dialogOpen = false"/>
          </v-menu>
          <v-menu
            v-else
            v-model="param.dialogOpen"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="multiDate[param.name]"
                :label="param.name"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"/>
            </template>
            <v-date-picker
              range
              v-model="multiDate[param.name]"
              @input="
                param.open = false;
                value[param.name] = arrayToAnd(multiDate[param.name])
              "/>
          </v-menu>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn small color="primary" @click="$emit('ok')">
        Ok
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'DatasetParamsValues',
  props: {
    value: {
      type: Object,
      required: true,
    },
    params: {
      type: Array,
      required: true,
    },
  },
  methods: {
    arrayToAnd(x) {
      if (!x[0]) return 'err';
      let string = "DATE('";
      string += x[0];
      string += "') AND DATE('";
      if (x[1]) string += x[1];
      else string += x[0];
      string += "')";
      return string;
    },
  },
  data() {
    return {
      multiDate: {},
    };
  },
};
</script>

<style scoped>

</style>
