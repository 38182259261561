<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs}">
      <v-btn color="primary"
             v-bind="attrs"
             v-on="on"
             @click="dialog = true">
        Aggiungi Utente
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span>Aggiungi Utente</span>
        <v-spacer/>
        <v-btn icon @click="dialog = false">
          <v-icon class="mdi mdi-close"/>
        </v-btn>
      </v-card-title>
      <v-card-text>
            <v-form ref="form" lazy-validation v-model="valid">
              <v-text-field
                type="text"
                v-model="userModel.userName"
                label="Username"
                required
                :rules="formRules.userNameRules"
              />
              <v-text-field
                type="text"
                v-model="userModel.firstName"
                label="Nome"
                required
                :rules="formRules.firstNameRules"
              />
              <v-text-field
                type="text"
                v-model="userModel.lastName"
                label="Cognome"
                hint="Cognome dell'utente"
                required
                :rules="formRules.lastNameRules"
              />
              <v-text-field
                type="text"
                v-model="userModel.email"
                label="Email"
                required
                :rules="formRules.emailRules"
              />
            </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary"
               :disabled="!valid"
               @click="saveUser">
          <span>Crea Utente</span>
          <v-progress-circular indeterminate v-if="creatingUser"/>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '@/api';

const userModel = {
  userName: '',
  lastName: '',
  firstName: '',
  email: '',
};

export default {
  name: 'UserCreator',
  methods: {
    resetForm() {
      this.userModel = { ...userModel };
      this.$refs.form.reset();
    },
    async saveUser() {
      if (!this.$refs.form.validate() || this.creatingUser) {
        return;
      }
      this.creatingUser = true;
      const user = await api.admin.user.create(this.userModel);
      this.creatingUser = false;
      this.dialog = false;
      this.tab = 0;
      this.resetForm();
      this.$emit('created', user);
    },
  },
  data() {
    // eslint-disable-next-line no-useless-escape
    const emailRegex = /^[^@\s]+@[^@\s]+\.[^@\s]{2,}$/;
    const userNameRegex = /^[\w-]+$/;
    return {
      formRules: {
        userNameRules: [
          (v) => !!v || 'Inserire Username',
          (v) => userNameRegex.test(v || '') || 'Username non valido',
        ],
        firstNameRules: [
          (v) => !!v || 'Inserire Nome',
        ],
        lastNameRules: [
          (v) => !!v || 'Inserire Cognome',
        ],
        emailRules: [
          (v) => !!v || 'Inserire email',
          (v) => emailRegex.test(v || '') || 'Indirizzo email non valido',
        ],
      },
      valid: false,
      dialog: false,
      tab: 'existing',
      creatingUser: false,
      loading: false,
      userModel: { ...userModel },
      selectedUser: null,
    };
  },
};
</script>
