<template>
    <v-card-text class="pt-3">
      <v-progress-linear v-if="loadingData" indeterminate />
      <v-autocomplete
        :disabled="loadingData"
        label="Seleziona Tabella"
        :items="schemaTables"
        item-text="name"
        v-model="table"
        hide-details="auto"
        @change="tableSelected()"
      />
      <v-autocomplete
        :disabled="table == null"
        label="Seleziona Colonne"
        :items="columns"
        item-text="name"
        v-model="selectedColumns"
        multiple
        class="my-2"
        hide-details="auto"
        @change="fieldsSelect()"
      />
      <v-row class="ma-0" v-if="showTest">
        <v-col cols=9>
          <span v-if="queryChanged && resultValues == null" class="text-subtitle-1">
            Senza risultati
          </span>
          <span v-else-if="queryChanged" class="text-subtitle-1">
            Risultati obsoleti
          </span>
        </v-col>
        <v-col cols=3>
          <v-btn :loading="loadingResults" color="primary" @click="testQuery">
            <v-icon class="mdi mdi-database-search"/>
            TEST
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        dense
        v-if="resultValues"
        :headers="resultHeaders"
        :items="resultValues"
        :loading="loadingResults"
      />
    </v-card-text>
</template>

<script>
import api from '@/api';

export default {
  name: 'DatasetEditDialog',
  components: { },
  props: {
    dataset: {
      type: Object,
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
    widget: {
      type: Object,
      required: false,
    },
    showTest: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  mounted() {
    this.loadData();
  },
  watch: {
    async dataset(newVal, oldVal) {
      if (newVal.id !== oldVal.id) {
        this.localDataset = { ...newVal, widgetId: this.widget?.id };
        this.loadingData = true;
        await this.parseQuery();
        this.loadingData = false;
      }
    },
  },
  methods: {
    async loadData() {
      this.loadingData = true;
      await this.loadSchemaTables();
      await this.parseQuery();
      this.loadingData = false;
    },
    async loadSchemaTables() {
      this.schemaTables = await api.graphic.databaseSchema.listTables(this.schema.id);
    },
    async parseQuery() {
      if (this.localDataset.query === '') return;
      const queryString = this.localDataset.query.split(' ');
      let scanIndex = -1;
      let currentScan = '';

      queryString.forEach((word, index) => {
        console.log(word);
        if (['SELECT', 'FROM'].includes(word.toUpperCase())) {
          scanIndex = index;
          currentScan = word.toUpperCase();
        }

        if (currentScan === 'SELECT' && index === scanIndex + 1) {
          if (word === '*') this.selectedColumns = [];
          else this.selectedColumns = word.split(',');
        } else if (currentScan === 'FROM' && index === scanIndex + 1) {
          this.table = word;
          this.columns = this.schemaTables.find((x) => (x.name === this.table)).columns;
        }
      });

      this.emitColumnsChange();
    },
    tableSelected() {
      this.columns = this.schemaTables.find((x) => (x.name === this.table)).columns;
      this.selectedColumns = [];
      this.fieldsSelect();
    },
    fieldsSelect() {
      let query = '';
      query += 'SELECT ';
      if (this.selectedColumns.length === 0) {
        query += '*';
      } else {
        query += this.selectedColumns.join(',');
      }
      query += ' FROM ';
      query += this.table;
      this.localDataset.query = query;
      this.queryChanged = true;
      this.$emit('modified', this.localDataset, this.selectedColumns);
      this.emitColumnsChange();
    },
    emitColumnsChange() {
      const selColumns = this.columns.filter(
        (col) => this.selectedColumns.includes(col.name),
      );
      this.$emit('updatedColumns', selColumns);
    },
    async testQuery() {
      if (this.loadingResults) {
        return;
      }
      this.queryChanged = false;
      this.queryFailed = false;
      this.queryError = null;
      this.loadingResults = true;
      const data = await api.graphic.dataset.test(this.localDataset, this.paramState);
      if (data.failed) {
        this.queryFailed = true;
        this.queryError = data.error;
        this.resultValues = null;
        this.resultHeaders = null;
        this.$emit('metaRefresh', null);
      } else {
        this.resultHeaders = data.metas.columns.map((col) => ({
          text: `${col.name} (${col.type})`,
          value: col.name,
        }));
        this.resultValues = data.values;
        this.$emit('metaRefresh', data.metas);
      }
      this.loadingResults = false;
    },
  },
  data() {
    return {
      schemaTables: [],
      table: null,
      columns: [],
      selectedColumns: [],
      loadingData: false,
      loadingResults: false,
      queryChanged: true,
      localDataset: { ...this.dataset, widgetId: this.widget?.id },
      resultValues: null,
    };
  },
};
</script>

<style lang="scss">
</style>
