<template>
  <div class="my-2">
    <v-row class="ml-4">
      <v-switch v-model="active" @change="parseQuery" label="Creare Tabella"/>
    </v-row>
    <div v-if="active">
      <v-row>
        <v-col cols=8>
          <v-text-field
            v-model="tableName"
            label="Nome della Tabella"
            required
            @change="updateCreate"
            hide-details="auto"
            :rules="[sqlFormat]"
          />
        </v-col>
      </v-row>
      <v-row class="ml-4">
        Fields:
      </v-row>
      <v-row v-for="field, i in fields" :key="i" class="align-center">
        <v-col :cols="reqSize(field.type) ? 5 : 6">
          <v-text-field
            v-model="field.name"
            label="Nome"
            required
            @change="updateCreate"
            hide-details="auto"
            :rules="[sqlFormat]"
          />
        </v-col>
        <v-col :cols="reqSize(field.type) ? 4 : 5">
          <v-autocomplete
            v-model="field.type"
            :items="dataTypes"
            required
            hide-details="true"
            label="Tipo"/>
        </v-col>
        <v-col cols=2 v-if="reqSize(field.type)">
          <v-text-field
            v-model="field.size"
            label="Size"
            required
            @change="updateCreate"
            type="number"
            hide-details="auto"
          />
        </v-col>
        <v-col cols=1>
          <v-btn icon @click="removeField(i)">
            <v-icon color="error">mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="justify-center" v-if="active">
        <v-btn @click="addField">
          <v-icon large color="success">mdi-plus</v-icon>
          Aggiungi campo
        </v-btn>
      </v-row>
    </div>
  </div>
</template>

<script>
const dataTypes = [
  'CHAR',
  'VARCHAR',
  'BINARY',
  'VARBINARY',
  'TINYBLOB',
  'TINYTEXT',
  'TEXT',
  'BLOB',
  'MEDIUMTEXT',
  'MEDIUMBLOB',
  'LONGTEXT',
  'LONGBLOB',
  'ENUM',
  'SET',
  'BIT',
  'TINYINT',
  'BOOL',
  'BOOLEAN',
  'SMALLINT',
  'MEDIUMINT',
  'INT',
  'INTEGER',
  'BIGINT',
  'FLOAT',
  'DOUBLE',
  'DECIMAL',
  'DEC',
  'DATE',
  'DATETIME',
  'TIMESTAMP',
  'TIME',
  'YEAR',
];

const requireSizeTypes = [
  'CHAR',
  'VARCHAR',
  'BINARY',
  'VARBINARY',
  'TEXT',
  'BLOB',
  'BIT',
  'TINYINT',
  'SMALLINT',
  'MEDIUMINT',
  'INTEGER',
  'BIGINT',
  'FLOAT',
  'DOUBLE',
  'DECIMAL',
];

export default {
  name: 'DatasetTableCreator',
  components: { },
  props: {
    query: {
      type: String,
      required: false,
    },
  },
  methods: {
    updateCreate() {
      const tableData = {
        active: this.active,
        tableName: this.tableName,
        fields: this.fields,
      };
      this.$emit('update', tableData);
    },
    parseQuery() {
      // Scan for the table name
      const parsedQuery = this.query.toLowerCase().split(' ');
      if (parsedQuery.length < 1) return;
      const fromIndex = parsedQuery.findIndex((x) => x === 'from');
      const tableName = (parsedQuery[fromIndex + 1] || '').replace(/;/g, '');

      // Scan the parameters
      const selectPart = this.query.toLowerCase().split('select')[1].split('from')[0].split(',');
      const fields = [];
      selectPart.forEach((part) => {
        fields.push(part.trim().split(' ')[0]);
      });

      if (fields.length !== 1 || fields[0] !== '*') {
        this.tableName = tableName;
        this.fields = fields.map((f) => ({ name: f, type: null }));
      }
    },
    reqSize(dataType) {
      return this.requireSizeTypes.includes(dataType);
    },
    addField() {
      this.fields.push({});
      this.updateCreate();
    },
    removeField(index) {
      this.fields.splice(index, 1);
      this.updateCreate();
    },
  },
  data() {
    return {
      sqlFormat: (value) => {
        const pattern = /^\S*$/;
        return pattern.test(value) || 'Invalid name.';
      },
      tableName: '',
      active: false,
      fields: [
        {
          name: 'date',
          type: 'DATE',
        },
      ],
      dataTypes,
      requireSizeTypes,
    };
  },
};
</script>

<style scoped>

</style>
