<template>
  <v-layout class="pa-5" row wrap>
      <v-col class="pa-2 col-md-4"
        v-for="dashboard in dashboards"
        :key="dashboard.id"
      >
        <v-card class="pa-3 cardBackgroundColor" elevation="0">
          <v-card-title>
            <v-icon class="mdi" :class="dashboard.icon"/>
            <span class="mx-2 px-2">{{dashboard.displayName}}</span>
            <v-spacer />
            <v-btn icon @click="goTo(dashboard)" >
              <v-icon class="mdi mdi-arrow-right"/>
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
  </v-layout>
</template>

<script>
export default {
  name: 'HomeView',
  mounted() {
    this.dashboards = this.$store.state.dashboards.sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });
  },
  methods: {
    goTo(dashboard) {
      window.parent.postMessage({
        data: {
          code: 'hide',
          test: 'HideCustomButton',
        },
      }, '*');
      this.$router.push({ name: dashboard.displayName });
    },
  },
  data() {
    return {
      dashboards: this.$store.state.dashboards,
    };
  },
};
</script>

<style scoped>

</style>
