<template>
  <v-dialog v-model="dialog" width="700">
    <template v-slot:activator="{on, attrs}">
      <v-btn small rounded
             color="primary"
             v-bind="attrs"
             v-on="on">
        Filtri Globali
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        Modifica quali filtri globali bisogna mostrare
      </v-card-title>
      <v-card-text>
        <v-checkbox
          v-for="param in params"
          :key="param.name"
          v-model="param.isGlobal"
          :label="param.name + ' - Query Parameter (' + param.type + ')'"
          @change="handleCheckbox(param)"
        />
        <v-checkbox
          v-for="filter in filters"
          :key="filter.label"
          v-model="filter.isGlobal"
          :label="filter.label + ' - Filter'"
          @change="handleCheckbox(filter)"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import store from '@/store';
import api from '@/api';

export default {
  name: 'WidgetEditGlobalFilters',
  props: {
    dashboard: {
      type: Object,
      required: true,
    },
  },
  watch: {
    dialog: {
      deep: true,
      handler() {
        if (this.remainingSave === 0 && this.dialog === false) this.$emit('close');
      },
    },
  },
  computed: {
    filters() {
      return store.state.globalFilters;
    },
    params() {
      return store.state.globalParams;
    },
  },
  methods: {
    async handleCheckbox() {
      this.remainingSave += 1;
      try {
        store.commit('setGlobalFilters', this.filters);
        store.commit('setGlobalParams', this.params);
        this.dashboard.globalFilters = JSON.stringify(this.filters.filter((x) => x.isGlobal)
          .map((y) => y.label));
        this.dashboard.globalParams = JSON.stringify(this.params.filter((x) => x.isGlobal)
          .map((y) => y.name));
        const result = await api.graphic.dashboard.update(this.dashboard.id, this.dashboard);
        this.remainingSave -= 1;
        if (this.remainingSave === 0 && this.dialog === false) this.$emit('close');
        return result;
      } catch (e) {
        this.dialog = true;
        this.error = ['Cannot save global filters', '(Unexpected Error Occurred)'];
      }
      return this.error;
    },
  },
  data() {
    return {
      dialog: false,
      tab: 0,
      remainingSave: 0,
    };
  },
};
</script>

<style scoped>

</style>
