<template>
  <v-form v-if="metas" ref="form">
    <span class="mx-2"> Colonne modificabili </span>
    <!-- Editable Fields -->
    <v-col>
      <v-row v-for="(item, i) in finalEditableColumns" :key="`value-cb-${i}`">
        <v-col cols=6 class="ma-0 py-1 px-2 align-center overflow-hidden">
          <v-checkbox
            dense
            hide-details="auto"
            v-model="item.editable"
            :label="`${item.name} (${item.type})`"
            @change="toggleEditable(item)"
          />
        </v-col>
        <v-col cols=6 class="ma-0 py-1 px-2 align-center">
          <v-text-field
            outlined
            dense
            hide-details="auto"
            v-model="item.realName"
            @blur="toggleEditable(item)"
            label="Nome in Tabella"
          />
        </v-col>
        <v-col cols=6 class="ma-0 py-1 px-2 align-center">
          <v-text-field
            outlined
            dense
            hide-details="auto"
            v-model="item.options"
            @blur="toggleEditable(item)"
            label="Opzioni"
          />
        </v-col>
        <v-col cols=6 class="ma-0 py-1 px-2 align-center">
          <v-text-field
            outlined
            dense
            hide-details="auto"
            v-model="item.tableName"
            @blur="toggleEditable(item)"
            label="Tabella"
          />
        </v-col>
        <v-col
          :cols="6 + item.editingFunc - item.editingJoin"
          class="ma-0 py-1 px-2 align-center d-flex"
        >
          <v-text-field
            outlined
            dense
            hide-details="auto"
            v-model="item.function"
            @focus="item.editingFunc = 10"
            @blur="toggleEditable(item); item.editingFunc = 6"
            label="Function"
          />
          <v-tooltip bottom v-if="item.editingFunc == 10">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle
              </v-icon>
            </template>
            <span> Replace name with <code>'@'</code></span>
            <br>
            <code>function(@, args)</code>
          </v-tooltip>
        </v-col>
        <v-col
          v-if="item.tableName !== defaultTable"
          :cols="6 + item.editingJoin - item.editingFunc"
          class="ma-0 py-1 px-2 align-center d-flex">
          <v-text-field
            outlined
            dense
            hide-details="auto"
            v-model="item.joinParams"
            @focus="item.editingJoin = 11"
            @blur="alignJoinFields(item); item.editingJoin = 6"
            label="Join Fields"
          />
          <v-tooltip bottom v-if="item.editingJoin == 11">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle
              </v-icon>
            </template>
            <span> Format: </span>
            <br>
            <code>nameInForeignTable=realNameInMainTable:AliasInMainTable;</code>
          </v-tooltip>
        </v-col>
        <v-col cols=12 class="mx-1 my-1 pa-0">
          <v-divider/>
        </v-col>
      </v-row>
    </v-col>
    <!-- Filter Configuration -->
    <v-row class="mt-2">
      <v-col cols="4">
        <v-switch
          v-model="filtered"
          @change="updateValue"
          label="Mostra Filtri"/>
      </v-col>
      <v-col cols="8" v-if="filtered">
        <!-- TODO change this from combobox to something else -->
        <v-combobox
          dense
          v-model="value.filterColumns"
          :items="metas.columns"
          item-text="name"
          @change="updateValue"
          label="Colonne filtrabili"
          multiple
          small-chips
        />
      </v-col>
    </v-row>
    <v-row>
      <v-spacer/>
      <v-btn class="rounded-pill primary font-weight-bold white--text py-1 px-2 mb-1 mr-3"
        @click="saveDefaults">
        Salva la selezione come Default
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: 'EditableTableConfig',
  props: {
    value: {
      type: Object,
      required: true,
    },
    dataset: {
      type: Object,
      required: true,
    },
    metas: {
      type: Object,
      required: false,
    },
    editableCols: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  watch: {
    metas: {
      deep: true,
      handler() {
        if (this.metas !== null && !this.loadedMetas) {
          // Load the widget config from the DB
          const pattern = /(?<=from|join)(\s+\w+\b)/i;
          this.defaultTable = this.dataset.query.match(pattern)[0].trim();
          (this.metas.columns || []).forEach((x) => {
            const fromMeta = this.editableCols.filter((y) => y.name === x.name)[0];
            this.finalEditableColumns.push({
              name: x.name,
              realName: fromMeta ? fromMeta.realName : x.name,
              function: fromMeta ? fromMeta.function : '',
              type: x.type,
              // eslint-disable-next-line no-nested-ternary
              editable: fromMeta ? (!!fromMeta.editable) : false,
              options: fromMeta ? fromMeta.options : '',
              tableName: fromMeta ? fromMeta.tableName : this.defaultTable,
              joinParams: fromMeta ? fromMeta.joinParams : '',
              // For This Component Visuals Only
              editingJoin: 6,
              editingFunc: 6,
            });
          });
          this.loadedMetas = true;
        }
      },
    },
    value: {
      deep: true,
      handler() {
        this.valuesCols = this.value.values;
      },
    },
  },
  mounted() {
    if (this.metas !== null && !this.loadedMetas) {
      // Load the widget config from the DB
      const pattern = /(?<=from|join)(\s+\w+\b)/i;
      this.defaultTable = this.dataset.query.match(pattern)[0].trim();
      (this.metas.columns || []).forEach((x) => {
        const fromMeta = this.editableCols.filter((y) => y.name === x.name)[0];
        this.finalEditableColumns.push({
          name: x.name,
          realName: fromMeta ? fromMeta.realName : x.name,
          function: fromMeta ? fromMeta.function : '',
          type: x.type,
          editable: fromMeta ? (!!fromMeta.editable) : false,
          options: fromMeta ? fromMeta.options : '',
          tableName: fromMeta ? fromMeta.tableName : this.defaultTable,
          joinParams: fromMeta ? fromMeta.joinParams : '',
          // For This Component Visuals Only
          editingJoin: 6,
          editingFunc: 6,
        });
      });
      this.loadedMetas = true;
      this.updateOldModelWidgets();
    }
  },
  methods: {
    // Unused, resets config to empty values
    reset() {
      this.$refs.form.reset();
    },
    // Sync the JOIN options of a edited column
    alignJoinFields(item) {
      this.finalEditableColumns.forEach((col) => {
        if (col.editable && col.tableName === item.tableName) {
          col.joinParams = item.joinParams;
        }
      });
      this.toggleEditable(item);
    },
    updateValue() {
      this.value.filtered = this.filtered;
      this.$emit('input', this.value);
    },
    // Mantain the compatibility with the old data model editable table widgets in DB
    updateOldModelWidgets() {
      this.$emit('saveMetaColumns', this.finalEditableColumns);
    },
    // Send editable columns config to parent component
    toggleEditable() {
      this.$emit('saveMetaColumns', this.finalEditableColumns);
      this.alignJoinFields();
    },
    // Set the current in-widget selected filter values as its default values
    saveDefaults() {
      this.toggleEditable();
      this.$emit('saveDef');
    },
  },
  data() {
    return {
      defaultTable: '',
      filtered: this.value.filtered,
      finalEditableColumns: [],
      loadedMetas: false,
      valuesCols: this.value.values,
    };
  },
};
</script>

<style scoped>

</style>
