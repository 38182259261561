const info = async (dashboardId) => {
  const response = await window.axios.get(`/client/dashboard/${dashboardId}`);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const listWidgets = async (dashboardId) => {
  const response = await window.axios.get(`/client/dashboard/${dashboardId}/widget`);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const listCategories = async (dashboardId) => {
  const response = await window.axios.get(`/client/dashboard/${dashboardId}/category`);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

export default {
  info,
  widget: {
    list: listWidgets,
  },
  category: {
    list: listCategories,
  },
};
