<!-- eslint-disable max-len, prefer-template -->
<template>
  <v-card>
    <!-- Header -->
    <v-row
      :style="headerStyle"
      class="flex justify-space-between ma-0 align-center"
    >
      <v-img
        v-if="logo != null"
        contain
        max-width="240"
        height="62"
        :src="logo"
      ></v-img>
      <div v-else>
        Logo
      </div>
      <v-icon x-large color="white" class="mx-4">mdi-account-circle</v-icon>
    </v-row>
    <!-- Middle -->
    <v-row class="flex ma-0">
      <!-- NavBar -->
      <div
        :style="'width:50px; background-color: ' + (theme.navbarColor || theme.secondaryColor)"
      >
        <v-row class="ma-0 justify-center py-4"
          :style="'background-color: '+ (theme.navbarActiveColor || theme.primaryColor) + '80'">
          <v-icon :color="theme.navbarActiveColor || theme.primaryColor">mdi-monitor-dashboard</v-icon>
        </v-row>
        <v-row class="ma-0 justify-center py-4">
          <v-icon :color="theme.iconColor">mdi-account</v-icon>
        </v-row>
        <v-row class="ma-0 justify-center py-4">
          <v-icon :color="theme.iconColor">mdi-compare</v-icon>
        </v-row>
      </div>
      <!-- Widget Area -->
      <v-col :style="'background-color: '+ ( theme.backgroundColor || 'white')">
        <v-row class="flex justify-end">
          <v-btn
            :color="theme.primaryColor"
            class="flex-nowrap rounded-pill font-weight-bold
              subtitle-2 white--text pl-4 pr-2 pt-1 my-1"
          >
            ESPORTA
            <v-icon color="white" class="mdi mdi-download" medium></v-icon>
          </v-btn>
        </v-row>
        <v-row>
          <v-card class="my-2 mx-4 pa-2" :style="'background-color: ' + (theme.cardBackgroundColor || 'white')" style="width: 35%">
            <v-row class="flex ma-2">
              <span class="">Widget Title</span>
              <v-spacer/>
              <v-icon color="gray">mdi-eye-off-outline</v-icon>
              <v-icon :color="theme.secondaryColor">mdi-content-copy</v-icon>
              <v-icon :color="theme.primaryColor">mdi-download</v-icon>
              <v-icon :color="theme.primaryColor">mdi-delete</v-icon>
              <v-icon :color="theme.primaryColor">mdi-pencil</v-icon>
            </v-row>
            <BarChart
              :height="200"
              :chart-data="colorDemo"
              :options="chartOptions"
              :renderer="reloader"
            />
          </v-card>
          <v-card class="my-2 mx-4 pa-2" :style="'background-color: ' + (theme.cardBackgroundColor || 'white')" style="width: 35%">
            <v-row class="flex ma-2">
              <span class="">Widget Title</span>
              <v-spacer/>
              <v-icon color="gray">mdi-eye-off-outline</v-icon>
              <v-icon :color="theme.secondaryColor">mdi-content-copy</v-icon>
              <v-icon :color="theme.primaryColor">mdi-download</v-icon>
              <v-icon :color="theme.primaryColor">mdi-delete</v-icon>
              <v-icon :color="theme.primaryColor">mdi-pencil</v-icon>
            </v-row>
            <LineChart
              :height="200"
              :chart-data="colorDemo"
              :options="chartOptions"
              :renderer="reloader"
            />
          </v-card>
        </v-row>
      </v-col>
    </v-row>
    <!-- Footer -->
    <v-row
      :style="'height: 50px; background-color: ' + (theme.footerColor || '#F5F5F5')"
      class="flex justify-end ma-0 align-center"
    >
      <span class="footer-text mx-4 text-subtitle-2">© 2022 - Powered by Premoneo</span>
    </v-row>
  </v-card>
</template>

<script>
import BarChart from '@/admin/components/main/DashboardEditor/widgets/bar_chart/BarChart.vue';
import LineChart from '@/admin/components/main/DashboardEditor/widgets/line_chart/LineChart.vue';
import colors from '@/tools/colors';

const chartDummyData = {
  datasets: [{
    backgroundColor: '#35d1ad',
    borderColor: '#35d1ad',
    data: [40, 41, 10, 15, 15, 5, 27, 5, 13, 6, 13, 8, 5],
    label: 'data-1',
    fill: false,
  },
  {
    backgroundColor: '#062b5e',
    borderColor: '#062b5e',
    data: [20, 21, 5, 7, 7, 2, 14, 2, 6, 3, 7, 4, 2],
    label: 'data-2',
    fill: false,
  }],
  labels: ['02-01', '02-02', '02-06', '02-08', '02-09', '02-12', '02-13', '02-15', '02-16', '02-19', '02-21', '02-22', '02-26'],
};

const chartOptions = {
  legend: { position: 'bottom' },
};

export default {
  name: 'ThemePreview',
  components: { BarChart, LineChart },
  props: {
    theme: {
      type: Object,
      required: true,
    },
    logo: {
      required: true,
    },
  },
  computed: {
    headerStyle() {
      if (this.theme.bannerColorR) {
        if (this.theme.bannerColorL) {
          // eslint-disable-next-line prefer-template
          return 'height: 64px; background-image: linear-gradient(to right, ' + this.theme.bannerColorL + ',' + this.theme.bannerColorR + ')';
        }
        // eslint-disable-next-line prefer-template
        return 'height: 64px; background-image: linear-gradient(to right, ' + this.theme.bannerColorR + ',' + this.theme.bannerColorR + ')';
      }
      if (this.theme.bannerColorL) {
        // eslint-disable-next-line prefer-template
        return 'height: 64px; background-image: linear-gradient(to right, ' + this.theme.bannerColorL + ',' + this.theme.bannerColorL + ')';
      }
      // eslint-disable-next-line prefer-template
      return 'height: 64px; background-image: linear-gradient(to right, ' + this.theme.secondaryColor + ',' + this.theme.primaryColor + ')';
    },
    colorDemo() {
      let usableColor = [];
      if (JSON.parse(this.theme.chartColors || '[]').length > 0) {
        usableColor = JSON.parse(this.theme.chartColors);
      }
      let cc = 0;
      while (usableColor.length < chartDummyData.datasets.length) {
        usableColor.push(colors.getColor(cc));
        cc += 1;
      }
      for (let i = 0; i < chartDummyData.datasets.length; i += 1) {
        chartDummyData.datasets[i].backgroundColor = usableColor[i];
        chartDummyData.datasets[i].borderColor = usableColor[i];
      }
      this.reload(usableColor);
      return chartDummyData;
    },
  },
  methods: {
    reload(usableColor) {
      if (this.colorCache !== usableColor) {
        this.colorCache = JSON.parse(JSON.stringify(usableColor));
        this.reloader = !this.reloader;
      }
    },
  },
  data() {
    return {
      chartOptions,
      colorCache: [],
    };
  },
};
</script>

<style>

</style>
