<template>
  <v-form v-if="metas">
    <!--Color Config -->
    <v-row>
      <v-col>
        Abilita colori
        <div v-for="(item, i) in finalColouredColumns" :key="`value-cb-${i}`">
          <v-checkbox v-if="isNumeric(item)"
            v-model="item.coloured"
            :label="`${item.name} (${item.type})`"
            @change="toggleColoured(item)"/>
        </div>
      </v-col>
    </v-row>
    <!--Filter Config -->
    <v-row>
      <v-col cols="4">
        <v-switch
          v-model="value.filtered"
          @change="updateValue"
          label="Mostra Filtri"/>
      </v-col>
      <v-col cols="8" v-if="value.filtered">
        <v-combobox
          dense
          v-model="value.filterColumns"
          item-text="name"
          item-value="name"
          @change="updateValue"
          :items="metas.columns"
          label="Colonne filtrabili"
          multiple
          small-chips
        />
      </v-col>
    </v-row>
    <v-row>
      <v-spacer/>
      <v-btn class="rounded-pill primary font-weight-bold white--text py-1 px-2 mb-1 mr-3"
        @click="saveDefaults">
        Salva la selezione come Default
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import dataTypes from '@/tools/dataTypes';

export default {
  name: 'OldTableWidgetConfig',
  props: {
    colouredCols: {
      type: Array,
      required: false,
      default: () => [],
    },
    dataset: {
      type: Object,
      required: true,
    },
    metas: {
      type: Object,
      required: false,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.valuesCols = this.value.values;
      },
    },
  },
  mounted() {
    // Load colored columns
    this.metas.columns.forEach((x) => {
      this.finalColouredColumns.push({
        name: x.name,
        type: x.type,
        coloured: this.colouredCols.includes(x.name),
      });
    });
  },
  computed: {
    // Returns the list of columns that have numeric values
    getNumericColumns() {
      const filtered = this.metas.columns.filter((item) => this.isNumeric(item));
      return filtered.map((x) => x.name);
    },
  },
  methods: {
    isNumeric: dataTypes.isNumeric,
    // Unused, resets config to empty values
    reset() {
      this.$refs.form.reset();
    },
    updateValue() {
      this.$emit('input', this.value);
    },
    // Sync the colored columns values
    toggleColoured() {
      this.$emit('saveMetaColumns', this.finalColouredColumns);
    },
    // Set the current in-widget selected filter values as its default values
    saveDefaults() {
      this.$emit('saveDef');
    },
  },
  data() {
    return {
      finalColouredColumns: [],
      multiValue: this.value.multiValue,
      numericCols: [],
      valuesCols: this.value.values,
    };
  },
};
</script>

<style scoped>

</style>
