import dashboard from '@/api/graphic/dashboard';
import databaseSchema from '@/api/graphic/databaseSchema';
import user from '@/api/graphic/user';
import widget from '@/api/graphic/widget';
import theme from '@/api/graphic/theme';
import dataset from '@/api/graphic/dataset';

export default {
  dashboard,
  databaseSchema,
  user,
  theme,
  widget,
  dataset,
};
