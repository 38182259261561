<template>
  <v-row class="pa-0 ma-0">
    <v-col
      v-for="filter in filters.filter((f) => f.isGlobal)"
      :key="filter.label"
      class="d-flex align-center"
    >
      <v-autocomplete
        v-if="!filter.isDate"
        :label="filter.label"
        :items="filter.items"
        :search-input.sync="filter.search"
        v-model="filter.selected"
        hide-details="auto"
        multiple
        class="mt-0 pt-0"
        @change="applyFilter(filter)"
        @focus="filter.focus = true"
        @blur="filter.focus = false; filter.search=''"
      >
        <!-- Visibles Group Selector -->
        <template v-slot:prepend-inner>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-show="filter.search != '' && filter.search != null && filter.focus"
                small
                icon
                class="ml-1"
                v-on="on"
                v-bind="attrs"
                @click="selectAllVisible(filter)"
              >
                <v-icon color="primary">mdi-checkbox-marked</v-icon>
              </v-btn>
            </template>
            <span>Seleziona tutti</span>
          </v-tooltip>
        </template>
        <!-- Selected Counter -->
        <template v-slot:selection="{ item, index }">
          <span v-if="index == 0">
            {{item}}
          </span>
          <v-chip label x-small v-else-if="index == 1">
            <span style="font-size: .95rem">+{{filter.selected.length - 1}}</span>
          </v-chip>
        </template>
      </v-autocomplete>
      <!-- Select / Deselect All Button -->
      <v-btn icon small @click="toggleAll(filter)">
        <v-icon
          size="24"
          v-if="filter.selected.length == 0"
          color="secondary"
          class="mdi mdi-checkbox-blank-outline"
        />
        <v-icon
          size="24"
          v-else-if="filter.selected.length < filter.items.length"
          color="secondary"
          class="mdi mdi-checkbox-intermediate"
        />
        <v-icon
          size="24"
          v-else-if="filter.selected.length === filter.items.length "
          color="secondary"
          class="mdi mdi-checkbox-marked"
        />
      </v-btn>
      <!-- Date Global Selector -->
      <v-menu
        v-if="filter.isDate"
        :close-on-content-click="false"
        transition="scale-transition"
        min-width="auto"
      >
        <template v-slot:activator="{ on }" >
          <v-text-field
            dense
            v-model="filter.selected"
            class="text-body-2"
            :label="filter.label"
            prepend-icon="mdi-calendar"
            readonly
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="filter.selected"
          scrollable
          locale="it-it"
          :allowed-dates="(val) => filter.items.indexOf(val) !== -1"
          @input="applyFilter(filter)"
        />
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
// import moment from 'moment';

export default {
  name: 'GlobalFilters',
  props: {
    visibleFilters: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.$root.$on('pushGlobalFilters', this.loadGlobalFilters);
    this.$root.$on('cleanGlobalFilters', this.cleanGlobalFilters);
  },
  methods: {
    isGlobal(filter) {
      return this.visibleFilters.includes(filter);
    },
    isDate() {
      return false;
    },
    // isDate(filter) {
    //   FIXME date pattern recognizer
    //   return moment(filter[1][0], 'YYYY-MM-DD').isValid();
    // },
    cleanGlobalFilters() {
      // eslint-disable-next-line no-param-reassign
      this.filters.forEach((f) => { f.selected = []; });
    },
    loadGlobalFilters(globalFilters) {
      if (Object.entries(globalFilters).length > 0) {
      // eslint-disable-next-line no-param-reassign
        this.filters.forEach((f) => { f.items = []; });
        Object.entries(globalFilters).forEach((value) => {
          const filterFound = this.filters.find((filter) => filter.label === value[0]);
          if (!filterFound) {
            this.filters.push({
              label: value[0],
              items: value[1],
              isGlobal: this.isGlobal(value[0]),
              isDate: this.isDate(value),
              focus: false,
              search: '',
              selected: [],
            });
          } else {
            value[1].forEach((x) => {
              if (!filterFound.items.includes(x)) {
                filterFound.items.push(x);
              }
            });
          }
        });
      }
      this.$store.commit('setGlobalFilters', this.filters);
    },
    toggleAll(filter) {
      // eslint-disable-next-line no-param-reassign
      if (filter.selected.length < filter.items.length) filter.selected = filter.items;
      // eslint-disable-next-line no-param-reassign
      else filter.selected = [];
    },
    selectAllVisible(filter) {
      filter.items.forEach((item) => {
        if (item.toUpperCase().includes(filter.search.toUpperCase())
          && !filter.selected.includes(item)) {
          filter.selected.push(item);
        }
      });
    },
    applyFilter(filter) {
      this.$root.$emit('updateFilterFromGlobal', {
        label: filter.label,
        selected: filter.selected,
      });
    },
    applyFilters() {
      this.$root.$emit('updateFilterFromGlobal', this.filters.map((filter) => ({
        label: filter.label,
        selected: filter.selected,
      })));
    },
  },
  data() {
    return {
      filters: [],
      selected: [],
      rangeDate: [],
    };
  },
};
</script>
