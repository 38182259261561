<template>
  <v-app-bar
    app
    clipped-left
    color="secondary"
    class="main-app-bar">
    <img
      :src="userLogo"
      class="pa-3"
      style="cursor: pointer;"
      height="62px"
      @click="redirectHome()"
    />
  </v-app-bar>
</template>

<script>

export default {
  name: 'AppBar',
  mounted() {
    // eslint-disable-next-line global-require
    this.userLogo = require('@/assets/vedrai-logo.png');
  },
  methods: {
    redirectHome() {
      this.$router.push('/');
    },
  },
  data() {
    return {
      userLogo: null,
    };
  },
};
</script>
