<template>
  <v-btn
    color="primary"
    @click="$emit('created')"
  >
    Aggiungi Tema
  </v-btn>
</template>

<script>

export default {
  name: 'ThemeCreator',
};
</script>
