var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("Parametri")]),_c('v-card-text',_vm._l((_vm.params),function(param,idx){return _c('v-row',{key:("ds-p" + idx)},[(param.name)?_c('v-col',{attrs:{"md":"12"}},[(param.type === 'query_expression')?_c('v-select',{attrs:{"label":param.displayAs,"items":param.allowedValues,"item-text":"displayAs","item-value":"expression"},model:{value:(_vm.value[param.name]),callback:function ($$v) {_vm.$set(_vm.value, param.name, $$v)},expression:"value[param.name]"}}):(param.type === 'text')?_c('v-text-field',{attrs:{"label":param.displayAs},model:{value:(_vm.value[param.name]),callback:function ($$v) {_vm.$set(_vm.value, param.name, $$v)},expression:"value[param.name]"}}):(param.type === 'date')?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":param.name,"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.value[param.name]),callback:function ($$v) {_vm.$set(_vm.value, param.name, $$v)},expression:"value[param.name]"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(param.dialogOpen),callback:function ($$v) {_vm.$set(param, "dialogOpen", $$v)},expression:"param.dialogOpen"}},[_c('v-date-picker',{on:{"input":function($event){param.dialogOpen = false}},model:{value:(_vm.value[param.name]),callback:function ($$v) {_vm.$set(_vm.value, param.name, $$v)},expression:"value[param.name]"}})],1):_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":param.name,"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.multiDate[param.name]),callback:function ($$v) {_vm.$set(_vm.multiDate, param.name, $$v)},expression:"multiDate[param.name]"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(param.dialogOpen),callback:function ($$v) {_vm.$set(param, "dialogOpen", $$v)},expression:"param.dialogOpen"}},[_c('v-date-picker',{attrs:{"range":""},on:{"input":function($event){param.open = false;
              _vm.value[param.name] = _vm.arrayToAnd(_vm.multiDate[param.name])}},model:{value:(_vm.multiDate[param.name]),callback:function ($$v) {_vm.$set(_vm.multiDate, param.name, $$v)},expression:"multiDate[param.name]"}})],1)],1):_vm._e()],1)}),1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.$emit('ok')}}},[_vm._v(" Ok ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }