const list = async () => {
  const response = await window.axios.get('/graphic/database_schema');
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const listTables = async (schemaId) => {
  const response = await window.axios.get(`/graphic/database_schema/${schemaId}/tables`);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

export default {
  list,
  listTables,
};
