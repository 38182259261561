const buildLayout = (widgets) => Object.keys(widgets)
  .map((id) => ({
    w: widgets[id].config.cols,
    h: widgets[id].config.height,
    x: widgets[id].config.x,
    y: widgets[id].config.y,
    i: id,
    widgetId: id,
    widget: widgets[id],
  }));

export default {
  buildLayout,
};
