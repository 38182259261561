<template>
  <v-app>
    <NavigationDrawer/>
    <AppBar/>
    <v-main>

      <v-container fluid class="pa-0">
        <router-view></router-view>
      </v-container>
    </v-main>
    <!-- <Footer /> -->
  </v-app>
</template>

<script>
import NavigationDrawer from '@/general/NavigationDrawer.vue';
import AppBar from '@/general/AppBar.vue';
// import Footer from '@/general/Footer.vue';

export default {
  name: 'AdminApp',
  components: { AppBar, NavigationDrawer },
};
</script>

<style lang="scss">
.v-application {
    font-family: $body-font-family, sans-serif !important;
    .title { // To pin point specific classes of some components
            font-family: $title-font, sans-serif !important;
    }
}
</style>
