import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    theme: null,
    session: null,
    dashboards: null,
    globalFilters: null,
    globalParams: null,
  },
  mutations: {
    auth(state, auth) {
      state.session = auth;
    },
    setUser(state, user) {
      state.user = user;
    },
    setDashboards(state, dashboards) {
      state.dashboards = dashboards;
    },
    setTheme(state, theme) {
      state.theme = theme;
    },
    setLogo(state, logo) {
      state.theme.logo = logo;
    },
    setGlobalFilters(state, globalFilters) {
      state.globalFilters = globalFilters;
    },
    setGlobalParams(state, globalParams) {
      state.globalParams = globalParams;
    },
  },
  actions: {
  },
  modules: {
  },
});
