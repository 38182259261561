<template>
  <v-card class="fill-height cardBackgroundColor" style="overflow-y: visible" v-if="!fetching">
    <v-card-title>
      {{widget.title}}
      <PieChartConfig v-if="dataset && widget.type == 'pie_chart'"
        v-model="widget.config.pie_chart"
        :dataset="dataset"
        :metas="queryResult.metas"
        :adminPanel="false"
      />
      <LineChartConfig v-if="dataset && widget.type == 'line_chart'"
        v-model="widget.config.line_chart"
        :dataset="dataset"
        :metas="queryResult.metas"
        :adminPanel="false"
      />
      <BarChartConfig v-if="dataset && widget.type == 'bar_chart'"
        v-model="widget.config.bar_chart"
        :dataset="dataset"
        :metas="queryResult.metas"
        :adminPanel="false"
      />
      <v-spacer/>
      <ExportData
        v-if="queryResult && widget"
        :title="widget.title"
        :query="printData"
        :onlyCSV="widget.type === 'table' || widget.type === 'editable_table'"
        @click="bsDisable=true"
        @close="bsDisable=false"
        @printPDF="printWidget"
      />
    </v-card-title>
    <v-card-text v-if="dataLoaded">
      <WidgetQueryParams
        v-if="widget.config[widget.type].filtered"
        v-model="selectorState"
        :loading="loading"
        :params="widget.datasets[0].params"
        :multi-value="widget.type === 'bar_chart' || widget.type === 'line_chart'
          || widget.type === 'multi_chart'"
        :filterValues="filtersValues"
        :showStacked="widget.type === 'bar_chart' || widget.type === 'multi_chart'"
        @stack="handleStack"
      />
      <v-row no-gutters>
        <v-col :cols="widgetCols(widget.type)" class="text-center">
          <div  v-if="dataset && widget">

            <TableWidget
              ref="widget"
              v-if="widget.type === 'newtable'"
              :widget="widget"
              :height="availableHeight"
              :param-state="selectorState.queryParams"
              @query="getTableData"
            />

            <EditableTableWidget
              ref="widget"
              v-if="widget.type === 'editable_table'"
              :widget="widget"
              :filter-state="selectorState.filterState"
              :height="availableHeight"
              :queryResult="queryResult"
            />

            <OldTableWidget
              ref="widget"
              v-if="widget.type === 'table'"
              :widget="widget"
              :filter-state="selectorState.filterState"
              :height="availableHeight"
              :queryResult="queryResult"
            />

            <NumberWidget
              ref="widget"
              v-if="widget.type === 'number'"
              :height="availableHeight"
              :fullscreen=true
              :widget="widget"
              :dataset="dataset"
              :queryResult="queryResult"
            />

            <BarChartWidget
              ref="widget"
              v-if="widget.type === 'bar_chart'"
              :height="availableHeight - 50"
              :widget="widget"
              :queryResult="queryResult"
              :widgetFilters="widgetFilters"
              :filter-state="selectorState.filterState"
              :stacked="widgetStacked"
              :stack="stack"
              @resetStack="stack.active = false"
              @filterSave="saveFilters"
            />

            <TextWidget
              ref="widget"
              v-if="widget.type === 'text'"
              :height="availableHeight"
              :widget="widget"
            />

            <LineChartWidget
              ref="widget"
              v-if="widget.type === 'line_chart'"
              :height="availableHeight - 50"
              :widget="widget"
              :queryResult="queryResult"
              :widgetFilters="widgetFilters"
              :filter-state="selectorState.filterState"
              @filterSave="saveFilters"
            />

            <MultiChartWidget
              ref="widget"
              v-if="widget.type === 'multi_chart'"
              :height="availableHeight"
              :widget="widget"
              :queryResult="queryResult"
              :queryResult2="queryResult2"
              :widgetFilters="widgetFilters"
              :stack="stack"
              :filter-state="selectorState.filterState"
              @filterSave="saveFilters"
            />

            <PieChartWidget
              ref="widget"
              v-if="widget.type === 'pie_chart'"
              :height="availableHeight - 50"
              :widget="widget"
              :dataset="dataset"
              :queryResult="queryResult"
              :widgetFilters="widgetFilters"
              :filter-state="selectorState.filterState"
              @filterSave="saveFilters"
            />
          </div>
          <v-progress-circular v-else indeterminate color="primary"/>
        </v-col>
        <v-col :cols="12-widgetCols(widget.type)"
          v-if="widget.type !== 'table' && widget.type !== 'number' && widget.type !== 'text'
          && widget.type !== 'editable_table'"
        >
          <v-tabs v-model="tab">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab key="datatable">Data Table</v-tab>
            <v-tab key="kpi">KPI</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" v-if="widget.type === 'multi_chart'">
            <v-tab-item key="datatable">
              <WidgetDataTable
                :data="queryResult.values"
                :data2="queryResult2.values"
                :headers="buildHeaders(widget)"
                :height="availableHeight"
                @data="changeExportData"
              />
            </v-tab-item>
            <v-tab-item key="kpi">
              <WidgetKPI
                :data="queryResult.values"
                :data2="queryResult2.values"
                :height="availableHeight"
                :kpiElements="buildKPIElements(widget)"
                :filterData="widgetSaveFilters"
                @data="changeExportData"
              />
            </v-tab-item>
          </v-tabs-items>
          <v-tabs-items v-model="tab" v-else>
            <v-tab-item key="datatable">
              <WidgetDataTable
                :data="queryResult.values"
                :headers="buildHeaders(widget)"
                :height="availableHeight"
                @data="changeExportData"
              />
            </v-tab-item>
            <v-tab-item key="kpi">
              <WidgetKPI
                :data="queryResult.values"
                :height="availableHeight"
                :kpiElements="buildKPIElements(widget)"
                :filterData="widgetSaveFilters"
                @data="changeExportData"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import api from '@/api';
import chartData from '@/tools/chartData';
import dataTypes from '@/tools/dataTypes';

import ExportData from '@/admin/components/main/DashboardEditor/export/ExportData.vue';
import WidgetKPI from '@/admin/components/main/DashboardEditor/widgets/expanded/WidgetKPI.vue';
import WidgetDataTable from '@/admin/components/main/DashboardEditor/widgets/expanded/WidgetDataTable.vue';
import WidgetQueryParams from '@/admin/components/main/DashboardEditor/widgets/WidgetQueryParams.vue';

import BarChartConfig from '@/admin/components/main/DashboardEditor/widgets/bar_chart/BarChartConfig.vue';
import LineChartConfig from '@/admin/components/main/DashboardEditor/widgets/line_chart/LineChartConfig.vue';
import PieChartConfig from '@/admin/components/main/DashboardEditor/widgets/pie_chart/PieChartConfig.vue';

import BarChartWidget from '@/admin/components/main/DashboardEditor/widgets/bar_chart/BarChartWidget.vue';
import LineChartWidget from '@/admin/components/main/DashboardEditor/widgets/line_chart/LineChartWidget.vue';
import MultiChartWidget from '@/admin/components/main/DashboardEditor/widgets/multi_chart/MultiChartWidget.vue';
import NumberWidget from '@/admin/components/main/DashboardEditor/widgets/number/NumberWidget.vue';
import OldTableWidget from '@/admin/components/main/DashboardEditor/widgets/oldtable/OldTableWidget.vue';
import PieChartWidget from '@/admin/components/main/DashboardEditor/widgets/pie_chart/PieChartWidget.vue';
import TableWidget from '@/admin/components/main/DashboardEditor/widgets/table/TableWidget.vue';
import TextWidget from '@/admin/components/main/DashboardEditor/widgets/text/TextWidget.vue';
import EditableTableWidget from '@/admin/components/main/DashboardEditor/widgets/editable_table/EditableTableWidget.vue';

export default {
  name: 'WidgetView',
  components: {
    WidgetQueryParams,
    TextWidget,
    NumberWidget,
    ExportData,
    TableWidget,
    OldTableWidget,
    BarChartWidget,
    BarChartConfig,
    LineChartWidget,
    MultiChartWidget,
    PieChartWidget,
    PieChartConfig,
    LineChartConfig,
    WidgetDataTable,
    WidgetKPI,
    EditableTableWidget,
  },
  props: {
    widgetFilters: {
      type: Object,
      required: false,
    },
  },
  mounted() {
    // eslint-disable-next-line prefer-destructuring
    this.dataset = this.widget.datasets[0];
    this.selectorState.queryParams = dataTypes.buildInitialParamState(
      this.dataset.params || [],
    );
    if (this.widget.type === 'multi_chart') {
      this.dataset2 = this.widget?.datasets[1];
      this.selectorState.queryParams2 = dataTypes.buildInitialParamState(
        this.dataset2.params || [],
      );
    }
    this.extractData();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.$root.$on('updateFiltersFromGlobal', this.updateFiltersFromGlobal);
  },
  watch: {
    tab: {
      deep: true,
      handler() {
        this.changeExportData();
      },
    },
    'selectorState.queryParams': {
      deep: true,
      async handler() {
        this.saveTemporalFilters();
        await this.extractData();
        if (this.$refs.widget?.buildData) {
          this.$refs.widget.buildData();
        }
        this.loadTemporalFilters();
      },
    },
    'selectorState.filterState': {
      deep: true,
      async handler() {
        if (!this.tempoChange) {
          if (this.$refs.widget?.buildData) {
            this.$refs.widget.buildData();
            this.updatePosibleFilters();
          }
        }
      },
    },
    widget: {
      deep: true,
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal?.datasets) !== JSON.stringify(oldVal?.datasets)) {
          [this.dataset] = newVal.datasets;
          this.selectorState.queryParams = dataTypes.buildInitialParamState(
            this.dataset.params || [],
          );
          this.extractData();
        }
        this.buildFilters();
        this.handleResize();
      },
    },
  },
  computed: {
    widgetConfig() {
      return this.widget.config[this.widget.type];
    },
  },
  methods: {
    handleStack(x, y) {
      if (!this.stack.active) this.stack = { col: x, values: y, active: true };
    },
    async extractData() {
      if (this.loading) {
        return;
      }
      if (this.widget.type === 'text' || this.widget.type === 'newtable') {
        this.dataLoaded = true;
        this.fetching = false;
        this.printData = this.queryResult.values;
        return;
      }
      const admin = this.$store.state.session.roles.indexOf('admin') >= 0;
      const datasetEndpoints = admin ? api.admin.dataset : api.client.dataset;
      this.loading = true;
      this.queryResult = await datasetEndpoints.fetchData(
        this.dataset.id,
        this.selectorState.queryParams,
      );
      this.printData = this.queryResult.values;
      if (this.widget.type === 'multi_chart') {
        this.queryResult2 = await datasetEndpoints.fetchData(
          this.dataset2.id,
          this.selectorState.queryParams,
        );
      }
      this.buildFilters();
      this.loading = false;
      this.loadDefaults();
      this.dataLoaded = true;
      this.fetching = false;
    },
    buildFilters() {
      if (this.widgetConfig.filtered && this.widgetConfig.filterColumns
        && this.widgetConfig.filterColumns.length > 0) {
        const filterConfig = chartData.buildFilters(
          this.widgetConfig.filterColumns,
          this.queryResult.values,
        );
        this.allFilters = filterConfig.filters;
        this.filtersValues = this.allFilters;
        this.selectorState.filterState = filterConfig.filterState;
        this.updatePosibleFilters();
      } else {
        this.filtersValues = {};
        this.selectorState.filterState = {};
      }
    },
    updatePosibleFilters() {
      if (this.widget.config[this.widget.type].filterColumns
        && this.widget.config[this.widget.type].filterColumns.length === 0) {
        this.filtersValues = {};
        return;
      }
      const existingFilters = {};
      const fValues = this.allFilters;
      const sValues = this.selectorState.filterState;
      const sComb = {};
      Object.keys(fValues).forEach((fVal) => {
        sComb[fVal] = [];
        Object.keys(sValues).forEach((sVal) => {
          if (fVal !== sVal) {
            if (sValues[sVal].length > 0) {
              sComb[fVal].push(sVal);
            }
          }
        });
      });
      Object.keys(fValues).forEach((fVal) => {
        if (sComb[fVal].length > 0) {
          fValues[fVal].forEach((filter) => {
            let posible = true;
            sComb[fVal].forEach((sVal) => {
              if (!this.queryResult.values.some((el) => {
                if (filter === (el[fVal]) && sValues[sVal].includes(el[sVal])) {
                  if (!existingFilters[fVal]) existingFilters[fVal] = [];
                  return true;
                }
                return false;
              })) {
                posible = false;
              }
            });
            if (posible) {
              existingFilters[fVal].push(filter);
            }
          });
        } else {
          existingFilters[fVal] = fValues[fVal];
        }
      });
      Object.keys(existingFilters).forEach((k) => {
        existingFilters[k] = [...new Set(existingFilters[k])];
      });
      this.filtersValues = existingFilters;
    },
    loadDefaults() {
      if (this.widget.type !== 'pie_chart' && this.widget.type !== 'multi_chart' && this.widget.type !== 'number') {
        if (this.widget.config[this.widget.type].filterColumns
        && this.widget.config[this.widget.type].filterColumns[0]
        && this.widget.config[this.widget.type].filterColumns[0].default) {
          this.widget.config[this.widget.type].filterColumns.forEach((column) => {
            this.selectorState.filterState[column.name] = column.default;
          });
        }
      }
    },
    changeExportData(data) {
      if (!data) {
        if (this.tab === 0) {
          this.printData = this.datatableData;
        } else if (this.tab === 1) {
          this.printData = this.kpitableData;
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (this.tab === 0) {
          this.datatableData = { ...data };
          this.printData = this.datatableData;
        } else if (this.tab === 1) {
          this.kpitableData = { ...data };
          this.printData = this.kpitableData;
        }
      }
    },
    getTableData(data) {
      this.printData = data.values;
    },
    buildHeaders(widget) {
      if (widget.type === 'pie_chart') return [widget.config[widget.type].labels, widget.config[widget.type].values];
      if (widget.type === 'bar_chart') return [widget.config[widget.type].labelColumn].concat(widget.config[widget.type].values);
      if (widget.type === 'line_chart') return [widget.config[widget.type].labelColumn].concat(widget.config[widget.type].values);
      if (widget.type === 'multi_chart') return [widget.config[widget.type].labelColumn].concat(widget.config[widget.type].values);
      return [widget.config[widget.type].labels, widget.config[widget.type].values];
    },
    buildKPIElements(widget) {
      if (widget.type === 'pie_chart') return [widget.config[widget.type].values];
      if (widget.type === 'bar_chart') return [widget.config[widget.type].values];
      if (widget.type === 'line_chart') return [widget.config[widget.type].values];
      if (widget.type === 'multi_chart') return [widget.config[widget.type].values];
      return [widget.config[widget.type].values];
    },
    widgetCols(type) {
      if (type === 'table' || type === 'number' || type === 'text' || type === 'editable_table') {
        return 12;
      }
      return 8;
    },
    saveFilters(filters) {
      this.widgetSaveFilters = filters;
    },
    handleResize() {
      this.availableHeight = (window.innerHeight - 200);
    },
    handleCollapse() {
      if (this.widgetSaveFilters) this.$emit('collapse', this.widget, this.widgetSaveFilters);
      else this.$emit('collapse', this.widget);
    },
    handleEdit() {
      this.$emit('edit');
    },
    handleDelete() {
      this.$emit('delete');
    },
    printWidget(name) {
      const element = document.getElementById(`wig${this.widget.id}`);
      const height = this.availableHeight;
      const width = (16 * (this.availableHeight / 7));

      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('landscape', 'px', [height + 10, width + 10]);
      html2canvas(element).then((canvas) => {
        const image = canvas.toDataURL('image/png');
        pdf.addImage(image, 'PNG', 5, 5, width, height);
        pdf.setFillColor(255, 255, 255, 255);
        pdf.setDrawColor(255, 255, 255, 255);
        pdf.save(name);
      });
    },
    updateFiltersFromGlobal(filter) {
      this.selectorState.filterState[filter.label] = filter.selected;
      // this.saveActualFilters();
    },
    saveTemporalFilters() {
      this.tempoChange = true;
      this.temporalFilters = this.selectorState.filterState;
    },
    loadTemporalFilters() {
      this.selectorState.filterState = this.temporalFilters;
      this.tempoChange = false;
    },
  },
  data() {
    return {
      widget: this.$route.meta.widget,
      bsDisable: false,
      tab: 0,
      availableHeight: this.widget?.config?.height * 65 - 15 - 80,
      loadingDatasets: false,
      widgetSaveFilters: null,
      dataset: this.widget?.datasets[0],
      dataset2: null,
      kpitableData: undefined,
      datatableData: undefined,
      queryResult: null,
      queryResult2: null,
      printData: null,
      loading: false,
      dataLoaded: false,
      widgetStacked: false,
      labelColumn: this.widget?.config?.bar_chart ? this.widget?.config?.bar_chart.labelColumn
        : null,
      fetching: true,
      r: Math.random(),
      allFilters: {},
      filtersValues: {},
      selectorState: { queryParams: {}, filterState: {} },
      stack: { col: null, values: null, active: false },
      tempoChange: false,
    };
  },
};
</script>
