import { render, staticRenderFns } from "./DatasetConfigStep.vue?vue&type=template&id=be44e676&scoped=true&"
import script from "./DatasetConfigStep.vue?vue&type=script&lang=js&"
export * from "./DatasetConfigStep.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be44e676",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
installComponents(component, {VCard,VExpansionPanelContent})
