<template>
  <v-card-text>
    <v-row class="ma-0 pa-0" v-for="cond, i in conditions" :key="i">
      <v-col class="my-0 mx-1 pa-0">
        <v-select
          :items="columns"
          item-text="name"
          hide-details="auto"
          @input="(v) => handleColumnChange(cond, v)"
        />
      </v-col>
      <v-col class="my-0 mx-1 pa-0">
        <!-- <v-select
          v-if="cond.type === 'date' || cond.type === 'datetime'"
          :items="intervals"
          item-text="name"
          item-value="value"
        /> -->
        <v-text-field
          v-if="cond.type == null"
          disabled
          hide-details="auto"
        />
        <v-text-field
          v-else-if="isNumberCond(cond.type)"
          v-model="cond.value"
          type="number"
          hide-details="auto"
          @input="handleValueChange"
        />
        <v-select
          v-else-if="isDateCond(cond.type)"
          v-model="cond.value"
          :items="dates"
          item-text="name"
          item-value="value"
          hide-details="auto"
          @input="handleValueChange"
        />
      </v-col>
      <div class="d-flex align-center">
        <v-btn @click="removeCondition(i)">
          <v-icon color="error">mdi-delete</v-icon>
        </v-btn>
      </div>
    </v-row>
    <v-row class="ma-0 pa-0">
      <v-col>
        <v-btn @click="addCondition()" block>
          <v-icon color="secondary">mdi-plus</v-icon> Aggiungi Cond.
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col :cols="devApp ? 8 : 9">
        <span v-if="queryChanged && resultValues == null" class="text-subtitle-1">
          Senza risultati
        </span>
        <span v-else-if="queryChanged" class="text-subtitle-1">
          Risultati obsoleti
        </span>
      </v-col>
      <v-col cols=1 v-if="devApp">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-information-variant-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>{{(localDataset||{}).query}}</span>
        </v-tooltip>
      </v-col>
      <v-col cols=3>
        <v-btn :loading="loadingResults" color="primary" @click="testQuery">
          <v-icon class="mdi mdi-database-search"/>
          TEST
        </v-btn>
      </v-col>
    </v-row>
    <div v-if="queryError">
      {{queryError}}
    </div>
    <v-data-table
      dense
      v-if="resultValues"
      :headers="resultHeaders"
      :items="resultValues"
      :loading="loadingResults"
    />
  </v-card-text>
</template>

<script>
import defaultMappigns from '@/tools/defaultMappings';
import dataTypes from '@/tools/dataTypes';

import api from '@/api';

const { defaultDates, defaultIntervals } = defaultMappigns;

export default {
  name: 'FiltersConfigStep',
  components: { },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    dataset: {
      type: Object,
      required: true,
    },
  },
  watch: {
    dataset(newVal) {
      console.log(newVal);
      this.localDataset = JSON.parse(JSON.stringify(this.dataset));
    },
  },
  methods: {
    async parseQuery() {
      if (this.localDataset.query === '') return;
      const queryString = this.localDataset.query.split(' ');
      let scanIndex = -1;
      let currentScan = '';

      queryString.forEach((word, index) => {
        console.log(word);
        if (word.toUpperCase() === 'WHERE') {
          scanIndex = index;
          currentScan = word.toUpperCase();
        }

        if (currentScan === 'SELECT' && index === scanIndex + 1) {
          if (word === '*') this.selectedColumns = [];
          else this.selectedColumns = word.split(',');
        } else if (currentScan === 'FROM' && index === scanIndex + 1) {
          this.table = word;
          this.columns = this.schemaTables.find((x) => (x.name === this.table)).columns;
        }
      });
    },
    updateFilters(value) {
      this.$emit('filters', value);
    },
    addCondition() {
      this.conditions.push({
        name: null,
        type: null,
        value: null,
      });
    },
    removeCondition(i) {
      this.conditions.splice(i, 1);
      this.handleValueChange();
    },
    handleColumnChange(condition, value) {
      condition.name = value;
      condition.type = this.columns.find((col) => col.name === value).dataType;
    },
    handleValueChange() {
      this.queryChanged = true;
      if (!this.conditions.some((x) => x.name && x.value)) return;
      let dataquery = `${this.dataset.query.split(' WHERE ')[0]} WHERE `;
      this.conditions.forEach((cond) => {
        if (this.isNumberCond(cond.type)) dataquery += `${cond.name} = ${cond.value} AND `;
        else if (this.isDateCond(cond.type)) dataquery += `DATE(${cond.name}) = ${this.parseDate(cond.value)} AND `;
        else if (this.isIntervalCond(cond.type)) dataquery += `(DATE(${cond.name}) BETWEEN ${this.parseInterval(cond.value)}) AND `;
        else dataquery += `${cond.name} = '${cond.value}' AND `;
      });
      dataquery = dataquery.slice(0, -4);
      this.localDataset.query = dataquery;
    },
    async testQuery() {
      if (this.loadingResults) {
        return;
      }
      this.queryChanged = false;
      this.queryFailed = false;
      this.queryError = null;
      this.loadingResults = true;
      const data = await api.graphic.dataset.test(this.localDataset, this.paramState);
      if (data.failed) {
        this.queryFailed = true;
        this.queryError = data.error;
        this.resultValues = null;
        this.resultHeaders = null;
        this.$emit('metaRefresh', null);
      } else {
        this.resultHeaders = data.metas.columns.map((col) => ({
          text: `${col.name} (${col.type})`,
          value: col.name,
        }));
        this.resultValues = data.values;
        this.$emit('metaRefresh', data.metas);
        this.$emit('filters', this.localDataset.query);
      }
      this.loadingResults = false;
    },
    isNumberCond(type) {
      return type.includes('int') || type === 'float';
    },
    isIntervalCond(type) {
      return this.isDateCond(type);
    },
    isDateCond(type) {
      return type === 'date' || type === 'datetime';
    },
    parseDate(value) {
      return `DATE('${dataTypes.convertDefaultDate(value)}')`;
    },
    parseInterval(value) {
      const interval = dataTypes.convertDefaultInterval(value);
      return `DATE('${interval[0]}') AND DATE('${interval[1]}')`;
    },
    reset() {
      this.conditions = [];
    },
  },
  data() {
    return {
      devApp: true,
      conditions: [],
      params: [],
      dates: defaultDates,
      intervals: defaultIntervals,
      queryChanged: false,
      queryFailed: false,
      queryError: null,
      loadingResults: false,
      localDataset: this.dataset,
    };
  },
};
</script>

<style scoped>

</style>
