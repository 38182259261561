<template>
  <v-card
    :id="'wig'+widget.id"
    class="fill-height cardBackgroundColor"
    style="overflow-y: visible; overflow-x: hidden"
    v-if="!fetching"
  >
    <v-container class="widget-actions">
      <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="editable" color="gray" icon small @click="handleVisibility"
              v-bind="attrs" v-on="on">
              <v-icon v-if="widget.showOnHome" class="mx-2 mdi mdi-eye-outline" />
              <v-icon v-else class="mx-2 mdi mdi-eye-off-outline" />
            </v-btn>
          </template>
          <span>Mostra in pagina</span>
      </v-tooltip>

      <v-tooltip v-if="editable" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon  v-bind="attrs" v-on="on" @click="handleClone">
            <v-icon color="secondary" class="mdi mdi-content-copy" />
          </v-btn>
        </template>
        <span>Clona Widget</span>
      </v-tooltip>

      <ExportData v-if="queryResult && widget && widget.type != 'number'"
        :title="widget.title"
        :query="queryResult2 && queryResult2.values
          ? queryResult.values.concat(queryResult2.values): queryResult.values"
        :onlyCSV="widget.type === 'table' || widget.type === 'editable_table'"
        @click="bsDisable=true"
        @close="bsDisable=false"
        @printPDF="printWidget"
      />
      <!-- <v-btn v-if="editable" color="primary" icon small  @click="handleDelete">
        <v-icon color="primary" class="mdi mdi-delete"/>
      </v-btn> -->
      <DeleteConfirmation
        v-if="editable"
        @confirm="handleDelete"
      />
      <v-btn v-if="editable" color="secondary" icon small  @click="handleEdit">
        <v-icon color="primary" class="mdi mdi-pencil"/>
      </v-btn>
      <v-btn
        v-if="false && widget.type !== 'text' && widget.type !== 'number'
          && widget.type !== 'editable_table'"
        icon small
        @click="handleExpand">
        <v-icon color="secondary" class="mdi mdi-arrow-expand-all"/>
      </v-btn>
    </v-container>
    <v-card-title v-if="widget.type !== 'text' && widget.type !== 'number'">
      <v-row class="ma-0" v-if="widget.type != 'multi_chart'">
        {{widget.title}}
        <PieChartConfig
          v-if="dataset && widget.type == 'pie_chart'"
          class="my-n4"
          v-model="widget.config.pie_chart"
          :dataset="dataset"
          :metas="queryResult.metas"
          :adminPanel="false"
        />
        <LineChartConfig
          v-if="dataset && widget.type == 'line_chart'"
          class="my-n6"
          v-model="widget.config.line_chart"
          :dataset="dataset"
          :metas="queryResult.metas"
          :adminPanel="false"
        />
        <BarChartConfig
          v-if="dataset && widget.type == 'bar_chart'"
          class="my-n6"
          v-model="widget.config.bar_chart"
          :dataset="dataset"
          :metas="queryResult.metas"
          :adminPanel="false"
        />
      </v-row>
      <v-row class="flex-column" v-if="widget.type == 'multi_chart'">
        <div class="mx-2 text-md-h3-1 d-flex flex-row" >
          <span>{{widget.title}}</span>
          <v-col cols="4" class="ml-4 ma-0 pa-0"
              v-if="false"
          >
            <v-select
              dense
              hide-details="auto"
              small-chips
              :items="getNumericColumns"
              :value="widget.config['multi_chart'].values[0]"
              @input="(x) => widget.config['multi_chart'].values = [x]"
            >
            </v-select>
          </v-col>
        </div>
        <v-row v-if="dataset && !widget.config.multi_chart.hideAxisSelector"
          class="mt-6 mb-n4 text-left mx-8 text-md-body-1 font-weight-bold flex-row">
          <MultiChartConfig
            v-if="dataset"
            class="my-n4"
            v-model="widget.config.multi_chart"
            :dataset="dataset"
            :metas="queryResult.metas"
            :adminPanel="false"
          />
        </v-row>
        <v-row v-if="dataset && !widget.config.multi_chart.hideDetails"
          class="mt-6 mb-n2 text-left mx-8 text-md-body-1 font-weight-bold flex-row">
          <v-col class="ma-n3">
            <p class="mt-n6 mb-n1 grey--text text--darken-1">
              {{
                widget.config['multi_chart'].tagTotal1 ?
                widget.config['multi_chart'].tagTotal1 :
                ('Totale ' + widget.config['multi_chart'].values[0]
                  + ' ' + widget.config['multi_chart'].name1)
              }}
            </p>
            <h2>{{formatNumber(multi.total1)}}</h2>
          </v-col>
          <v-col class="ma-n3">
            <p class="mt-n6 mb-n1 grey--text text--darken-1">
              {{
                widget.config['multi_chart'].tagTotal2 ?
                widget.config['multi_chart'].tagTotal2 :
                ('Totale ' + widget.config['multi_chart'].values[0]
                + ' ' + widget.config['multi_chart'].name2)
              }}
            </p>
            <h2>{{formatNumber(multi.total2)}}</h2>
          </v-col>
          <v-col class="ma-n3">
            <p class="mt-n6 mb-n1 grey--text text--darken-1">
              {{
                widget.config['multi_chart'].tagVersus ?
                widget.config['multi_chart'].tagVersus :
                'Confronto'
              }}
            </p>
            <v-row class="ma-0">
              <!-- <v-btn color="info" icon large class="my-n2 mr-2 ml-n1"
                @click="handleMultiPercentual">
                <v-icon class="mdi mdi-label-percent"/>
              </v-btn> -->
              <h2 v-if="multi.growth > 0" class="green--text">{{formatNumber(multi.growth)}}</h2>
              <h2 v-else-if="multi.growth == 0">{{formatNumber(multi.growth)}}</h2>
              <h2 v-else class="red--text">{{formatNumber(multi.growth)}}</h2>
              <h2 v-if="multi.percentual">%</h2>
            </v-row>
          </v-col>
        </v-row>
        <!-- Hide Details tag -->
        <div
          v-if="dataset && widget.config.multi_chart.hideDetails"
          class="my-2"
        />
      </v-row>
    </v-card-title>
    <v-card-title v-else-if="widget.type === 'number'">
      <span class="text-h6"> {{widget.title}} </span>
    </v-card-title>
    <v-card-text v-if="dataLoaded" class="pl-3 pr-2">
      <WidgetQueryParams
        v-if="widget.config[widget.type].filtered"
        v-model="selectorState"
        :loading="loading"
        :globalParams="globalParams"
        :globalFilters="globalFilters"
        :params="widget.datasets[0].params"
        :params2="widget.datasets[1] ? widget.datasets[1].params : []"
        :filter-values="filtersValues"
        :multi-value="widget.type === 'bar_chart'
                   || widget.type === 'line_chart'
                   || widget.type === 'table'
                   || widget.type === 'multi_chart'
                   || widget.type === 'number'
                   || widget.type === 'editable_table'"
        :showStacked="widget.type === 'bar_chart' || widget.type === 'multi_chart'"
        @stack="handleStack"
        @syncf="updatePosibleFilters()"
      />
      <v-row no-gutters>
        <v-col cols="12" class="text-center">
          <div  v-if="dataset && widget">

            <NumberWidget
              ref="widget"
              v-if="widget.type === 'number'"
              :height="availableHeight"
              :widget="widget"
              :widgetFilters="widgetFilters"
              :filter-state="selectorState.filterState"
              :dataset="dataset"
              :queryResult="queryResult"
              :adminView="editable"
            />

            <TableWidget
              ref="widget"
              v-if="widget.type === 'newtable'"
              :widget="widget"
              :param-state="selectorState.queryParams"
              :height="availableHeight - 50"
              :adminView="editable"
              @query="getTableData"
            />

            <EditableTableWidget
              ref="widget"
              v-if="widget.type === 'editable_table'"
              :widget="widget"
              :filter-state="selectorState.filterState"
              :height="availableHeight - 20"
              :queryResult="queryResult"
              :adminView="editable"
            />

            <OldTableWidget
              ref="widget"
              v-if="widget.type === 'table'"
              :widget="widget"
              :filter-state="selectorState.filterState"
              :height="availableHeight - 20"
              :queryResult="queryResult"
              :adminView="editable"
            />

            <BarChartWidget
              ref="widget"
              v-if="widget.type === 'bar_chart'"
              :height="availableHeight - 50"
              :widget="widget"
              :queryResult="queryResult"
              :widgetFilters="widgetFilters"
              :filter-state="selectorState.filterState"
              :stacked="widgetStacked"
              :stack="stack"
              :adminView="editable"
              @resetStack="stack.active = false"
              @filterSave="saveFilters"
            />

            <TextWidget
              ref="widget"
              v-if="widget.type === 'text'"
              :height="availableHeight"
              :widget="widget"
            />

            <LineChartWidget
              ref="widget"
              v-if="widget.type === 'line_chart'"
              :height="availableHeight - 50"
              :widget="widget"
              :queryResult="queryResult"
              :widgetFilters="widgetFilters"
              :filter-state="selectorState.filterState"
              :stack="stack"
              :adminView="editable"
              @resetStack="stack.active = false"
              @filterSave="saveFilters"
            />

            <MultiChartWidget
              ref="widget"
              v-if="widget.type === 'multi_chart'"
              :height="availableHeight
                - (widget.config.multi_chart.hideAxisSelector ? 0 : 50)
                - (widget.config.multi_chart.hideDetails ? 0 : 0)
                - 72"
              :widget="widget"
              :queryResult="queryResult"
              :queryResult2="queryResult2"
              :widgetFilters="widgetFilters"
              :stack="stack"
              :filter-state="selectorState.filterState"
              :percentual="multi.percentual"
              :adminView="editable"
              @infoBar="handleInfoBarChange"
              @filterSave="saveFilters"
            />

            <PieChartWidget
              ref="widget"
              v-if="widget.type === 'pie_chart'"
              :height="availableHeight - 50"
              :widget="widget"
              :dataset="dataset"
              :queryResult="queryResult"
              :widgetFilters="widgetFilters"
              :filter-state="selectorState.filterState"
              :adminView="editable"
              @filterSave="saveFilters"
            />
          </div>
          <v-progress-circular v-else indeterminate color="primary"/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import api from '@/api';
import dataTypes from '@/tools/dataTypes';
import chartData from '@/tools/chartData';

import DeleteConfirmation from '@/graphic/components/main/DashboardEditor/widgets/DeleteConfirmation.vue';
import ExportData from '@/graphic/components/main/DashboardEditor/export/ExportData.vue';
import WidgetQueryParams from '@/graphic/components/main/DashboardEditor/widgets/WidgetQueryParams.vue';

import BarChartConfig from '@/graphic/components/main/DashboardEditor/widgets/bar_chart/BarChartConfig.vue';
import LineChartConfig from '@/graphic/components/main/DashboardEditor/widgets/line_chart/LineChartConfig.vue';
import PieChartConfig from '@/graphic/components/main/DashboardEditor/widgets/pie_chart/PieChartConfig.vue';
import MultiChartConfig from '@/graphic/components/main/DashboardEditor/widgets/multi_chart/MultiChartConfig.vue';

import BarChartWidget from '@/graphic/components/main/DashboardEditor/widgets/bar_chart/BarChartWidget.vue';
import LineChartWidget from '@/graphic/components/main/DashboardEditor/widgets/line_chart/LineChartWidget.vue';
import MultiChartWidget from '@/graphic/components/main/DashboardEditor/widgets/multi_chart/MultiChartWidget.vue';
import NumberWidget from '@/graphic/components/main/DashboardEditor/widgets/number/NumberWidget.vue';
import OldTableWidget from '@/graphic/components/main/DashboardEditor/widgets/oldtable/OldTableWidget.vue';
import PieChartWidget from '@/graphic/components/main/DashboardEditor/widgets/pie_chart/PieChartWidget.vue';
import TableWidget from '@/graphic/components/main/DashboardEditor/widgets/table/TableWidget.vue';
import EditableTableWidget from '@/graphic/components/main/DashboardEditor/widgets/editable_table/EditableTableWidget.vue';
import TextWidget from '@/graphic/components/main/DashboardEditor/widgets/text/TextWidget.vue';

export default {
  name: 'WidgetPreview',
  components: {
    WidgetQueryParams,
    TextWidget,
    NumberWidget,
    DeleteConfirmation,
    ExportData,
    TableWidget,
    EditableTableWidget,
    OldTableWidget,
    BarChartWidget,
    LineChartWidget,
    MultiChartWidget,
    PieChartWidget,
    PieChartConfig,
    BarChartConfig,
    LineChartConfig,
    MultiChartConfig,
  },
  props: {
    widget: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    actualFilters: {
      type: Object,
      required: false,
    },
    widgetFilters: {
      type: Object,
      required: false,
    },
    globalFilters: {
      type: Array,
      required: false,
    },
    globalParams: {
      type: Array,
      required: false,
    },
    width: {
      type: Number,
      required: false,
    },
  },
  mounted() {
    this.selectorState.queryParams = dataTypes.buildInitialParamState(
      this.dataset.params || [],
    );
    if (this.widget.type === 'multi_chart') {
      if (this.widget?.datasets[1]) {
        this.dataset2 = this.widget?.datasets[1];
        this.selectorState.queryParams2 = dataTypes.buildInitialParamState(
          this.dataset2.params || [],
        );
      }
      // this.widget.datasets[0].params.forEach((par) => {
      //   // eslint-disable-next-line no-param-reassign
      //   par.displayAs = `Set 1: ${par.displayAs}`;
      // });
      // this.widget.datasets[1].params.forEach((par) => {
      //   // eslint-disable-next-line no-param-reassign
      //   par.displayAs = `Set 2: ${par.displayAs}`;
      // });
    }
    this.extractData();
    this.$root.$on('updateFilterFromGlobal', this.updateFilterFromGlobal);
    this.$root.$on('updateFiltersFromGlobal', this.updateFiltersFromGlobal);
    this.$root.$on('updateParamFromGlobal', this.updateParamFromGlobal);
    this.$root.$on('updateParamsFromGlobal', this.updateParamsFromGlobal);
  },
  watch: {
    'selectorState.queryParams': {
      deep: true,
      async handler() {
        this.saveTemporalFilters();
        await this.extractData();
        if (this.$refs.widget?.buildData) {
          this.$refs.widget.buildData();
        }
        this.loadTemporalFilters();
      },
    },
    'selectorState.queryParams2': {
      deep: true,
      async handler() {
        this.saveTemporalFilters();
        await this.extractData();
        if (this.$refs.widget?.buildData) {
          this.$refs.widget.buildData();
        }
        this.loadTemporalFilters();
      },
    },
    'selectorState.filterState': {
      deep: true,
      async handler() {
        if (!this.tempoChange) {
          if (this.$refs.widget?.buildData) {
            this.$refs.widget.buildData();
          }
        }
      },
    },
    widget: {
      deep: true,
      handler(newVal, oldVal) {
        this.$root.$emit('cleanGlobalFilters');
        this.$root.$emit('cleanGlobalParams');
        if (JSON.stringify(newVal?.datasets) !== JSON.stringify(oldVal?.datasets)) {
          [this.dataset] = newVal.datasets;
          this.selectorState.queryParams = dataTypes.buildInitialParamState(
            this.dataset.params || [],
          );
          this.extractData();
        }
        this.buildFilters();
        this.loadDefaults();
        this.availableHeight = newVal.config.height * 65 - 64;
      },
    },
  },
  computed: {
    widgetConfig() {
      return this.widget.config[this.widget.type];
    },
    getNumericColumns() {
      const isNumeric = (thing) => dataTypes.isNumeric(thing);
      const filtered = this.queryResult.metas.columns.filter((item) => isNumeric(item));
      return filtered.map((x) => x.name);
    },
  },
  methods: {
    handleStack(x, y) {
      if (this.stack.col === x) this.stack = { col: null, values: y, active: false };
      else this.stack = { col: x, values: y, active: true };
      this.updatePosibleFilters();
    },
    formatNumber(num) {
      if (num % 1 === 0) return String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1.');
      return String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1.').replace(/.([^.]*)$/, ',$1');
    },
    async extractData() {
      if (this.loading) {
        return;
      }
      if (this.widget.type === 'text' || this.widget.type === 'newtable') {
        this.dataLoaded = true;
        this.fetching = false;
        return;
      }
      const admin = this.$store.state.session.roles.indexOf('admin') >= 0;
      const datasetEndpoints = admin ? api.graphic.dataset : api.client.dataset;
      this.loading = true;
      this.queryResult = await datasetEndpoints.fetchData(
        this.dataset.id,
        this.selectorState.queryParams,
      );
      let conf = true;
      if (this.widget.type === 'multi_chart' && this.widget.datasets[1] !== undefined) {
        this.queryResult2 = await datasetEndpoints.fetchData(
          this.dataset2.id,
          this.selectorState.queryParams2,
        );
        if (this.queryResult2.error !== null) conf = false;
      }
      if (this.widget.type === 'multi_chart' && this.widget.datasets[1] === undefined) {
        this.queryResult2 = { failed: true, error: 'Error within second Query load' };
      }
      if (this.queryResult.error === null && conf) {
        this.buildFilters();
        this.loadDefaults();
      }
      if (this.widget.type === 'table') {
        if (!this.widget.config.colouredColumns) {
          this.widget.config.colouredColumns = [];
        }
        if (this.queryResult.metas) {
          this.queryResult.metas.columns.forEach((x) => {
            // eslint-disable-next-line no-param-reassign
            x.coloured = this.widget.config.colouredColumns.includes(x.name);
          });
        }
      }
      if (this.widget.type === 'editable_table') {
        if (!this.widget.config.editableColumns) {
          this.widget.config.editableColumns = [];
        }
        if (this.queryResult.metas) {
          this.queryResult.metas.columns.forEach((x) => {
            // eslint-disable-next-line no-param-reassign
            x.editable = this.widget.config.editableColumns.includes(x.name);
          });
        }
      }
      this.loading = false;
      this.dataLoaded = true;
      this.fetching = false;
    },
    async handleClone() {
      // const clone =
      await api.graphic.widget.clone(this.widget.id);
      this.$emit('cloned');
    },
    buildFilters() {
      if (this.widgetConfig.filtered && this.widgetConfig.filterColumns
        && this.widgetConfig.filterColumns.length > 0) {
        const filterConfig = chartData.buildFilters(
          this.widgetConfig.filterColumns,
          this.queryResult.values,
        );
        this.allFilters = filterConfig.filters;
        this.filtersValues = this.allFilters;
        this.selectorState.filterState = filterConfig.filterState;
        this.updatePosibleFilters();
      } else {
        this.filtersValues = {};
        this.selectorState.filterState = {};
      }
    },
    updatePosibleFilters() {
      if (this.widget.config[this.widget.type].filterColumns
        && this.widget.config[this.widget.type].filterColumns.length === 0) {
        this.filtersValues = {};
        return;
      }

      function setCond(conditions, type, value) {
        Object.keys(conditions).forEach((key) => {
          if (key !== type) conditions[key] = value && conditions[key];
        });
      }

      const data = this.queryResult.values;
      const possibleFilters = {};
      const sValues = this.selectorState.filterState;
      const filterNames = Object.keys(sValues);

      filterNames.forEach((filter) => {
        possibleFilters[filter] = [];
      });

      data.forEach((item) => {
        const cond = {};
        filterNames.forEach((filter) => {
          cond[filter] = true;
        });

        filterNames.forEach((filter) => {
          // eslint-disable-next-line max-len
          setCond(cond, filter, (sValues[filter].length === 0 || sValues[filter].includes(item[filter])));
        });

        filterNames.forEach((filter) => {
          if (
            (cond[filter] || sValues[filter].includes(item[filter]))
            && !possibleFilters[filter].includes(item[filter])
          ) {
            possibleFilters[filter].push(item[filter]);
          }
        });
      });

      this.saveActualFilters();
      this.filtersValues = possibleFilters;
      this.sortValues();
      this.$root.$emit('pushGlobalFilters', this.filtersValues);
      this.updatePosibleParams();
    },
    updatePosibleParams() {
      // TODO set params value
      if (this.widget.datasets.length === 0 || this.widget.datasets[0].params.length === 0) {
        this.paramsValues = {};
        return;
      }

      const possibleParams = {};
      const sValues = this.widget.datasets[0].params;

      // Add params options
      sValues.forEach((param) => {
        possibleParams[param.name] = {
          isGlobal: this.globalParams.includes(param.name),
          ...param,
        };
      });
      this.paramsValues = possibleParams;
      this.$root.$emit('pushGlobalParams', this.paramsValues);
    },
    sortValues() {
      if (this.filtersValues && this.filtersValues != null) {
        Object.keys(this.filtersValues).forEach((k) => {
          this.filtersValues[k] = this.filtersValues[k].sort();
        });
      }
    },
    saveActualFilters() {
      if (this.editable && this.widget.config[this.widget.type].filterColumns) {
        this.widget.config[this.widget.type].filterColumns.forEach((fl) => {
          // eslint-disable-next-line no-param-reassign
          this.actualFilters[fl.name] = this.selectorState.filterState[fl.name];
        });
      }
    },
    loadDefaults() {
      if (this.widget.type !== 'pie_chart' && this.widget.type !== 'newtable') {
        if (this.widget.config[this.widget.type].filterColumns
          && this.widget.config[this.widget.type].filterColumns[0]
          && this.widget.config[this.widget.type].filterColumns[0].default
          && Object.keys(this.widget.config[this.widget.type].filterColumns[0]).includes('default')) {
          this.widget.config[this.widget.type].filterColumns.forEach((column) => {
            this.selectorState.filterState[column.name] = column.default;
          });
        }
      }
    },
    saveFilters(filters) {
      this.widgetSaveFilters = filters;
    },
    labelSizingEx() {
      const size = this.widget.config.cols * (100 / 12);
      const admin = this.$store.state.session.roles.indexOf('admin') >= 0;
      // alert(this.$refs.wig.clientHeight);
      let maxW = ((window.innerWidth - 170) / 12) * this.width;
      maxW -= 48;
      // eslint-disable-next-line no-unused-vars
      const fontSize = (maxW / size) * 2;
      // ConvertWidth to font size
      const stl = `width: calc(100%${admin ? ' - 48px' : ''});`;
      //  font-size: ${admin ? '.8' : '1.25'}rem`;
      return stl;
    },
    labelSizing(size) {
      let maxW = ((window.innerWidth - 170) / 12) * this.width;
      let ss = maxW;
      maxW = ss;
      ss = maxW + size;
      maxW -= 48;
      const admin = this.$store.state.session.roles.indexOf('admin') >= 0;
      const fontSize = 1;
      // ConvertWidth to font size
      return `width: calc(100%); font-size: ${fontSize}rem`;
      // eslint-disable-next-line no-unreachable
      return `width: calc(100%${admin ? ' - 48px' : ''}); font-size: ${fontSize}px`;
    },
    getTableData(data) {
      this.queryResult = data;
    },
    handleExpand() {
      if (this.widgetSaveFilters) this.$emit('expand', this.widget, this.widgetSaveFilters);
      else this.$emit('expand', this.widget);
    },
    handleEdit() {
      this.$emit('edit');
    },
    handleDelete() {
      this.$emit('delete');
    },
    handleVisibility() {
      this.$emit('vision', true);
    },
    handleInfoBarChange(props) {
      this.multi.growth = props.growth;
      this.multi.total1 = props.sum1;
      this.multi.total2 = props.sum2;
      this.multi.percentual = props.percentual;
    },
    handleMultiPercentual() {
      this.multi.percentual = !this.multi.percentual;
    },
    printWidget(name) {
      const element = document.getElementById(`wig${this.widget.id}`);
      const height = element.clientHeight;
      const width = element.clientWidth;

      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('landscape', 'px', [height + 10, width + 10]);
      html2canvas(element).then((canvas) => {
        const image = canvas.toDataURL('image/png');
        pdf.addImage(image, 'PNG', 5, 5, width, height);
        pdf.setFillColor(255, 255, 255, 255);
        pdf.setDrawColor(255, 255, 255, 255);
        pdf.save(name);
      });
      this.bsDisable = false;
    },
    updateFilterFromGlobal(filter) {
      this.selectorState.filterState[filter.label] = filter.selected;
      this.saveActualFilters();
    },
    updateFiltersFromGlobal(filters) {
      filters.forEach((filter) => {
        this.selectorState.filterState[filter.label] = filter.selected;
      });
      this.saveActualFilters();
    },
    updateParamFromGlobal(param) {
      this.selectorState.queryParams[param.name] = param.selection;
      this.saveActualFilters();
    },
    updateParamsFromGlobal(params) {
      params.forEach((param) => {
        this.selectorState.queryParams[param.name] = param.selection;
      });
      this.saveActualFilters();
    },
    showFilterBar() {
      if (Object.values(this.selectorState.filterState).length > 0) return true;
      if (Object.values(this.selectorState.queryParams).length > 0) return true;
      if (this.selectorState.queryParams2
        && Object.values(this.selectorState.queryParams2).length > 0) return true;
      return false;
    },
    saveTemporalFilters() {
      this.tempoChange = true;
      this.temporalFilters = this.selectorState.filterState;
    },
    loadTemporalFilters() {
      this.$root.$emit('cleanGlobalFilters', this.filtersValues);
      // TODO
      this.$root.$emit('cleanGlobalParams', this.filtersValues);
      this.selectorState.filterState = this.temporalFilters;
      this.tempoChange = false;
    },
  },
  data() {
    return {
      bsDisable: false,
      availableHeight: this.widget?.config?.height * 65 - 64,
      loadingDatasets: false,
      dataset: this.widget?.datasets[0],
      dataset2: null,
      queryResult: null,
      queryResult2: null,
      loading: false,
      dataLoaded: false,
      widgetStacked: false,
      fetching: true,
      r: Math.random(),
      allFilters: {},
      filtersValues: {},
      selectorState: { queryParams: {}, queryParams2: {}, filterState: {} },
      stack: { col: null, values: null, active: false },
      multi: {
        growth: 0,
        total1: 0,
        total2: 0,
        percentual: false,
      },
      tempoChange: false,
    };
  },
};
</script>

<style lang="scss">
.widget-actions {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 10px;
  width: 100%;
  text-align: right;
}
</style>
