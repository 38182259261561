<template>
  <v-container fluid class="pa-0">
    <div v-if="expandedWidget != null">
      <WidgetExpanded
        class="fill-height"
        :widget="expandedWidget"
        :editable="false"
        @collapse="collapseWidget"
      />
    </div>
    <div v-else>
      <v-row class="px-6 pt-6" v-if="dataLoaded">
        <v-col cols="11">
          <v-row>
            <GlobalFilters :visibleFilters="globalFilters" />
            <GlobalParams :visibleParams="globalParams" />
          </v-row>
        </v-col>
        <v-col>
          <ExportData
            :bigButton="true"
            :title="dashboard.displayName"
            :onlyPDF="true"
            @printPDF="printDashboard"
          />
        </v-col>
      </v-row>
      <v-row v-if="!dataLoaded">
        <v-col cols="12" class="py-16 text-center">
          <v-progress-circular
            indeterminate
            color="primary"
            size="50"
            class="align-self-center justify-center"/>
        </v-col>
      </v-row>
      <v-row id="dashboard" class="px-5 pb-5" v-else>
        <GridLayout
          class="col-12"
          :layout.sync="layout"
          :col-num="12"
          :row-height="50"
          :is-draggable="false"
          :is-resizable="false"
          :is-mirrored="false"
          :vertical-compact="true"
          :margin="[15,15]"
          :use-css-transforms="true">
          <GridItem v-for="item in layout.filter((r) => r.widget.showOnHome)"
                    :x="item.x"
                    :y="item.y"
                    :w="item.w"
                    :h="item.h"
                    :i="item.i"
                    :key="item.widgetId">
            <WidgetPreview
              @expand="expandWidget(item.widget)"
              :widget="widgets[item.widgetId]"
              :globalFilters="JSON.parse((globalFilters || '[]'))"
              :globalParams="JSON.parse((globalParams || '[]'))"
              :width="item.w"
            />
          </GridItem>
        </GridLayout>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import VueGridLayout from 'vue-grid-layout';

import api from '@/api';
import ExportData from '@/admin/components/main/DashboardEditor/export/ExportData.vue';
import layout from '@/tools/layout';
import WidgetPreview from '@/admin/components/main/DashboardEditor/widgets/WidgetPreview.vue';
import WidgetExpanded from '@/admin/components/main/DashboardEditor/widgets/expanded/WidgetExpanded.vue';
import GlobalFilters from '@/admin/components/main/DashboardEditor/widgets/GlobalFilters.vue';
import GlobalParams from '@/admin/components/main/DashboardEditor/widgets/GlobalParams.vue';

export default {
  name: 'DashboardViewer',
  components: {
    ExportData,
    WidgetPreview,
    WidgetExpanded,
    GlobalFilters,
    GlobalParams,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
  mounted() {
    this.loadWidgets();
  },
  methods: {
    async loadWidgets() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      let widgets = await api.client.dashboard.widget.list(this.dashboard.id);
      widgets = widgets.filter((r) => r.showOnHome);
      // eslint-disable-next-line no-return-assign
      this.widgets = widgets
        .reduce((acc, w) => {
          acc[w.id] = w;
          return acc;
        }, {});
      this.layout = layout.buildLayout(this.widgets);
      this.dataLoaded = true;
      this.loading = false;
      this.globalFilters = this.dashboard.globalFilters ?? '';
      this.globalParams = this.dashboard.globalParams ?? '';
    },
    collapseWidget() {
      this.expandedWidget = null;
    },
    expandWidget(widget) {
      this.expandedWidget = widget;
    },
    printDashboard(name) {
      const element = document.getElementById('dashboard');
      const height = element.clientHeight;
      const width = element.clientWidth;

      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('landscape', 'px', [height + 10, width + 10]);
      html2canvas(element).then((canvas) => {
        const image = canvas.toDataURL('image/png');
        pdf.addImage(image, 'PNG', 5, 5, width, height);
        pdf.setFillColor(255, 255, 255, 255);
        pdf.setDrawColor(255, 255, 255, 255);
        pdf.save(name);
      });
    },
  },
  data() {
    return {
      loading: false,
      dataLoaded: false,
      globalFilters: [],
      widgets: null,
      layout: [],
      dashboard: this.$route.meta.dashboard,
      expandedWidget: null,
    };
  },
};
</script>

<style scoped>

</style>
