<template>
  <v-form v-if="metas">
    <!-- Label and values to show -->
    <v-row>
      <v-col cols="4">
        <v-select
          v-model="value.labels"
          :items="metas.columns"
          :item-text="(i) => `${i.name} (${i.type})`"
          item-value="name"
          required
          label="Etichetta"
          @change="updateValue"/>
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="value.value1"
          :items="numericValues"
          :item-text="(i) => `${i.name} (${i.type})`"
          item-value="name"
          required
          label="Value 1"
          @change="updateValue"/>
      </v-col>
      <v-col cols="4">
        <v-select
          clearable
          v-model="value.value2"
          :items="numericValues"
          :item-text="(i) => `${i.name} (${i.type})`"
          item-value="name"
          required
          label="Value 2 (Optional)"
          @change="updateValue"/>
      </v-col>
    </v-row>
    <!-- Display Options -->
    <v-row class="mt-0">
      <v-col cols=12 class="flex-columns">
        <v-slider
          v-model="value.operation"
          :tick-labels="operationsLabel"
          :max="6"
          step="1"
          ticks="always"
          tick-size="4"
          :thumb-size="24"
          thumb-label="always"
          class="mx-2 mt-2"
          @input="updateOperationValue"
        >
          <template v-slot:thumb-label=""></template>
        </v-slider>
        <br>
        <p class="mx-4 mt-n6">Operazione: {{operations[value.operation]}}</p>
      </v-col>
    </v-row>
    <v-row class="align-center">
      <!-- <v-col cols=4 >
        <v-switch
          class="px-0 my-0"
          hide-details="auto"
          v-model="value.numeric"
          @change="updateValue"
          :label="value.numeric ? 'Numerico' : 'Percentuale'"
        />
      </v-col> -->
      <v-col cols=3>
        <v-text-field
          outlined
          dense
          hide-details="auto"
          v-model="value.symbol"
          @input="updateValue"
          label="Simbolo"
        />
      </v-col>
    </v-row>
    <!-- Hide/show Comparison Info -->
    <v-row class="mt-0">
      <v-col cols=12>
        <v-switch
          hide-details="auto"
          v-model="value.hideVariation"
          @change="updateValue"
          label="Nascondi Variazione"
        />
      </v-col>
    </v-row>
    <!-- Display Detailed Info -->
    <v-row class="mt-0">
      <v-col cols=12>
        <v-switch
          hide-details="auto"
          v-model="value.detailed"
          @change="updateValue"
          label="Mostra Dettagli"
        />
      </v-col>
      <v-col cols=6>
        <v-text-field
          dense
          hide-details="auto"
          v-model="value.labelVal1"
          @input="updateValue"
          label="Etichetta 1"
        />
      </v-col>
      <v-col cols=6>
        <v-text-field
          dense
          hide-details="auto"
          v-model="value.labelVal2"
          @input="updateValue"
          label="Etichetta 2"
        />
      </v-col>
    </v-row>
    <!-- Filter Config -->
    <v-row>
      <v-col cols="4">
        <v-switch
          v-model="value.filtered"
          @change="updateValue"
          label="Mostra Filtri"/>
      </v-col>
      <v-col cols="8" v-if="value.filtered">
        <v-combobox
          dense
          v-model="value.filterColumns"
          item-text="name"
          item-value="name"
          @change="updateValue"
          :items="metas.columns"
          label="Colonne filtrabili"
          multiple
          small-chips
        />
      </v-col>
    </v-row>
    <v-row>
      <v-spacer/>
      <v-btn class="rounded-pill primary font-weight-bold white--text py-1 px-2 mb-1 mr-3"
        @click="saveDefaults">
        Salva la selezione come Default
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import dataTypes from '@/tools/dataTypes';

export default {
  name: 'NumberWidgetConfig',
  props: {
    dataset: {
      type: Object,
      required: true,
    },
    metas: {
      type: Object,
      required: false,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  watch: {
    metas(val) {
      this.metadata = val;
    },
  },
  mounted() {
    if (typeof this.value.operation === 'undefined') {
      this.value.operation = this.value.numeric ? 1 : 2;
    }
  },
  computed: {
    // Returns the list of columns that have numeric values
    numericValues() {
      if (!this.metas?.columns) {
        return [];
      }
      return this.metas.columns
        .filter((item) => dataTypes.isNumeric(item.type));
    },
    // Returns the list of column names
    labels() {
      if (!this.metas?.columns) {
        return [];
      }
      return this.metas.columns;
    },
    // Returns the list of columns that have Textbased values
    textValues() {
      if (!this.metas?.columns) {
        return [];
      }
      return this.metas.columns
        .filter((item) => dataTypes.isText(item.type));
    },
  },
  methods: {
    updateValue() {
      this.$emit('input', this.value);
    },
    updateOperationValue() {
      this.value.numeric = !this.value.numeric;
      this.updateValue();
    },
    // Set the current in-widget selected filter values as its default values
    saveDefaults() {
      this.$emit('saveDef');
    },
  },
  data() {
    return {
      operationsLabel: ['+', '-', '- (%)', '×', '÷', 'x̄', 'σ'],
      operations: ['Somma', 'Differenza', 'Differenza (Percentuale)', 'Prodotto', 'Rapporto', 'Media', 'Deviazione standard'],
    };
  },
};
</script>

<style scoped>

</style>
