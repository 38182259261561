<template>
  <v-navigation-drawer
    fixed
    right
    v-model="open"
    width="500px"
    height="calc(100% - 115px)"
    style="margin-top: 65px"
    class="pl-1">
    <v-card flat tile class="pa-0" v-if="localWidget">
      <v-card-title class="px-2">
        <v-card-title class="pa-0">
          <v-icon class="align-self-center mr-2">{{(widgetType||{}).icon}}</v-icon>
          <b>{{(widgetType||{}).displayName}}</b>
        </v-card-title>
        <v-spacer />
        <v-btn icon small color="info" @click="save" :loading="saving">
          <v-icon class="mdi mdi-upload"/>
        </v-btn>
        <v-btn icon small @click="close">
          <v-icon class="mdi mdi-close"/>
        </v-btn>
      </v-card-title>
      <v-card-text class="px-1">
        <v-expansion-panels accordion tile focusable autofocus multiple :value="openedSections">

          <v-expansion-panel>
            <v-expansion-panel-header>Generale</v-expansion-panel-header>
            <v-expansion-panel-content class="pt-6">
              <GeneralConfig :value="localWidget" @input="handleInput" />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Dataset</v-expansion-panel-header>
            <v-expansion-panel-content class="pt-6 dataset-config-panel" >
              <DatasetConfig v-if="dataset"
                             :widget="localWidget"
                             :schema="schema"
                             @change="handleDatasetChange"/>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel  v-if="localWidget.type === 'number'">
            <v-expansion-panel-header>Numero</v-expansion-panel-header>
            <v-expansion-panel-content class="pt-6 dataset-config-panel" >
              <NumberWidgetConfig  v-if="dataset"
                               v-model="localWidget.config.number"
                               :dataset="dataset"
                               :metas="metas"
                               @saveDef="saveDefault"
                               @input="handleInput"/>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-if="localWidget.type === 'newtable'">
            <v-expansion-panel-header>Tabella</v-expansion-panel-header>
            <v-expansion-panel-content class="pt-6 dataset-config-panel" >
              <TableWidgetConfig v-if="dataset"
                                 :widget="localWidget"
                                 :schema="schema"
                                 :metas="metas"
                                 @change="handleChange"/>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-if="localWidget.type === 'editable_table'">
            <v-expansion-panel-header>Tabella Modificabile</v-expansion-panel-header>
            <v-expansion-panel-content class="pt-6 dataset-config-panel" >
              <EditableTableConfig v-if="dataset"
                          v-model="localWidget.config.editable_table"
                          :dataset="dataset"
                          :metas="metas"
                          :editableCols="localWidget.config.editableColumns"
                          @input="handleInput"
                          @saveDef="saveDefault"
                          @saveMetaColumns="saveMetaColumns" />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-if="localWidget.type === 'table'">
            <v-expansion-panel-header>Tabella</v-expansion-panel-header>
            <v-expansion-panel-content class="pt-6 dataset-config-panel" >
              <OldTableWidgetConfig v-if="dataset"
                               v-model="localWidget.config.table"
                               :dataset="dataset"
                               :metas="metas"
                               :colouredCols="localWidget.config.colouredColumns"
                               @input="handleInput"
                               @saveDef="saveDefault"
                               @saveMetaColumns="saveMetaColumns" />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel  v-if="localWidget.type === 'bar_chart'">
            <v-expansion-panel-header>Istogramma</v-expansion-panel-header>
            <v-expansion-panel-content class="pt-6 dataset-config-panel" >
              <BarChartConfig v-if="dataset"
                               v-model="localWidget.config.bar_chart"
                               :dataset="dataset"
                               :metas="metas"
                               @input="handleInput"
                               @saveDef="saveDefault"/>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel  v-if="localWidget.type === 'text'">
            <v-expansion-panel-header>Testo</v-expansion-panel-header>
            <v-expansion-panel-content class="pt-6 dataset-config-panel" >
              <TextWidgetConfig v-model="localWidget.config.text"
                                :dataset="dataset"
                                :metas="metas"
                                @input="handleInput"/>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel  v-if="localWidget.type === 'line_chart'">
            <v-expansion-panel-header>Linea</v-expansion-panel-header>
            <v-expansion-panel-content class="pt-6 dataset-config-panel" >
              <LineChartConfig  v-if="dataset"
                               v-model="localWidget.config.line_chart"
                               :dataset="dataset"
                               :metas="metas"
                               @input="handleInput"
                               @saveDef="saveDefault"/>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel  v-if="localWidget.type === 'multi_chart'">
            <v-expansion-panel-header>Multi-Linea</v-expansion-panel-header>
            <v-expansion-panel-content class="pt-6 dataset-config-panel" >
              <MultiChartConfig  v-if="dataset"
                               v-model="localWidget.config.multi_chart"
                               :dataset="dataset"
                               :metas="metas"
                               @input="handleInput"
                               @saveDef="saveDefault"/>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel  v-if="localWidget.type === 'pie_chart'">
            <v-expansion-panel-header>Torta</v-expansion-panel-header>
            <v-expansion-panel-content class="pt-6 dataset-config-panel" >
              <PieChartConfig  v-if="dataset"
                               v-model="localWidget.config.pie_chart"
                               :dataset="dataset"
                               :metas="metas"
                               @input="handleInput"
                               @saveDef="saveDefault"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import api from '@/api';
import models from '@/tools/models';

import GeneralConfig from '@/graphic/components/main/DashboardEditor/configure/GeneralConfig.vue';
import DatasetConfig from '@/graphic/components/main/DashboardEditor/configure/DatasetConfig.vue';
import TableWidgetConfig from '@/graphic/components/main/DashboardEditor/widgets/table/TableWidgetConfig.vue';
import EditableTableConfig from '@/graphic/components/main/DashboardEditor/widgets/editable_table/EditableTableConfig.vue';
import OldTableWidgetConfig from '@/graphic/components/main/DashboardEditor/widgets/oldtable/OldTableWidgetConfig.vue';
import BarChartConfig from '@/graphic/components/main/DashboardEditor/widgets/bar_chart/BarChartConfig.vue';
import LineChartConfig from '@/graphic/components/main/DashboardEditor/widgets/line_chart/LineChartConfig.vue';
import MultiChartConfig from '@/graphic/components/main/DashboardEditor/widgets/multi_chart/MultiChartConfig.vue';
import PieChartConfig from '@/graphic/components/main/DashboardEditor/widgets/pie_chart/PieChartConfig.vue';
import NumberWidgetConfig from '@/graphic/components/main/DashboardEditor/widgets/number/NumberWidgetConfig.vue';
import TextWidgetConfig from '@/graphic/components/main/DashboardEditor/widgets/text/TextWidgetConfig.vue';
import dataTypes from '@/tools/dataTypes';

export default {
  name: 'WidgetConfig',
  components: {
    TextWidgetConfig,
    NumberWidgetConfig,
    BarChartConfig,
    TableWidgetConfig,
    EditableTableConfig,
    OldTableWidgetConfig,
    DatasetConfig,
    GeneralConfig,
    LineChartConfig,
    MultiChartConfig,
    PieChartConfig,
  },
  props: {
    open: {
      type: Boolean,
      required: false,
    },
    widget: {
      type: Object,
    },
    schema: {
      type: Object,
      required: true,
    },
  },
  watch: {
    open: {
      deep: true,
      handler() {
        if (!this.open) this.close();
      },
    },
    widget: {
      deep: true,
      handler() {
        this.localWidget = { ...this.widget };
        this.loadDataset();
      },
    },
  },
  computed: {
    widgetType() {
      return this.widgetTypeMap[this.localWidget?.type];
    },
  },
  mounted() {
    this.localWidget = { ...this.widget };
    this.loadDataset();
  },
  methods: {
    close() {
      this.$emit('close', this.widget);
    },
    async handleDatasetChange(dataset) {
      const cachedConfig = this.localWidget.config[this.localWidget.type];
      const cachedMetas = this.metas;
      this.dataset = dataset;
      await this.loadMetadata();
      if (this.compareName(cachedMetas.columns, this.metas.columns)) {
        this.localWidget.config[this.localWidget.type] = cachedConfig;
      } else {
        this.localWidget.config[this.localWidget.type] = {
          ...models.widgetTypeMap[this.localWidget.type].defaultConfig,
        };
      }
      if (this.localWidget.type === 'editable_table') {
        this.localWidget.config.query = this.localWidget.datasets[0].query;
      }
      await this.save();
    },
    async loadDataset() {
      if (!this.localWidget || !this.localWidget.id) {
        return;
      }
      const datasets = await api.graphic.widget.datasets.list(this.localWidget.id);
      [this.dataset] = datasets;
      await this.loadMetadata();
    },
    async loadMetadata() {
      if (!this.dataset?.id) {
        return;
      }
      const params = dataTypes.buildInitialParamState(this.dataset.params);
      this.metas = await api.graphic.dataset.metadata(this.dataset.id, params);
    },
    compareName(ar1, ar2) {
      if (ar1.length !== ar2.length) return false;
      const p1 = ar1.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      const p2 = ar2.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });

      for (let i = 0; i < ar1.length; i += 1) {
        if (!(p1[i].name === p2[i].name)) {
          return false;
        }
      }
      return true;
    },
    saveDefault() {
      if (this.widget.type !== 'pie_chart') {
        this.widget.config[this.widget.type].filterColumns.forEach((filter) => {
          // eslint-disable-next-line no-param-reassign
          filter.default = filter.actual;
        });
      }
      this.$emit('saveDef', this.widget);
    },
    saveMetaColumns(cols) {
      if (this.widget.type === 'table') {
        const objectColumns = this.localWidget.config.colouredColumns
          ? [...this.localWidget.config.colouredColumns]
          : [];
        cols.forEach((col) => {
          if (col.coloured) {
            if (objectColumns.indexOf(col.name) === -1) objectColumns.push(col.name);
          } else {
            const index = objectColumns.indexOf(col.name);
            if (index > -1) objectColumns.splice(index, 1);
          }
        });
        this.localWidget.config.colouredColumns = objectColumns;
        this.save();
      } else if (this.widget.type === 'editable_table') {
        const objectColumns = this.localWidget.config.editableColumns
          ? [...this.localWidget.config.editableColumns]
          : [];
        const newColumns = [];
        cols.forEach((col) => {
          // if (col.editable) {
          const foundColumn = objectColumns.filter((x) => x.name === col.name)[0];
          if (!foundColumn) {
            newColumns.push({
              editable: col.editable,
              name: col.name,
              realName: col.realName,
              function: col.function ? col.function : '',
              options: col.options ? col.options : '',
              tableName: col.tableName,
              joinParams: col.joinParams ? col.joinParams : '',
            });
          } else {
            newColumns.push({
              editable: col.editable,
              name: foundColumn.name,
              realName: col.realName ? col.realName : foundColumn.name,
              function: col.function ? col.function : '',
              options: col.options ? col.options : '',
              tableName: col.tableName,
              joinParams: col.joinParams ? col.joinParams : '',
            });
          }
          //     foundColumn.editable = col.editable ? col.editable : foundColumn.editable;
          //     foundColumn.realName = col.realName ? col.realName : foundColumn.name;
          //     foundColumn.function = col.function ? col.function : '';
          //     foundColumn.options = col.options ? col.options : '';
          //     foundColumn.tableName = col.tableName;
          //     foundColumn.joinParams = col.joinParams ? col.joinParams : '';
          //   }
          // } else {
          //   const index = objectColumns.findIndex((x) => x.name === col.name);
          //   if (index > -1) objectColumns.splice(index, 1);
          // }
        });
        this.localWidget.config.editableColumns = newColumns;
        this.save();
      }
    },
    handleInput() {
      this.$emit('change', this.localWidget);
    },
    handleChange(widget) {
      this.localWidget = widget;
      this.$emit('change', this.localWidget);
    },
    async save() {
      if (this.saving) {
        return;
      }
      this.saving = true;
      this.localWidget = await api.graphic.widget.update(this.localWidget);
      this.saving = false;
      this.$emit('change', this.localWidget);
    },
  },
  data() {
    return {
      saving: false,
      openedSections: [0],
      widgetTypeMap: models.widgetTypeMap,
      localWidget: null,
      dataset: null,
      metas: null,
    };
  },
};
</script>

<style lang="scss">
.dataset-config-panel {
  .v-expansion-panel-content__wrap {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
