const info = async () => {
  const response = await window.axios.get('/client/user');
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const listDashboards = async () => {
  const response = await window.axios.get('/client/user/dashboard');
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

export default {
  info,
  listDashboards,
};
