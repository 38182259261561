const list = async () => {
  const response = await window.axios.get('/admin/user');
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const create = async (user) => {
  const response = await window.axios.post('/admin/user', user);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const listDashboards = async (userId) => {
  const response = await window.axios.get(`/admin/user/${userId}/dashboards`);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

export default {
  list,
  create,
  listDashboards,
};
