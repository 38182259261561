<template>
  <v-row no-gutters class="table-widget my-0">
    <v-col cols="12" class="table-widget--table-wrap mt-0 mb-n2">
      <v-data-table
        v-if="ready && resultLoaded && !failed"
        dense
        class="cardBackgroundColor"
        :headers="resultHeaders"
        :items="resultValues"
        :loading="loading"
        :options.sync="options"
      >
        <!-- <template v-for="(rh, i) in resultHeaders" v-slot:[`item.${rh.name}`]="{ item }">
          <strong :key="i">{{item.name}}</strong>
        </template> -->
        <template
          v-for="(column, i) in widget.config.colouredColumns"
          v-slot:[`item.${column}`]="{ item }"
        >
          <span
            :class="{
              'red--text text--lighten-2': item[column].toString().includes('-'),
              'green--text text--lighten-2': !item[column].toString().includes('-')
              }"
            :key="i">{{item[column]}}
          </span>
        </template>
        <template v-slot:footer.prepend>
          <v-spacer/>
          <span>Items:</span>
          <v-select
            dense
            hide-details="auto"
            class="my-n3 mx-2 text-caption"
            style="max-width: 80px"
            :items="[5,10,20,50,100,options.itemsPerPage].sort((a,b) => a-b)"
            v-model="options.itemsPerPage"
          />
        </template>
      </v-data-table>
      <v-progress-circular
        v-if="!resultLoaded"
        indeterminate
        color="primary"
        class="justify-center"/>
      <div style="height: 100%;" class="fill-height overflow-hidden"
        v-else-if="failed">
        <v-card
          shaped
          align="center"
          style="margin: 10% 10% 10% 10%;"
          class="error--text text-subtitle-1 pa-4 fluid"
        >
          <div class="font-weight-bold" v-if="adminView"> Impossibile estrarre dati.</div>
          <div  v-if="adminView"> {{queryError}} </div>
          <div  v-if="!adminView"> Errore Interno, contatta l'amministratore. </div>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import WidgetQueryParams from '@/admin/components/main/DashboardEditor/widgets/WidgetQueryParams.vue';

const computeItemPerPage = (height, showFilters) => {
  const availableHeight = height - 37 - (showFilters ? 48 : 0);
  return Math.max(1, Math.floor(availableHeight / 32) - 1);
};

export default {
  name: 'OldTableWidget',
  // eslint-disable-next-line vue/no-unused-components
  components: { WidgetQueryParams },
  props: {
    widget: {
      type: Object,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    filterState: {
      type: Object,
      required: false,
    },
    queryResult: {
      type: Object,
      required: true,
    },
    adminView: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  watch: {
    queryResult: {
      deep: true,
      handler() {
        this.handleData();
      },
    },
    height(newVal) {
      this.options.itemsPerPage = computeItemPerPage(newVal, this.showFilters);
      this.options.page = 1;
      this.handleData();
    },
    filterState: {
      deep: true,
      handler() {
        this.filterData();
      },
    },
    options: {
      handler(newVal, oldVal) {
        if (JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
          this.handleData();
        }
      },
      deep: true,
    },
  },
  computed: {
  },
  mounted() {
    this.ready = true;
    if (!this.queryResult.failed) {
      this.showFilters = this.widget.datasets[0].params.length > 0
        || this.widget.config.table.filtered;
      this.options.itemsPerPage = computeItemPerPage(this.height, this.showFilters);
    }
    this.handleData();
  },
  methods: {
    handleData() {
      if (this.queryResult?.failed) {
        this.queryFailed = true;
        this.queryError = this.queryResult.error;
        this.resultValues = null;
        this.resultHeaders = null;
        this.failed = true;
      } else {
        this.resultHeaders = this.queryResult.metas.columns.map((col) => ({
          text: col.name,
          value: col.name,
        }));
        this.headerPositions = Object.fromEntries(
          this.resultHeaders.map((col, idx) => [col.text, idx + 1]),
        );
        this.resultValues = this.queryResult.values;
        this.filterData();
      }
      this.loading = false;
      this.resultLoaded = true;
    },
    async filterData() {
      this.loading = true;
      if (this.widget.config.table.filtered) {
        this.resultValues = this.queryResult.values.filter((value) => {
          let is = true;
          Object.keys(this.filterState).forEach((filter) => {
            if (this.filterState[filter].length > 0
              && !this.filterState[filter].includes(value[filter])) is = false;
          });
          return is;
        });
      }
      this.loading = false;
    },
  },
  data() {
    return {
      ready: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortByDesc: [],
      },
      resultHeaders: null,
      headerPositions: {},
      resultValues: null,
      loading: false,
      resultLoaded: false,
      failed: false,
      showFilters: false,
    };
  },
};
</script>

<style lang="scss">
  .table-widget {
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    & > .table-widget--table-wrap {
      flex:1
    }
    .v-data-footer__select {
      display: none;
    }
    .v-data-footer__pagination {
      margin-left: auto;
    }
    .v-data-table__wrapper{
      thead{
        th {
          white-space: nowrap;
        }
      }
      tbody {
        td {
          white-space: nowrap;
        }
      }
    }
  }
</style>
