<template>
  <!-- In Widget selector -->
  <v-col cols="6" v-if="metas && !adminPanel">
    <v-autocomplete
      v-model="valuesCols"
      :items="getNumericColumns"
      required
      multiple
      small-chips
      label="Ordinata"
      @change="handleValueChange"/>
  </v-col>
  <!-- Widget Config Section -->
  <v-form v-else ref="form">
    <!-- Axis and rows to show -->
    <v-row>
      <v-col cols="6">
        <v-select
          v-model="value.labelColumn"
          :items="(metas != null ? metas.columns : [])"
          :item-text="(i) => `${i.name} (${i.type})`"
          item-value="name"
          required
          label="Etichetta"
          @change="updateValue"/>
      </v-col>
      <v-col cols="6">
        <div v-for="(item, i) in metas.columns" :key="`value-cb-${i}`">
          <v-checkbox v-if="isNumeric(item)"
            v-model="valuesCols"
            :label="`${item.name} (${item.type})`"
            :value="item.name"
            @change="handleValueChange"/>
        </div>
      </v-col>
    </v-row>
    <!-- Display options -->
    <v-row>
      <v-col cols="4">
        <v-switch v-if="value.values.length>1"
          class="col col-md-6"
          v-model="value.stacked"
          @change="updateValue"
          label="In Pila"
        />
      </v-col>
    </v-row>
    <!-- Filter Config -->
    <v-row>
      <v-col cols="4">
        <v-switch
          v-model="value.filtered"
          @change="updateValue"
          label="Mostra Filtri"/>
      </v-col>
      <v-col cols="8" v-if="value.filtered">
        <v-combobox
          dense
          v-model="value.filterColumns"
          item-text="name"
          item-value="name"
          @change="updateValue"
          :items="metas.columns"
          label="Colonne filtrabili"
          multiple
          small-chips
        />
      </v-col>
    </v-row>
    <v-row>
      <v-spacer/>
      <v-btn class="rounded-pill primary font-weight-bold white--text py-1 px-2 mb-1 mr-3"
        @click="saveDefaults">
        Salva la selezione come Default
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import dataTypes from '@/tools/dataTypes';

export default {
  name: 'LineChartConfig',
  props: {
    adminPanel: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    dataset: {
      type: Object,
      required: true,
    },
    metas: {
      type: Object,
      required: false,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  watch: {
    // Changes on config from outside the widget
    metas: {
      deep: true,
      handler() {
        this.$forceUpdate();
      },
    },
    // Changes on config from query updates
    value: {
      deep: true,
      handler() {
        this.valuesCols = this.value.values;
      },
    },
  },
  mounted() {
  },
  computed: {
    // Returns the list of columns that have numeric values
    getNumericColumns() {
      const filtered = this.metas.columns.filter((item) => this.isNumeric(item));
      return filtered.map((x) => x.name);
    },
  },
  methods: {
    isNumeric: dataTypes.isNumeric,
    // Unused, resets config to empty values
    reset() {
      this.$refs.form.reset();
    },
    updateValue() {
      this.$emit('input', this.value);
    },
    handleValueChange() {
      this.value.values = this.valuesCols;
      this.updateValue();
    },
    // Set the current in-widget selected filter values as its default values
    saveDefaults() {
      this.$emit('saveDef');
    },
  },
  data() {
    return {
      multiValue: this.value.multiValue,
      numericCols: [],
      valuesCols: this.value.values,
    };
  },
};
</script>

<style scoped>

</style>
