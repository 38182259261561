<template>
  <v-app-bar
    app
    clipped-left
    color="secondary"
    class="main-app-bar">
    <img
      src="@/general/VedraiLogo.svg"
      class="pa-3"
      style="cursor: pointer;"
      height="62px"
      @click="redirectHome()"
    />
    <v-spacer />
    <AccountMenu/>
  </v-app-bar>
</template>

<script>
import AccountMenu from '@/general/AccountMenu.vue';

export default {
  name: 'AppBar',
  components: { AccountMenu },
};
</script>

<style lang="scss">
  @import "src/scss/mixins";
  @import "src/scss/variables";
  .main-app-bar {
    @include horizontal_gradient($app-bar-gradient-left, $app-bar-gradient-right, 100%);
    // height: 82px !important;
    .v-toolbar__content {
      padding-left: 0 !important;
      // height: 82px !important;
    }
    .col {
      margin: 0;
      padding: 0;
    }
  }
</style>
