const list = async () => {
  const response = await window.axios.get('/graphic/user/list');
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const info = async () => {
  const response = await window.axios.get('/graphic/user');
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

export default {
  info,
  list,
};
