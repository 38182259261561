const fetch = async (themeId) => {
  const response = await window.axios.get(`/client/theme/${themeId}`);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

export default {
  fetch,
};
