<template>
  <v-row no-gutters class="table-widget">
    <v-col cols="12" class="table-widget--table-wrap">
      <div class="my-2"/>
      <!-- Global Bulk Edit Pop Up -->
      <v-dialog v-model="groupEditDialog" width="1240">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" @click="resetConds()" class="mr-2">
            <v-icon color="secondary">mdi-plus</v-icon>Edit Globale
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Edit globale
          </v-card-title>

          <v-card-text>
            <v-container v-if="resultHeaders && resultHeaders !== null">
              <v-row>
                <!-- Left Conditions Side -->
                <v-col cols=6>
                  <v-row>
                    <v-col cols="6">Colonna</v-col>
                    <v-col cols="6">Equivale a</v-col>
                  </v-row>
                  <v-row v-for="n, i in nConds" :key="n">
                    <!-- Colonna -->
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="chosenColumn[i]"
                        @input="intervalGlobal[i] = false"
                        :items="resultHeaders.slice(0, -1).sort((a,b) => sortText(a,b))"
                      />
                    </v-col>
                    <!-- Equivale a -->
                    <v-col cols="6">
                      <v-autocomplete
                        v-if="!intervalGlobal[i]"
                        :items="getColumnDistinctValues(chosenColumn[i])"
                        :disabled="chosenColumn[i] == null"
                        item-value="val"
                        item-text="text"
                        multiple
                        v-model="chosenColumnValue[i]"
                        :search-input.sync="searchGlobal"
                        @focus="focusGlobal = true"
                        @blur="focusGlobal = false; searchGlobal = ''"
                      >
                        <template v-slot:prepend-inner>
                          <v-tooltip top >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                              v-show="searchGlobal && searchGlobal !== '' && focusGlobal"
                              small
                              icon
                              class="ml-1"
                              v-on="on"
                              v-bind="attrs"
                              @click="pushAll(searchGlobal, chosenColumn[i], chosenColumnValue[i])"
                            >
                              <v-icon color="primary">mdi-checkbox-marked</v-icon>
                            </v-btn>
                            </template>
                            <span>Seleziona tutti</span>
                          </v-tooltip>
                        </template>
                          <template v-slot:append>
                            <v-tooltip top >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  class="mr-1"
                                  icon small
                                  v-on="on"
                                  v-bind="attrs"
                                  @click="searchGlobal = ''; chosenColumnValue[i]=[]"
                                >
                                  <v-icon color="secondary">mdi-eraser-variant</v-icon>
                                </v-btn>
                              </template>
                            <span>Cancella ricerca</span>
                          </v-tooltip>
                        </template>
                        <template v-slot:selection="{ item, index }">
                          <span v-if="index == 0">
                            {{item.text}}
                          </span>
                          <v-chip label x-small v-else-if="index == 1">
                            <span style="font-size: .95rem">
                              +{{chosenColumnValue[i].length - 1}}
                            </span>
                          </v-chip>
                        </template>
                      </v-autocomplete>
                      <v-row v-else class="ma-0 d-flex flex-nowrap">
                        <v-combobox
                          label="Da:"
                          class="mr-1"
                          :items="
                            getColumnDistinctValues(chosenColumn[i], true)
                            .filter((x) => rangeB[i]==='' || parseFloat(x) < parseFloat(rangeB[i]))
                          "
                          v-model="rangeA[i]"
                          :disabled="chosenColumn[i] == null"
                        />
                        <v-combobox
                          label="a:"
                          class="ml-1"
                          :items="
                            getColumnDistinctValues(chosenColumn[i], true)
                            .filter((x) => rangeA[i]==='' || parseFloat(x) > parseFloat(rangeA[i]))
                          "
                          v-model="rangeB[i]"
                          :disabled="chosenColumn[i] == null"
                        />
                      </v-row>
                      <v-switch
                        v-if="isNumeric(chosenColumn[i])"
                        dense
                        label="Intervallo"
                        :disabled="chosenColumn[i] == null"
                        hide-details="auto"
                        v-model="intervalGlobal[i]"
                        @change="resetSides(i)"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <!-- Right Values Side -->
                <v-col cols=6>
                  <v-row>
                    <v-col cols="6">Obiettivo</v-col>
                    <v-col cols="6">Valore</v-col>
                  </v-row>
                  <v-row v-for="n, i in nValues" :key="n">
                    <!-- Obiettivo -->
                    <v-col cols="6">
                      <v-autocomplete
                        :items="getEditableColumns.sort()"
                        v-model="objective[i]"
                      />
                    </v-col>
                    <!-- Valore -->
                    <v-col cols="6">
                      <v-text-field
                        v-if="!getFromHeaderText(objective[i])"
                        v-model="objectiveValue[i]"
                        :disabled="objective[i] == null"
                        :type="isNumeric(objective[i]) ? 'number' : 'text'"
                        @keypress="isInt($event, column.name)"
                      />
                      <v-autocomplete
                        v-else
                        v-model="objectiveValue[i]"
                        :disabled="objective[i] == null"
                        :items="getFromHeaderText(objective[i]).options.split(';')"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols=3>
                  <v-btn @click="nConds += 1" block>
                    <v-icon color="secondary">mdi-plus</v-icon> Aggiungi Cond.
                  </v-btn>
                </v-col>
                <v-col cols=3>
                  <v-btn @click="nConds -= 1" block :disabled="nConds === 1">
                    <v-icon color="secondary">mdi-minus</v-icon> Rimuovi Cond.
                  </v-btn>
                </v-col>
                <v-col cols=3>
                  <v-btn @click="nValues += 1" block>
                    <v-icon color="secondary">mdi-plus</v-icon> Aggiungi Valore
                  </v-btn>
                </v-col>
                <v-col cols=3>
                  <v-btn @click="nValues -= 1" block :disabled="nValues === 1">
                    <v-icon color="secondary">mdi-minus</v-icon> Rimouvi Valore
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" :disabled="updating" text @click="groupEditDialog = false">
              Annulla
            </v-btn>
            <v-progress-circular v-if="updating" color="primary" :size="16" indeterminate/>
            <v-btn color="primary" :disabled="updating" text @click="globalEdit()">
              Aggiorna
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Add Row Pop Up -->
      <v-dialog v-model="addNewRowDialog" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on">
            <v-icon color="secondary">mdi-plus</v-icon>Aggiungi riga
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Aggiungi riga
          </v-card-title>

          <v-card-text>
            <v-container v-if="resultHeaders && resultHeaders !== null">
              <v-row
                v-for="(header, index) in resultHeaders.filter(
                  (x) => x.text !== 'Azioni' && x.text !== 'id'
                )"
                :key="index"
              >
                <v-col cols="12">
                  <v-text-field
                    v-if="!getFromHeader(header)"
                    v-model="newRow[header.text]"
                    :label="header.text"
                    :type="isNumeric(header.text) ? 'number' : 'text'"
                    @keypress="isInt($event, header.text)"
                  ></v-text-field>
                  <v-select
                    v-else
                    :items="getFromHeader(header).options.split(';')"
                    v-model="newRow[header.text]"
                    :label="header.text"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="addNewRowDialog = false">
              Annulla
            </v-btn>
            <v-btn color="primary" text @click="addNewRow()"> Aggiungi </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Save Changes Button -->
      <v-btn @click="saveDataTable" class="ml-2">
        <v-icon color="secondary">mdi-content-save</v-icon>Salva dati
      </v-btn>
      <br/>
      <div class="my-2"/>
      <!-- Table Main Component -->
      <v-data-table
        v-if="ready && resultLoaded && !failed"
        dense
        :headers="resultHeaders"
        :items="resultValues"
        :loading="loading"
        :options.sync="options"
      >
        <template
          v-for="(column, i) in widget.config.editableColumns.filter((x) =>
            typeof x.editable === 'undefined' || x.editable === true
          )"
          v-slot:[`item.${column.name}`]="{ item }"
        >
          <input
            v-if="column.options.length === 0"
            class="text-center"
            :style="'border: 1px solid #ddd; width:'+ maximum(column.name)*8 + 'px'"
            :type="isNumeric(column.name) ? 'number' : 'text'"
            :key="i"
            @keypress="isInt($event, column.name)"
            @change="markUpdate(item, column.name, $event.target.value)"
            :value="item[column.name]"
            @input="(value) => item[column.name] = value.target.value"
          />
          <v-select v-else
            hide-details="auto"
            style="font-size: .5rem"
            dense
            single-line
            :items="column.options.split(';')"
            :key="column.name"
            @change="markUpdate(item, column.name, $event)"
            :value="item[column.name]"
          >
            <template v-slot:label>
              <span class="text-body-2">{{item[column.name]}}</span>
            </template>
            <template v-slot:selection="{ item: val }">
              <span class="text-body-2 mb-1">{{val}}</span>
            </template>
          </v-select>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <button
            type="button"
            class="btn btn-primary"
            @click="markDelete(item)"
          >
            <v-icon
              color="error"
              v-if="
                editedData.filter(
                  (x) => x.id === item.id && x.action === 'delete'
                ).length > 0
              "
            >
              mdi-delete
            </v-icon>
            <v-icon color="primary" v-else>mdi-delete</v-icon>
          </button>
        </template>
        <template v-slot:footer.prepend>
          <v-spacer/>
          <span>Items:</span>
          <v-select
            dense
            hide-details="auto"
            class="my-n3 mx-2 text-caption"
            style="max-width: 80px"
            :items="[5,10,20,50,100,options.itemsPerPage].sort((a,b) => a-b)"
            v-model="options.itemsPerPage"
          />
        </template>
      </v-data-table>
      <!-- Loading Animation -->
      <v-progress-circular
        v-if="!resultLoaded"
        indeterminate
        color="primary"
        class="justify-center"
      />
      <!-- Error Messages -->
      <div
        style="height: 100%"
        class="fill-height overflow-hidden"
        v-else-if="failed"
      >
        <v-card
          shaped
          align="center"
          style="margin: 10% 10% 10% 10%"
          class="error--text text-subtitle-1 pa-4 fluid"
        >
          <div class="font-weight-bold" v-if="adminView">
            Impossibile estrarre dati.
          </div>
          <div v-if="adminView">{{ queryError }}</div>
          <div v-if="!adminView">
            Errore Interno, contatta l'amministratore.
          </div>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import api from '@/api';
import WidgetQueryParams from '@/graphic/components/main/DashboardEditor/widgets/WidgetQueryParams.vue';

const computeItemPerPage = (height, showFilters) => {
  const availableHeight = height - 88 - (showFilters ? 38 : 0);
  return Math.max(1, Math.floor(availableHeight / 32) - 1);
};

export default {
  name: 'EditableTableWidget',
  // eslint-disable-next-line vue/no-unused-components
  components: { WidgetQueryParams },
  props: {
    adminView: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    filterState: {
      type: Object,
      required: false,
    },
    height: {
      type: Number,
      required: true,
    },
    queryResult: {
      type: Object,
      required: true,
    },
    widget: {
      type: Object,
      required: true,
    },
  },
  watch: {
    // Changes on the widget's query
    queryResult: {
      deep: true,
      handler() {
        this.handleData();
      },
    },
    // Changes on the available vertical space of the widget
    height(newVal) {
      this.options.itemsPerPage = computeItemPerPage(newVal, this.showFilters);
      this.options.page = 1;
      this.handleData();
    },
    // Changes on the filter selection
    filterState: {
      deep: true,
      handler() {
        this.filterData();
      },
    },
    // Changes on the filter configuration options
    options: {
      handler(newVal, oldVal) {
        if (JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
          this.handleData();
        }
      },
      deep: true,
    },
  },
  computed: {
    // Get all the editable = true, or undefined rows from the config
    // (Undefined is the accepted model in v1 & v2, current is v3, model auto updates on config open in admin panel)
    getEditableColumns() {
      const editableColumns = [];
      this.widget.config.editableColumns.forEach((x) => {
        if (typeof x.editable === 'undefined' || x.editable === true) editableColumns.push(x.name);
      });
      return editableColumns;
    },
    // Get the first table name from the query text
    tableName() {
      const pattern = /(?<=from|join)(\s+\w+\b)/i;
      const stringQuery = this.widget.config.query;
      const query = stringQuery.match(pattern)[0];
      return query.trim();
    },
  },
  mounted() {
    // Load the widget raw data with widget config
    this.ready = true;
    if (!this.queryResult.failed) {
      this.showFilters = this.widget.datasets[0].params.length > 0
        || this.widget.config.editable_table.filtered;
      this.options.itemsPerPage = computeItemPerPage(
        this.height,
        this.showFilters,
      );
    }
    this.handleData();
  },
  methods: {
    resetConds() {
      this.nConds = 1;
      this.nValues = 1;
      this.chosenColumn = [null];
      this.chosenColumnValue = [[]];
      this.intervalGlobal = [false];
      this.rangeA = [''];
      this.rangeB = [''];
      this.objective = [null];
      this.objectiveValue = [null];
    },
    addCond() {
      this.chosenColumn.push(null);
      this.chosenColumnValue.push([]);
      this.intervalGlobal.push(false);
      this.rangeA.push('');
      this.rangeB.push('');
    },
    addValue() {
      this.objective.push(null);
      this.objectiveValue.push(null);
    },
    removeCond() {
      this.chosenColumn.pop();
      this.chosenColumnValue.pop();
      this.intervalGlobal.pop();
      this.rangeA.pop();
      this.rangeB.pop();
    },
    removeValue() {
      this.objective.pop();
      this.objectiveValue.pop();
    },
    // Temporary format the config into the new V3 datamodel
    updateOldModelWidget() {
      // eslint-disable-next-line max-len
      if (this.widget.config.editableColumns.length > 0 && this.widget.config.editableColumns[0].realName && this.widget.config.editableColumns[0].function) return;
      this.widget.config.editableColumns = this.widget.config.editableColumns.map((col) => (
        {
          editable: col.editable,
          name: col.name,
          realName: col.realName ? col.realName : col.name,
          function: col.function ? col.function : '',
          options: col.options,
          tableName: col.tableName ? col.tableName : this.tableName,
          joinParams: col.joinParams ? col.joinParams : '',
        }
      ));
    },
    // Select all visible options when searching on the autocomplete
    pushAll(searchVal, column, selection) {
      const itemsList = this.getColumnDistinctValues(column).map((x) => x.val);
      itemsList.forEach((item) => {
        if (item.toUpperCase().includes(searchVal.toUpperCase()) && !selection.includes(item)) {
          selection.push(item);
        }
      });
    },
    // Map the in-widget column name to the real DB-table name of a single column
    searchRealValues(name, char) {
      // eslint-disable-next-line consistent-return
      const real = this.widget.config.editableColumns.find((col) => col.name === name);
      if (real) {
        let joinParams = '';
        real.joinParams.split(';').filter((e) => e.trim() !== '').forEach((j) => {
          if (joinParams !== '') joinParams += ' and ';
          joinParams += `${char}.${j.split('=')[0]}=a.${j.split('=')[1].split(':')[0]}`;
        });
        let func;
        if (joinParams.length > 0) func = real.function.replace('@', `${char}.${real.realName}`);
        else func = real.function.replace('@', `@${real.realName}`);
        return {
          name: real.realName,
          function: func,
          table: real.tableName,
          join: joinParams,
        };
      }
      return {
        name,
        function: '',
        table: this.tableName,
        joinParams: [],
      };
    },
    // Build the bulk edit data and send the save message
    async globalEdit() {
      const condition = () => {
        let valid = true;
        this.chosenColumn.forEach((c) => { valid = valid && (c !== null); });
        this.chosenColumnValue.forEach((c) => { valid = valid && (c !== null); });
        this.objective.forEach((c) => { valid = valid && (c !== null); });
        this.objectiveValue.forEach((c) => { valid = valid && (c !== null); });
        return valid;
      };

      if (condition()) {
        this.updateOldModelWidget();
        const realColumn = this.chosenColumn.map((cond) => this.searchRealValues(cond, '#'));
        const realTarget = this.objective.map((obj) => this.searchRealValues(obj, '#'));
        const columnValue = this.intervalGlobal.map((interval, i) => {
          if (interval) return `${this.rangeA[i]} and ${this.rangeB[i]}`;
          return this.chosenColumnValue[i].map((x) => `"${x}"`).join();
        });
        const target = {
          column: realColumn.map((c) => c.name),
          'column-value': columnValue,
          'column-table': realColumn.map((c) => c.table),
          'column-join': realColumn.map((c) => c.join),
          'column-function': realColumn.map((c) => c.function),
          intervals: this.intervalGlobal,
          target: realTarget.map((c) => c.name),
          'target-value': this.objectiveValue,
          'target-table': realTarget.map((c) => c.table),
          'target-join': realTarget.map((c) => c.join),
        };
        console.log(target);
        // this.updating = true;
        try {
          const admin = this.$store.state.session.roles.indexOf('admin') >= 0;
          const datasetEndpoints = admin ? api.graphic.dataset : api.client.dataset;
          await datasetEndpoints.updateTableBulk(
            this.tableName,
            target,
            this.widget.config.schemaId,
          );
          // this.updating = false;
          // this.groupEditDialog = false;
          // window.location.reload();
        } catch (e) {
          // this.updating = false;
          // this.groupEditDialog = false;
          console.error(e);
        }
      } else {
        console.error('error, a value is null');
      }
    },
    // Return a list with all the occurencies of distinct values, or limit values between A and B
    getColumnDistinctValues(column, range) {
      let unique = [];
      if (range) {
        this.resultValues.forEach((item) => {
          if (!unique.some((u) => u.val === item[column])) {
            unique.push(item[column] ? item[column] : 0);
          }
        });
        unique = [...new Set(unique)].sort((a, b) => this.sortNumber(a, b));
      } else {
        unique = [...new Set(this.resultValues.map((item) => ({
          text: this.valid(item[column]) ? item[column] : '- vuoto -',
          val: this.valid(item[column]) ? item[column] : 'NULL',
        })))].sort((a, b) => this.sortText(a, b));
      }
      return unique;
    },
    // IF x is different to [undefined, null, '', 0, false]
    valid(x) {
      if (x !== 0 && !x) return false;
      return true;
    },
    // Extract first coincident name in editable columns and 'header' item
    getFromHeader(header) {
      const col = this.widget.config.editableColumns.filter((x) => header.text === x.name)[0];
      if (col && col.options.length > 0) return col;
      return false;
      // return this.widget.config.editableColumns[index];
    },
    // Extract first coincident name in editable columns and 'header.text' item
    getFromHeaderText(header) {
      const col = this.widget.config.editableColumns.filter((x) => header === x.name)[0];
      if (col && col.options.length > 0) return col;
      return false;
      // return this.widget.config.editableColumns[index];
    },
    // Add an 'insert' command to the actions cache before save
    addNewRow() {
      this.resultValues.unshift({ ...this.newRow });
      this.editedData.push({
        newItem: { ...this.newRow },
        action: 'insert',
      });
      this.addNewRowDialog = false;
    },
    // Get the maximum of an object in 'name' field
    maximum(name) {
      let max = 10;
      this.resultValues.forEach((item) => {
        if (String(item[name]).length > max) max = String(item[name]).length;
      });
      return max;
    },
    // Save the single 'insert', 'update' and 'delete' actions cache
    async saveDataTable() {
      this.loading = true;
      const admin = this.$store.state.session.roles.indexOf('admin') >= 0;
      const datasetEndpoints = admin ? api.graphic.dataset : api.client.dataset;

      this.updateOldModelWidget();

      const rowsToEdit = [];
      this.editedData.forEach((edited) => {
        const objs = {};
        const params = {};
        this.widget.config.editableColumns
          .filter((x) => typeof x.editable === 'undefined' || x.editable === true)
          .forEach((col) => {
            if (!objs[col.tableName]) objs[col.tableName] = {};
            objs[col.tableName][col.realName] = edited.newItem[col.name];
            params[col.tableName] = this.formatJoin(col.joinParams, edited.newItem);
          });
        Object.keys(objs).forEach((tableName) => {
          rowsToEdit.push({
            action: edited.action,
            id: edited.id,
            tableName,
            joinParams: params[tableName],
            newItem: objs[tableName],
          });
        });
      });

      // eslint-disable-next-line no-restricted-syntax
      for (const res of rowsToEdit.filter((x) => x.action === 'delete')) {
        // FIXME delete on different Tables
        if (res.tableName === this.tableName) {
          // eslint-disable-next-line no-await-in-loop
          await datasetEndpoints.deleteTableRow(
            res.tableName,
            res.id,
            this.widget.config.schemaId,
          );
        }
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const res of rowsToEdit.filter((x) => x.action === 'update')) {
        // eslint-disable-next-line no-await-in-loop
        await datasetEndpoints.updateTableRow(
          res.tableName,
          res.id,
          { joinParams: res.joinParams, itemValues: res.newItem },
          this.widget.config.schemaId,
        );
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const res of rowsToEdit.filter((x) => x.action === 'insert')) {
        // FIXME insert on different Tables
        if (res.tableName === this.tableName) {
          // eslint-disable-next-line no-await-in-loop
          await datasetEndpoints.insertTableRow(
            res.tableName,
            res.newItem,
            this.widget.config.schemaId,
          );
        }
      }
      window.location.reload();
    },
    // Format the query string
    formatJoin(text, item) {
      const params = text.split(';').filter((e) => e.trim() !== '');
      return params.map((p) => {
        const temp = p.split('=');
        // On ALIAS set
        if (temp[1].split(':').length > 1) return `${temp[0]}='${item[temp[1].split(':')[1]]}'`;
        // Without alias
        return `${temp[0]}='${item[temp[1]]}'`;
      });
    },
    // Add an 'delete' command to the actions cache before save
    markDelete(item) {
      if (this.editedData.filter((x) => x.id === item.id).length > 0) {
        this.editedData = this.editedData.filter((x) => x.id !== item.id);
      } else {
        this.editedData.push({
          id: item.id,
          action: 'delete',
        });
      }
    },
    // Add an 'update' command to the actions cache before save
    markUpdate(item, key, value) {
      const newItem = { ...item };
      newItem[key] = value;
      const editedData = this.editedData.filter((x) => x.id === item.id);
      if (editedData.length <= 0) {
        this.editedData.push({
          id: item.id,
          newItem,
          action: 'update',
        });
      } else {
        editedData[0].newItem = newItem;
      }
    },
    // Chech if @name column meta values are numeric
    isNumeric(name) {
      const el = this.queryResult.metas.columns.filter((x) => x.name === name)[0];
      if (!el || el === null) return false;
      return (
        el.type.includes('FLOAT')
        || el.type.includes('DOUBLE')
        || el.type.includes('INT')
      );
    },
    // Chech if @name column meta values is INT
    isInt(event, name) {
      const el = this.queryResult.metas.columns.filter((x) => x.name === name)[0];
      if (!el || el === null) return false;
      if (el.type.includes('INT')) {
        const e = event || window.event;
        const charCode = e.which ? e.which : e.keyCode;
        if (charCode === 46) {
          e.preventDefault();
        } else {
          return true;
        }
      }
      return true;
    },
    // Load and process filter data with configurations
    handleData() {
      if (this.queryResult?.failed) {
        this.queryFailed = true;
        this.queryError = this.queryResult.error;
        this.resultValues = null;
        this.resultHeaders = null;
        this.failed = true;
      } else {
        this.resultHeaders = this.queryResult.metas.columns.map((col) => ({
          text: col.name,
          value: col.name,
        }));
        this.resultHeaders.push({
          text: 'Azioni',
          value: 'actions',
        });
        this.headerPositions = Object.fromEntries(
          this.resultHeaders.map((col, idx) => [col.text, idx + 1]),
        );
        this.resultValues = this.queryResult.values;
        this.filterData();
      }
      this.loading = false;
      this.resultLoaded = true;
    },
    // Filter de raw data list according to filter selections
    async filterData() {
      this.loading = true;
      if (this.widget.config.editable_table.filtered) {
        this.resultValues = this.queryResult.values.filter((value) => {
          let is = true;
          Object.keys(this.filterState).forEach((filter) => {
            if (
              this.filterState[filter].length > 0
              && !this.filterState[filter].includes(value[filter])
            ) { is = false; }
          });
          return is;
        });
      }
      this.loading = false;
    },
    sortText(a, b) {
      if (a.text === '- vuoto -') return -1;
      if (b.text === '- vuoto -') return 1;
      if (a.text > b.text) return 1;
      if (a.text < b.text) return -1;
      return 0;
    },
    sortNumber(a, b) {
      const x = parseFloat(a);
      const y = parseFloat(b);
      if (x > y) return 1;
      if (x < y) return -1;
      return 0;
    },
    // Reset Global update values
    resetSides(i) {
      this.chosenColumnValue[i] = [];
      this.rangeA[i] = '';
      this.rangeB[i] = '';
    },
  },
  data() {
    return {
      // LINKER
      addNewRowDialog: false,
      ready: false,
      chosenColumn: [null],
      chosenColumnValue: [[]],
      editedData: [],
      failed: false,
      focusGlobal: false,
      groupEditDialog: false,
      headerPositions: {},
      intervalGlobal: [false],
      loading: false,
      newRow: [],
      objective: [null],
      objectiveValue: [null],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortByDesc: [],
      },
      rangeA: '',
      rangeB: '',
      resultHeaders: null,
      resultValues: null,
      resultLoaded: false,
      searchGlobal: '',
      showFilters: false,
      totalRowCount: null,
      updating: false,
      nConds: 1,
      nValues: 1,
    };
  },
};
</script>

<style lang="scss">
.table-widget {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  & > .table-widget--table-wrap {
    flex: 1;
  }
  .v-data-footer__select {
    display: none;
  }
  .v-data-footer__pagination {
    margin-left: auto;
  }
  .v-data-table__wrapper {
    thead {
      th {
        white-space: nowrap;
      }
    }
    tbody {
      td {
        white-space: nowrap;
      }
    }
  }
}
</style>
