// Empty widget initial configurations for new Itemas

const widgetTypes = [
  {
    type: 'text',
    icon: 'mdi-text',
    displayName: 'Testo',
    defaultConfig: {
      text: '',
    },
  },
  {
    type: 'number',
    icon: 'mdi-numeric',
    displayName: 'Numero',
    defaultConfig: {
      filtered: false,
      filterColumns: [],
      labels: null,
      value1: null,
      value2: null,
      labelVal1: 'Actual',
      labelVal2: 'Forecast',
      numeric: false,
      detailed: false,
      hideVariation: false,
      symbol: '',
    },
  },
  {
    type: 'table',
    icon: 'mdi-table',
    displayName: 'Tabella',
    defaultConfig: {
      filtered: false,
      filterColumns: [],
    },
  },
  {
    type: 'editable_table',
    icon: 'mdi-table',
    displayName: 'Tabella Editabile',
    defaultConfig: {
      filtered: false,
      filterColumns: [],
      query: '',
      schemaId: -1,
    },
  },
  {
    type: 'pie_chart',
    icon: 'mdi-chart-arc',
    displayName: 'Grafico - Torta',
    defaultConfig: {
      filtered: false,
      filterColumns: [],
      labels: null,
      values: null,
      donut: false,
    },
  },
  {
    type: 'bar_chart',
    displayName: 'Grafico - Istogramma',
    icon: 'mdi-poll',
    defaultConfig: {
      filtered: false,
      filterColumns: [],
      stacked: false,
      multiline: false,
      values: [],
      labelColumn: null,
      groupColumn: null,
      horizontal: false,
    },
  },
  {
    type: 'line_chart',
    icon: 'mdi-chart-line',
    displayName: 'Grafico - Linea',
    defaultConfig: {
      filtered: false,
      filterColumns: [],
      multiline: false,
      values: [],
      labelColumn: null,
      groupColumn: null,
      tension: 0.1,
      fill: false,
    },
  },
  {
    type: 'multi_chart',
    icon: 'mdi-chart-areaspline',
    displayName: 'Grafico - MultiLinea',
    defaultConfig: {
      filtered: false,
      filterColumns: [],
      multiline: false,
      values: [],
      labelColumn: null,
      groupColumn: null,
      tension: 0.1,
      order: false,
      name1: 1,
      name2: 2,
      fill: false,
    },
  },
];

const widgetTypesGraphic = widgetTypes.filter((x) => (!['editable_table', 'multi_chart'].includes(x.type)));

const widgetTypeMap = widgetTypes.reduce((acc, o) => {
  acc[o.type] = o;
  return acc;
}, {});

const widgetConfigValidators = {
  text: (widget) => {
    if (!widget?.config?.text) {
      return ['Configurazione non valida'];
    }
    return !widget.config.text.text ? ['Inserire un testo'] : [];
  },
  bar_chart: (widget) => {
    if (!widget?.config?.bar_chart) {
      return ['Configurazione non valida'];
    }
    const errors = [];
    if (!widget.config.bar_chart.labelColumn) {
      errors.push('Scegliere la colonna da mostrare sull\'asse X');
    }
    if ((widget.config.bar_chart.values?.length || 0) === 0) {
      errors.push('Scegliere almento un valore da mostrare sull\'asse Y');
    }
    return errors;
  },
  pie_chart: (widget) => {
    if (!widget?.config?.pie_chart) {
      return ['Configurazione non valida'];
    }
    const errors = [];
    if (!widget.config.pie_chart.labels) {
      errors.push('Schegliere una colonna da usare come etichetta');
    }
    if ((widget.config.pie_chart.values?.length || 0) === 0) {
      errors.push('Scegliere una colonna contentente i valori da mostrare');
    }
    return errors;
  },
  number: (widget) => {
    if (!widget?.config?.number) {
      return ['Configurazione non valida'];
    }
    return [];
  },
  line_chart: (widget) => {
    if (!widget?.config?.line_chart) {
      return ['Configurazione non valida'];
    }
    const errors = [];
    return errors;
  },
  editable_table: (widget) => {
    if (!widget?.config?.editable_table) {
      return ['Configurazione non valida'];
    }
    const errors = [];
    return errors;
  },
};

const widgetModel = {
  type: '',
  title: 'Widget',
  description: '',
  config: {
    cols: 3,
    height: 3,
  },
};

const datasetModel = {
  label: '',
  query: '',
  params: [],
};

export default {
  widgetTypes,
  widgetTypesGraphic,
  widgetModel,
  datasetModel,
  widgetTypeMap,
  widgetConfigValidators,
};
