<template>
  <v-row no-gutters>
          <v-tabs v-model="tab" background-color="primary" dark >
            <v-tab key="code">Codice</v-tab>
            <v-tab key="preview">Anteprima</v-tab>
            <v-tab-item key="code">
                <codemirror
                  ref="codemirror"
                  v-model="value.text"
                  @input="handleChange"
                  :options="cmOptions"/>
            </v-tab-item>
            <v-tab-item key="preview">
                <div class="text-widget" style="min-height: 100px" v-html="markdown"/>
            </v-tab-item>
          </v-tabs>
  </v-row>
</template>

<script>
import marked from 'marked';

export default {
  name: 'TextWidgetConfig',
  components: { },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
  },
  methods: {
    handleChange() {
      this.markdown = marked(this.value?.text || '');
      this.$emit('input', this.value);
    },
  },
  data() {
    return {
      tab: 'code',
      markdown: marked(this.value?.text || ''),
      cmOptions: {
        matchBrackets: true,
        showCursorWhenSelecting: true,
        height: 'auto',
        width: '752',
        mode: 'text/x-markdown',
        theme: 'mbo',
        smartIndent: true,
        line: true,
        fixedGutter: false,
        lineNumbers: true,
        autoRefresh: true,
        lineWrapping: true,
        htmlMode: true,
        matchClosing: true,
        indentWithTabs: true,
      },
    };
  },
};
</script>

<style scoped>

</style>
