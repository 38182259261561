const fetchData = async (datasetId, params = {}) => {
  const response = await window.axios.get(`/client/dataset/${datasetId}/data`, { params });
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const insertTableRow = async (tableName, item, schemaId) => {
  const response = await window.axios.post(`/client/dataset/insert/${tableName}/${schemaId}`, item);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const updateTableRow = async (tableName, id, item, schemaId) => {
  const response = await window.axios.post(`/client/dataset/update/${tableName}/${id}/${schemaId}`, item);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const updateTableBulk = async (tableName, item, schemaId) => {
  const response = await window.axios.post(`/client/dataset/update-bulk/${tableName}/${schemaId}`, item);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const deleteTableRow = async (tableName, id, schemaId) => {
  const response = await window.axios.post(`/client/dataset/delete/${tableName}/${id}/${schemaId}`);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

export default {
  fetchData,
  updateTableBulk,
  insertTableRow,
  updateTableRow,
  deleteTableRow,
};
