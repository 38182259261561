/* eslint-disable import/no-extraneous-dependencies */
import Vue from 'vue';
import '@/plugins/axios';
import '@/plugins/logger';
import Keycloak from 'keycloak-js';

import VueCodemirror from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/mbo.css';
import 'codemirror/addon/hint/show-hint.css';

import 'codemirror/mode/markdown/markdown';
import 'codemirror/mode/sql/sql';
import 'codemirror/addon/selection/active-line';
import 'codemirror/addon/hint/sql-hint';
import 'codemirror/addon/hint/show-hint';

import VueRouter from 'vue-router';
import models from '@/tools/models';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import api from '@/api';

import AdminApp from '@/admin/AdminApp.vue';
import adminRouter from '@/admin/router';

import GraphicApp from '@/graphic/GraphicApp.vue';
import graphicRouter from '@/graphic/router';

import UserApp from '@/user/UserApp.vue';
import DashboardViewer from '@/user/views/DashboardViewer.vue';
import HomeView from '@/user/views/HomeView.vue';
import WidgetView from '@/user/views/WidgetView.vue';

import ErrorApp from '@/error/ErrorApp.vue';
import ErrorView from '@/error/MainView.vue';

Vue.use(VueCodemirror);
Vue.config.productionTip = false;

// Load Keycloak settings from .env file
const keyCloakOptions = {
  url: process.env.VUE_APP_KEYCLOAK_URL,
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENTID,
  onLoad: 'login-required',
  checkLoginIframe: false,
};

const keycloak = Keycloak(keyCloakOptions);
Vue.prototype.$keycloak = keycloak;

// Load the user info onto Vuex
const storeAuthData = () => {
  const payload = {
    idToken: keycloak.idToken,
    accessToken: keycloak.token,
    roles: keycloak.realmAccess.roles,
  };
  if (payload.idToken !== '' && payload.accessToken !== '') {
    store.commit('auth', payload);
  } else {
    store.commit('logout');
    Vue.$log.error('logout detected');
    window.location.reload();
  }
};

const setStandardHome = (routes) => {
  routes.unshift({
    path: '/',
    name: 'Home',
    component: HomeView,
    replace: true,
    meta: {
      icon: 'mdi-home',
    },
  });
  routes.push({
    path: '*',
    name: 'Home',
    component: HomeView,
    replace: true,
    meta: {
      icon: 'mdi-home',
      hidden: true,
    },
  });
};

// Load error App
const mountErrorApp = async () => {
  Vue.use(VueRouter);
  const routes = [{
    path: '*',
    name: 'Home',
    component: ErrorView,
    replace: true,
    meta: {
      icon: 'mdi-home',
      hidden: true,
    },
  }];
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
  });
  new Vue({
    router,
    vuetify,
    render: (h) => h(ErrorApp, { props: { keycloak } }),
  }).$mount('#app');
  document.title = 'Monitor';
};

const mountAdminApp = async () => {
  new Vue({
    router: adminRouter,
    store,
    vuetify,
    render: (h) => h(AdminApp, { props: { keycloak } }),
  }).$mount('#app');
  document.title = 'Dashboards Monitor Admin';
};

const mountGraphicApp = async () => {
  const userInfo = await api.graphic.user.info();
  store.commit('setUser', userInfo);

  new Vue({
    router: graphicRouter,
    store,
    vuetify,
    render: (h) => h(GraphicApp, { props: { keycloak } }),
  }).$mount('#app');
  document.title = 'Dashboards Monitor Editor';
};

// Try to load a user Dashboard, if something fails, load Error App instead
const mountUserApp = async () => {
  try {
    const userInfo = await api.client.user.info();
    const dashboards = await api.client.user.listDashboards();
    const theme = await api.client.theme.fetch(userInfo.themeId);

    const fetchLogo = async () => {
      if (theme.logo != null) {
        try {
          const base64 = await fetch(`data:@file/png;base64,${theme.logo}`);
          const logo = URL.createObjectURL(await base64.blob());
          store.commit('setLogo', logo);
          return;
        // eslint-disable-next-line no-empty
        } catch (e) { }
      }
      // eslint-disable-next-line global-require
      const logo = await require('@/assets/vedrai-logo.png');
      store.commit('setLogo', logo);
    };
    fetchLogo();

    store.commit('setUser', userInfo);
    store.commit('setDashboards', dashboards);
    store.commit('setTheme', theme);
    Vue.use(VueRouter);
    const routes = [];

    dashboards.forEach((dashboard) => {
      routes.push({
        path: `/dashboard-${dashboard.id}/`,
        name: `${dashboard.displayName}`,
        component: DashboardViewer,
        replace: true,
        meta: {
          dashboard,
          icon: 'mdi-monitor-dashboard',
        },
      });
    });
    routes.sort((a, b) => {
      if (a.meta.dashboard.order < b.meta.dashboard.order) {
        return -1;
      }
      if (a.meta.dashboard.order > b.meta.dashboard.order) {
        return 1;
      }
      if (a.meta.dashboard.id < b.meta.dashboard.id) {
        return -1;
      }
      if (a.meta.dashboard.id > b.meta.dashboard.id) {
        return 1;
      }
      return 0;
    });
    dashboards.forEach((dashboard) => {
      dashboard.widgets.forEach((widget) => {
        const ico = models.widgetTypes.find((o) => o.type === widget.type).icon;
        routes.push({
          path: `/dashboard-${dashboard.id}/widget-${widget.id}`,
          name: `${widget.title}#${widget.id}`,
          component: WidgetView,
          replace: true,
          meta: {
            parent: dashboard.id,
            widget,
            icon: ico,
            hidden: widget.showOnHome,
          },
        });
      });
    });

    if (userInfo.userName === 'euroristoro') {
      routes.push({
        path: '*',
        meta: { hidden: true },
        beforeEnter: (to, from, next) => { next('/dashboard-100/'); },
      });
    } else {
      setStandardHome(routes);
    }
    const router = new VueRouter({
      mode: 'history',
      base: process.env.BASE_URL,
      routes,
    });
    new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(UserApp, { props: { keycloak } }),
    }).$mount('#app');
    document.title = 'Monitor';
  } catch {
    mountErrorApp();
  }
};

// Login with Keycloak, if admin, Load admin app
keycloak.init(keyCloakOptions)
  .then(() => {
    storeAuthData();
    if (keycloak.hasRealmRole('admin')) {
      mountAdminApp();
    } else if (keycloak.hasRealmRole('graphic-editor')) {
      mountGraphicApp();
    } else {
      mountUserApp();
    }
    setInterval(() => {
      keycloak.updateToken(70)
        .then(storeAuthData)
        .catch(() => {
          Vue.$log.error('Failed to refresh token');
          window.location.reload();
        });
    }, 30000);
  })
  .catch(() => {
    Vue.$log.error('Authenticated Failed');
    window.location.reload();
  });
