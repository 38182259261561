<script>
import { HorizontalBar, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;
export default {
  extends: HorizontalBar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    height: {
      type: Number,
    },
  },
  watch: {
    options: {
      deep: true,
      handler(newVal) {
        // eslint-disable-next-line no-underscore-dangle
        this._data._chart.destroy();
        setTimeout(() => this.renderChart(this.chartData, newVal), 10);
      },
    },
    chartData: {
      deep: true,
      handler(newVal) {
        // eslint-disable-next-line no-underscore-dangle
        this._data._chart.destroy();
        setTimeout(() => this.renderChart(newVal, this.options), 10);
      },
    },
    height() {
      // eslint-disable-next-line no-underscore-dangle
      this._data._chart.destroy();
      setTimeout(() => this.renderChart(this.chartData, this.options), 10);
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  methods: {
    reRender() {
      // eslint-disable-next-line no-underscore-dangle
      this._data._chart.destroy();
      this.renderChart(this.chartData, this.options);
    },
  },
};
</script>
