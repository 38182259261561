<template>
    <v-card-text class="pt-3">
      <v-text-field
        dense
        v-model="localDataset.label"
        label="Label"
        required
        @change="$emit('modified', localDataset)"
        :rules="[(v) => !!v || 'Inserire Label']"/>
      <span class="text-subtitle-1 mt-3">Query:</span>
      <QueryInput v-if="schema"
                  v-model="localDataset.query"
                  :schema="schema"
                  @input="handleQueryChange"
                  @run="executeQuery"/>
      <div v-if="queryChanged" class="mt-3">
        <v-row>
          <v-col>
            <span class="text-subtitle-1">
              Risultati obsoleti
            </span>
          </v-col>
          <v-spacer />
          <v-btn icon :loading="loadingResults" color="primary" @click="executeQuery">
            <v-icon class="mdi mdi-refresh"/>
          </v-btn>
        </v-row>
      </div>
      <DatasetParamsConfig v-model="localDataset.params" @change="buildParamState"/>
      <v-data-table
        class="mt-10"
        dense
        v-if="resultValues"
        :headers="resultHeaders"
        :items="resultValues"
        :loading="loadingResults"/>
      <div v-if="queryFailed" class="mt-3">
        <span class="text-subtitle-1 error--text">
          <v-icon class="mdi mdi-alert-circle-outline" color="error"/>
          Errore durante l'esecuzione della query:
          <b>{{queryError}}</b>
        </span>
      </div>
      <v-dialog v-model="paramSelectionDialog" width="500">
        <DatasetParamsValues
                v-if="localDataset.params"
                :params="localDataset.params"
                v-model="paramState"
                @ok="handleParameterSet"
        />
      </v-dialog>
    </v-card-text>
</template>

<script>
import QueryInput from '@/admin/components/main/DashboardEditor/dataset/QueryInput.vue';
import api from '@/api';
import dataset from '@/api/admin/dataset';
import DatasetParamsConfig from '@/admin/components/main/DashboardEditor/dataset/DatasetParamsConfig.vue';
import DatasetParamsValues from '@/admin/components/main/DashboardEditor/dataset/DatasetParamsValues.vue';

export default {
  name: 'DatasetEditDialog',
  components: { DatasetParamsValues, DatasetParamsConfig, QueryInput },
  props: {
    dataset: {
      type: Object,
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
    widget: {
      type: Object,
      required: false,
    },
  },
  watch: {
    dataset(newVal) {
      this.localDataset = { ...newVal, widgetId: this.widget?.id };
    },
  },
  methods: {
    reset() {
      this.localDataset = { ...dataset, widgetId: this.widget?.id, query: '' };
      this.queryChanged = false;
      this.queryFailed = false;
      this.queryError = null;
      this.loadingResults = false;
      this.paramState = {};
    },
    handleQueryChange(value, params) {
      this.queryChanged = true;
      if (params && params.length) {
        const existing = Object.fromEntries(this.localDataset.params.map((p) => [p.name, p]));
        this.localDataset.params = params.map((name) => {
          const existingConfig = existing[name];
          const newConfig = {
            displayAs: name,
            name,
            defaultValue: '',
            optional: false,
            type: 'text',
          };
          if (existingConfig) return existingConfig;
          return newConfig;
        });
        this.paramState = Object.fromEntries(Object.keys(params).map((p) => [p, '']));
      } else {
        this.localDataset.params = [];
        this.paramState = {};
      }
      this.$emit('modified', this.localDataset);
    },
    addAllowedValue(param) {
      param.allowedValues.push({ displayAs: '', expression: '' });
    },
    removeAllowedValue(param, idx) {
      param.allowedValues.splice(idx, 1);
    },
    async handleParameterSet() {
      this.paramSelectionDialog = false;
      await this.testQuery();
    },
    async executeQuery() {
      if (this.localDataset.params.length === 0) {
        await this.testQuery();
      } else {
        this.paramSelectionDialog = true;
      }
    },
    async testQuery() {
      if (this.loadingResults) {
        return;
      }
      this.queryChanged = false;
      this.queryFailed = false;
      this.queryError = null;
      this.loadingResults = true;
      const data = await api.admin.dataset.test(this.localDataset, this.paramState);
      if (data.failed) {
        this.queryFailed = true;
        this.queryError = data.error;
        this.resultValues = null;
        this.resultHeaders = null;
        this.$emit('metaRefresh', null);
      } else {
        this.resultHeaders = data.metas.columns.map((col) => ({
          text: `${col.name} (${col.type})`,
          value: col.name,
        }));
        this.resultValues = data.values;
        this.$emit('metaRefresh', data.metas);
      }
      this.loadingResults = false;
    },
    buildParamState() {
      if (this.localDataset.params.length === 0) {
        return;
      }
      this.paramState = {};
      this.localDataset.params.forEach((param) => {
        if (param.type === 'query_expression') {
          this.paramState[param.name] = param.allowedValues[0]?.expression;
        } else if (param.defaultValue) {
          this.paramState[param.name] = param.defaultValue;
        }
      });
    },
  },
  data() {
    return {
      loadingResults: false,
      resultHeaders: null,
      resultValues: null,
      dialog: false,
      queryChanged: false,
      queryFailed: false,
      queryError: false,
      localDataset: { ...this.dataset, widgetId: this.widget?.id },
      saving: false,
      paramState: {},
      paramSelectionDialog: false,
    };
  },
};
</script>

<style lang="scss">
.CodeMirror-hints {
  z-index: 300;
}
.param-config {
  .v-expansion-panel-content__wrap {
    padding: 0!important;
  }
  .v-expansion-panel-header--active{
    padding: 0!important;
  }
}
</style>
