<template>
  <!-- Main Screen Dashboard Create Button -->
  <v-col md="4">
    <v-hover v-if="!open"  v-slot="{ hover }">
      <v-card class="dashboard-card"
              :color="hover ? 'light-blue lighten-5' : 'white'"
              @click="initCreator()"
      >
        <v-card-text class="row dashboard-creator-body no-gutters"
                     align="center"
                     justify="center">
          <v-row align="center" justify="center">
            <v-icon color="primary" class="mdi mdi-plus" large />
          </v-row>
        </v-card-text>
      </v-card>
    </v-hover>
    <v-card v-else>
      <v-col cols="12">
        <v-form v-model="valid">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="model.displayName"
                  :rules="nameRules"
                  label="Nome"
                  hint="Il nome che verrà mostrato agli utenti"
                  required
                />
                <v-select
                  v-model="model.schemaId"
                  :items="schemas"
                  item-text="displayName"
                  item-value="id"
                  required
                  label="Schema DB"
                  hint="Lo schema DB usato da questa dashboard"
                  :loading="loadingSchemas"
                  :rules="schemaRules"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
      <v-card-actions>
        <v-btn color="error" @click="cancel">Cancel</v-btn>
        <v-spacer />
        <v-btn color="info" :disabled="!valid" @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>

  </v-col>
</template>

<script>
import api from '@/api';

export default {
  name: 'DashboardCreator',
  mounted() {
    this.loadDatabaseSchemas();
  },
  methods: {
    async loadDatabaseSchemas() {
      this.loadingSchemas = true;
      this.schemas = await api.admin.databaseSchema.list();
      this.loadingSchemas = false;
    },
    initCreator() {
      this.open = true;
      this.model = {
        displayName: '',
        schemaId: null,
        icon: 'mdi-monitor-dashboard',
      };
    },
    async save() {
      const newDashboard = await api.admin.dashboard.create(this.model);
      this.open = false;
      this.model = false;
      this.$emit('created', newDashboard);
    },
    cancel() {
      this.open = false;
      this.model = null;
    },
  },
  data() {
    return {
      valid: null,
      schemas: null,
      loadingSchemas: false,
      model: null,
      open: false,
      nameRules: [
        (v) => !!v || 'Inserire Nome',
      ],
      schemaRules: [
        (v) => !!v || 'Selezionare uno schema',
      ],
    };
  },
};
</script>

<style lang="scss">
.dashboard-card {
  cursor: pointer;
}
.dashboard-creator-body {
  min-height: 100px;
}
</style>
