<template>
  <v-expansion-panel-content>
    <v-card tile flat class="pa-0" v-if="metas">
      <TextWidgetConfig v-if="type==='text'"
                        :dataset="dataset"
                        v-model="value.text"
                        :metas="metas"
                        @input="$emit('input', value)"/>
      <NumberWidgetConfig v-if="type==='number'"
                      :dataset="dataset"
                      v-model="value.number"
                      :metas="metas"
                      @input="$emit('input', value)"/>
      <PieChartConfig v-if="type==='pie_chart'"
                      :dataset="dataset"
                      v-model="value.pie_chart"
                      :metas="metas"
                      @input="$emit('input', value)"/>
      <BarChartConfig v-if="type==='bar_chart'"
                      :dataset="dataset"
                      v-model="value.bar_chart"
                      :metas="metas"
                      @input="$emit('input', value)"/>
      <LineChartConfig v-if="type==='line_chart'"
                       :dataset="dataset"
                       v-model="value.line_chart"
                       :metas="metas"
                       @input="$emit('input', value)"/>
      <MultiChartConfig v-if="type==='multi_chart'"
                       :dataset="dataset"
                       v-model="value.multi_chart"
                       :metas="metas"
                       @input="$emit('input', value)"/>
      <EditableTableConfig v-if="type==='editable_table'"
                        :dataset="dataset"
                        v-model="value.editable_table"
                        :metas="metas"
                        @input="$emit('input', value)"/>
    </v-card>
  </v-expansion-panel-content>
</template>

<script>
import BarChartConfig from '@/graphic/components/main/DashboardEditor/widgets/bar_chart/BarChartConfig.vue';
import TextWidgetConfig from '@/graphic/components/main/DashboardEditor/widgets/text/TextWidgetConfig.vue';
import NumberWidgetConfig from '@/graphic/components/main/DashboardEditor/widgets/number/NumberWidgetConfig.vue';
import LineChartConfig from '@/graphic/components/main/DashboardEditor/widgets/line_chart/LineChartConfig.vue';
import MultiChartConfig from '@/graphic/components/main/DashboardEditor/widgets/multi_chart/MultiChartConfig.vue';
import PieChartConfig from '@/graphic/components/main/DashboardEditor/widgets/pie_chart/PieChartConfig.vue';
import EditableTableConfig from '@/graphic/components/main/DashboardEditor/widgets/editable_table/EditableTableConfig.vue';

export default {
  name: 'WidgetConfigStep',
  components: {
    NumberWidgetConfig,
    BarChartConfig,
    PieChartConfig,
    LineChartConfig,
    MultiChartConfig,
    TextWidgetConfig,
    EditableTableConfig,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    dataset: {
      type: Object,
      required: true,
    },
    metas: {
      type: Object,
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
  },
  mounted() {
  },
};
</script>

<style scoped>

</style>
