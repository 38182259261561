let colorList = [
  '#6171DC',
  '#F655AB',
  '#FF7D48',
  '#35d1ad',
  '#062b5e',
  '#cd543c',
  '#ffc857',
  '#985f99',
  '#04724d',
  '#008BF8',
  '#540804',
  '#f49301',
  '#caa8f5',
  '#107793',
  '#4bb3fd',
  '#AE0A5F',
  '#FFDB70',
  '#516C7B',
  '#7CA6CB',
  '#C06E52',
  '#C5CC00',
];

const romaColorList = [
  '#de900b',
  '#980328',
  '#35d1ad',
  '#062b5e',
  '#cd543c',
  '#ffc857',
  '#985f99',
  '#04724d',
  '#008BF8',
  '#540804',
  '#f49301',
  '#caa8f5',
  '#107793',
  '#4bb3fd',
  '#AE0A5F',
  '#FFDB70',
  '#516C7B',
  '#7CA6CB',
  '#C06E52',
  '#C5CC00',
];

const setRomaColorTheme = (string) => {
  if (string === 'roma') colorList = romaColorList;
};

const setColorTheme = (array) => {
  colorList = array;
};

export default {
  list: colorList,
  getColor: (i) => colorList[i % colorList.length],
  setColors: setColorTheme,
  setRomaColors: setRomaColorTheme,
};
