<template>
  <div>
    <!-- Display of chart if there are no errors -->
    <div v-if="dataLoaded && !failed">
      <BarChartHorizontal
        v-if="config && config.horizontal"
        ref="chart"
        :height="height -(config.filtered? 46 : 0)"
        :chart-data="data"
        :options="options" />
      <BarChart
        v-else
        ref="chart"
        :height="height -(config.filtered? 46 : 0)"
        :chart-data="data"
        :options="options" />
    </div>
    <!-- Loading Animation -->
    <v-progress-circular v-else-if="!dataLoaded && !failed" indeterminate color="primary"/>
    <!-- Error Mesages Display -->
    <div v-else-if="failed" style="height: 100%;" class="fill-height overflow-hidden">
      <v-card
        shaped
        align="center"
        style="margin: 4% 8%;"
        class="error--text text-subtitle-1 pa-4 fluid"
      >
        <div v-if="adminView">
          <div class="font-weight-bold"> Errore durante l'estrazione dei dati.</div>
          <br/>
          {{error}}
        </div>
        <div  v-else> Errore Interno, contatta l'amministratore. </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import colors from '@/tools/colors';
import dataTypes from '@/tools/dataTypes';

import BarChart from '@/graphic/components/main/DashboardEditor/widgets/bar_chart/BarChart.vue';
import BarChartHorizontal from '@/graphic/components/main/DashboardEditor/widgets/bar_chart/BarChartHorizontal.vue';
import chartData from '@/tools/chartData';

export default {
  name: 'BarChartWidget',
  components: { BarChartHorizontal, BarChart },
  props: {
    adminView: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    filterState: {
      type: Object,
      required: false,
      default: () => null,
    },
    height: {
      type: Number,
      required: true,
    },
    queryResult: {
      type: Object,
      required: true,
    },
    stack: {
      type: Object,
      required: true,
    },
    stacked: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    widget: {
      type: Object,
      required: true,
    },
    widgetFilters: {
      type: Object,
      required: false,
    },
  },
  computed: {
    // Widget specific config pointer
    config() {
      return this.widget?.config?.bar_chart;
    },
    // Chart options formatted
    options() {
      const scales = {
        x: {
          type: dataTypes.isTime(this.labelDef?.type || '') ? ['time'] : 'text',
        },
        xAxes: [{
          stacked: this.config?.stacked,
        }],
        yAxes: [{
          stacked: this.config?.stacked,
        }],
      };
      const legend = {
        position: 'bottom',
      };
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales,
        legend,
      };
    },
  },
  mounted() {
    this.loadData();
  },
  watch: {
    // On query changes
    queryResult: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
    // On stack config change
    stack: {
      handler() {
        this.handleStackChange(this.stack.col, this.stack.values);
      },
    },
    // On config changes
    'widget.config': {
      deep: true,
      handler() {
        this.loadData();
      },
    },
  },
  methods: {
    // Load the widget data
    loadData() {
      if (!this.config) {
        return;
      }
      if (this.queryResult.failed) {
        this.failed = true;
        this.error = this.queryResult.error;
      } else {
        this.failed = false;
        this.error = null;
        this.buildData();
      }
      this.dataLoaded = true;
    },
    // Construct the chart with the data from filter selection and query results
    buildData() {
      if (!this.queryResult) {
        return;
      }
      this.validateResults();
      if (this.failed) {
        return;
      }
      this.filterRows();
      this.buildChartData();
      this.saveFilters();
    },
    // Reformat data from the filter selection
    handleFilterChange() {
      this.filterRows();
      this.buildChartData();
      this.saveFilters();
    },
    // Change chart options
    handleStackChange(col, values) {
      if (values !== null) {
        const newFilters = this.filterState;
        newFilters[col] = values;
      }
      this.buildChartData(col, values, values !== null);
      this.$emit('resetStack');
    },
    // this produces a 2d array of combinations
    combinations(array) {
      if (!array.length) {
        return [];
      }

      // cut empty array values and wrap non-array values
      // e.g. ['x',[], ['y','z']] becomes [['x'],['y','z']]
      // eslint-disable-next-line no-param-reassign
      array = array
        .filter((item) => item.length > 0)
        .map((item) => (item instanceof Array ? item : [item]));

      // internal recursive function
      function combine(list) {
        let prefixes;
        let combinations;

        if (list.length === 1) {
          return list[0];
        }

        // eslint-disable-next-line prefer-destructuring, prefer-const
        prefixes = list[0];
        // eslint-disable-next-line prefer-const
        combinations = combine(list.slice(1)); // recurse

        // produce a flat list of each of the current
        // set of values prepended to each combination
        // of the remaining sets.
        // eslint-disable-next-line max-len
        return prefixes.reduce((memo, prefix) => memo.concat(combinations.map((combination) => [prefix].concat(combination))), []);
      }

      const resultSet = combine(array);
      return resultSet.map((item) => (item instanceof Array ? item : [item]));
    },
    // Convert raw filtered data list to chart-formatted data
    buildChartData(stackCol, stackValues, stacking = false) {
      const { labelColumn, values } = this.config;
      // Order rows data
      let labels = [];
      const builtRows = [];
      this.rows.forEach((subrows) => {
        const builtSubrows = {};
        subrows.rows.forEach((row) => {
          if (builtSubrows[row[labelColumn]]) {
            values.forEach((tag) => {
              builtSubrows[row[labelColumn]][tag] += row[tag];
            });
          } else {
            builtSubrows[row[labelColumn]] = JSON.parse(JSON.stringify(row));
          }
        });
        const arrRes = [];
        Object.keys(builtSubrows).forEach((key) => {
          arrRes.push(builtSubrows[key]);
        });
        arrRes.sort((a, b) => {
          if (a[labelColumn] < b[labelColumn]) return -1;
          if (a[labelColumn] > b[labelColumn]) return 1;
          return 0;
        });

        if (labels.length === 0) labels = Object.keys(builtSubrows);
        else {
          Object.keys(builtSubrows).forEach((key) => {
            if (!labels.includes(key)) {
              labels.push(key);
            }
          });
        }
        labels.sort();
        builtRows.push({ label: subrows.label, rows: arrRes });
      });
      // Get name of object attribute from filters
      const highlight = Object.keys(this.filterState);
      const rowStats = [];
      builtRows.forEach((row) => {
        if (row.rows.length > 0) {
          const stats = {};
          highlight.forEach((key) => {
            stats[key] = row.rows[0][key];
          });
          rowStats.push(stats);
        }
      });
      // Check and fill 0's to correct data
      let index = 0;
      labels.forEach((label) => {
        let blankRows = 0;
        builtRows.forEach((row, idx) => {
          if (row.rows.length > 0) {
            if (row.rows[index] === undefined || row.rows[index][labelColumn] !== label) {
              const obj = {};
              obj[labelColumn] = label;
              highlight.forEach((key) => {
                obj[key] = rowStats[idx - blankRows][key];
              });
              values.forEach((v) => {
                obj[v] = null;
              });
              if (row.rows[index] === undefined) row.rows.push(obj);
              else row.rows.splice(index, 0, obj);
            }
          } else {
            blankRows += 1;
          }
        });
        index += 1;
      });
      this.rows = builtRows;
      // Stack Section
      const newDataSet = [];
      if (stacking) {
        const clonedRows = [...this.rows];
        const filterValues = Object.entries(this.filterState).map((el) => {
          if (el[0] !== stackCol) {
            return el[1].map((x) => el[0].concat(': ', x));
          }
          return stackCol.concat(': ', stackValues.join('+'));
        });
        const combinedValues = this.combinations(filterValues);
        combinedValues.forEach((cv) => {
          const newRow = [];
          const uniqueNewRow = [];
          clonedRows.forEach((row) => {
            Object.values(row.rows).forEach((column) => {
              // eslint-disable-next-line no-unused-vars
              let belongsToColumn = true;
              cv.forEach((c) => {
                const entry = c.split(': ');
                if ((entry[0] !== stackCol && column[entry[0]] !== entry[1])
                  && (column[entry[0]] === undefined || column[entry[0]] === null)) {
                  belongsToColumn = false;
                }
              });
              if (belongsToColumn) newRow.push({ ...column });
            });
          });
          newRow.forEach((el) => {
            const entries = Object.values(uniqueNewRow);
            let found = false;
            entries.forEach((entry) => {
              if (entry[labelColumn] === el[labelColumn]) {
                values.forEach((v) => {
                  const newValue = entry[v] + el[v];
                  entry[v] = newValue;
                });
                found = true;
              }
            });
            if (!found) uniqueNewRow.push(el);
          });
          newDataSet.push({
            label: cv.join(', ').replace('__', ': '),
            rows: uniqueNewRow,
          });
        });
      }
      const rowsToFetch = stackValues == null ? [...this.rows] : [...newDataSet];
      // Data to chart section
      if (this.rows.length === 1) {
        this.data = {
          labels,
          datasets: values.map((col, idx) => ({
            label: col,
            borderColor: colors.getColor(idx),
            backgroundColor: colors.getColor(idx),
            data: this.rows[0].rows.map((r) => r[col]),
            // filters: Object.entries(this.filterState).map((val) => val),
          })),
        };
      } else {
        let color = -1;
        this.data = {
          labels,
          datasets: rowsToFetch.flatMap((subset) => values.map((col) => {
            color += 1;
            return {
              label: `${subset.label} ${col}`,
              borderColor: colors.getColor(color),
              backgroundColor: colors.getColor(color),
              data: subset.rows.map((r) => r[col]),
              // filters: Object.entries(this.filterState).map((val) => val),
            };
          })),
        };
      }
    },
    // Filter raw data list by the selection of filters
    filterRows() {
      const nofilters = Object.values(this.filterState).every((value) => value.length === 0);
      if (!this.config.filtered || this.config.filterColumns.length === 0 || nofilters) {
        this.rows = [{ label: '', rows: this.queryResult.values }];
      } else {
        this.rows = chartData.filterData(
          this.queryResult.values,
          this.config,
          this.filterState,
        );
      }
    },
    // Check if the selection of Axis, Values and filters is correct
    validateResults() {
      if (!this.queryResult) {
        return;
      }
      const errors = [];
      const { columns } = this.queryResult.metas;
      const { labelColumn, values } = this.config;
      const valuesNames = new Set([...values]);
      [this.labelDef] = columns.filter((c) => c.name === labelColumn);
      const valuesDef = columns.filter((c) => valuesNames.has(c.name));
      const nonNumericValues = valuesDef.filter((i) => !dataTypes.isNumeric(i)).map((i) => i.name);
      if (!this.labelDef) {
        errors.push(`La colonna ${labelColumn} non é presente.`);
      }
      if (valuesDef.length === 0) {
        errors.push('Nessun valore selezionato da mostrare');
      }
      if (nonNumericValues.length > 0) {
        errors.push(`Le colonne ${nonNumericValues.join(',')} non sono di tipo numerico.`);
      }
      this.failed = errors.length !== 0;
      this.error = errors.join('\n');
    },
    // Utility for Filter Default Selections save
    saveFilters() {
      this.$emit('filterSave', { filters: this.filters, filterState: this.filterState });
    },
    // Compare condition utility for 2 checking 2 exact set of keys
    compareKeys(x, y) {
      let key = true;
      Object.keys(x).forEach((ix, index) => {
        if (ix !== Object.keys(y)[index]) key = false;
      });
      return key;
    },
  },
  data() {
    return {
      data: null,
      dataLoaded: false,
      error: null,
      failed: false,
      filters: {},
      labelDef: null,
      rows: [],
    };
  },
};
</script>

<style lang="scss">
</style>
