<template>
  <v-expansion-panel-content>
    <v-form lazy-validation v-model="valid" ref="form">
      <v-select
        v-model="type"
        :items="widgetTypes"
        item-value="type"
        required
        return-object
        label="Tipo">
        <template v-slot:item="data">
            <v-icon class="mr-2">{{data.item.icon}}</v-icon> {{data.item.displayName}}
        </template>
        <template v-slot:selection="data">
          <v-icon class="mr-2">{{data.item.icon}}</v-icon> {{data.item.displayName}}
        </template>
      </v-select>
      <v-text-field
        v-model="value.title"
        label="Titolo"
        required
        @change="updateValue"
        :rules="rules.titleRules"
      />
      <v-textarea
        v-model="value.description"
        rows="2"
        label="Descrizione"
        required
        counter="250"
        @change="updateValue"
        :rules="rules.descriptionRules"
      />
    </v-form>
  </v-expansion-panel-content>
</template>

<script>
export default {
  name: 'GeneralConfigStep',
  props: {
    value: {
      type: Object,
      required: true,
    },
    widgetTypes: {
      type: Array,
      required: true,
    },
  },
  watch: {
    type(newVal, oldVal) {
      if (oldVal && this.value.config[oldVal.type]) {
        delete this.value.config[oldVal.type];
      }
      this.value.config[newVal.type] = { ...newVal.defaultConfig };
      this.value.type = newVal.type;
      this.value.icon = newVal.icon;
      this.updateValue();
    },
  },
  methods: {
    updateValue() {
      this.$emit('input', this.value);
    },
  },
  data() {
    return {
      valid: true,
      type: null,
      rules: {
        titleRules: [(v) => !!v || 'Inserire Titolo'],
        typeRules: [(v) => !!v || 'Selezionare Tipo'],
        descriptionRules: [(v) => (v || '').length <= 250 || 'Massimo 250 caratteri'],
      },
    };
  },
};
</script>
