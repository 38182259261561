<template>
  <div>
    <v-data-table
      :height="height*0.9"
      :headers="tableHeaders"
      :items="tableRows"
      :items-per-page="nRows"
      item-key="name"
    />
  </div>
</template>

<script>
/*
const tableHeaders = [
  { text: 'Username', align: 'start', value: 'userName' },
  { text: 'First Name', value: 'firstName' },
  { text: 'Last Name', value: 'lastName' },
  { text: 'e-mail', value: 'email' },
  { text: '', value: 'data-table-expand' },
]; */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */

export default {
  name: 'WidgetKPI',
  mounted() {
    if (this.data2) this.displayMultiData();
    else this.displayData();
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    data2: {
      type: Array,
      required: false,
      default: () => undefined,
    },
    height: {
      type: Number,
      required: true,
    },
    kpiElements: {
      type: Array,
      required: true,
    },
    filterData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    nRows() {
      const totalH = this.height * 0.9;
      const rows = Math.floor((totalH - 48) / 48);
      return rows;
    },
  },
  methods: {
    displayData() {
      this.tableRows = [];
      if (this.filterData != null) {
        const filters = JSON.parse(JSON.stringify(this.filterData.filterState));
        // eslint-disable-next-line prefer-const
        let res = this.filterArray(this.data, filters);
        const filterCombos = [];
        // eslint-disable-next-line no-unused-vars
        res.forEach((item) => {
          // eslint-disable-next-line no-unused-vars
          const obj = {};
          Object.keys(filters).forEach((key) => {
            obj[key] = item[key];
          });
          let equalFilters = 0;
          filterCombos.forEach((o) => {
            equalFilters = 0;
            Object.keys(o).forEach((k) => {
              // eslint-disable-next-line no-plusplus
              if (o[k] === obj[k]) equalFilters++;
            });
            if (equalFilters === Object.keys(filters).length) {
              o.data.push(item);
            }
          });
          if (equalFilters < Object.keys(filters).length) {
            obj.data = [item];
            filterCombos.push(obj);
          }
        });
        const parsedKPI = JSON.parse(JSON.stringify(this.kpiElements[0]));
        if (Object.keys(filters).length === 0) {
          parsedKPI.forEach((el) => {
            this.tableRows.push({
              name: el,
              min: this.findMin(this.data, el),
              max: this.findMax(this.data, el),
              average: this.findAverage(this.data, el),
            });
          });
        } else {
          filterCombos.forEach((combo) => {
            parsedKPI.forEach((el) => {
              const elName = Object.values(combo).slice(0, -1).join(' + ').concat(' [', el, ']');
              if (this.tableRows.filter((e) => e.name === elName).length === 0) {
                this.tableRows.push({
                  name: elName,
                  min: this.findMin(combo.data, el),
                  max: this.findMax(combo.data, el),
                  average: this.findAverage(combo.data, el),
                });
              }
            });
          });
        }
      }
      this.$emit('data', this.tableRows);
    },
    displayMultiData() {
      this.tableRows = [];
      if (this.filterData != null) {
        const filters = JSON.parse(JSON.stringify(this.filterData.filterState));
        const res = this.filterArray(this.data, filters);
        const filterCombos = [];
        const res2 = this.filterArray(this.data2, filters);
        const filterCombos2 = [];
        // eslint-disable-next-line no-unused-vars
        res.forEach((item) => {
          // eslint-disable-next-line no-unused-vars
          const obj = {};
          Object.keys(filters).forEach((key) => {
            obj[key] = item[key];
          });
          let equalFilters = 0;
          filterCombos.forEach((o) => {
            equalFilters = 0;
            Object.keys(o).forEach((k) => {
              // eslint-disable-next-line no-plusplus
              if (o[k] === obj[k]) equalFilters++;
            });
            if (equalFilters === Object.keys(filters).length) {
              o.data.push(item);
            }
          });
          if (equalFilters < Object.keys(filters).length) {
            obj.data = [item];
            filterCombos.push(obj);
          }
        });
        res2.forEach((item) => {
          // eslint-disable-next-line no-unused-vars
          const obj = {};
          Object.keys(filters).forEach((key) => {
            obj[key] = item[key];
          });
          let equalFilters = 0;
          filterCombos2.forEach((o) => {
            equalFilters = 0;
            Object.keys(o).forEach((k) => {
              // eslint-disable-next-line no-plusplus
              if (o[k] === obj[k]) equalFilters++;
            });
            if (equalFilters === Object.keys(filters).length) {
              o.data.push(item);
            }
          });
          if (equalFilters < Object.keys(filters).length) {
            obj.data = [item];
            filterCombos2.push(obj);
          }
        });
        const parsedKPI = JSON.parse(JSON.stringify(this.kpiElements[0]));
        if (Object.keys(filters).length === 0) {
          parsedKPI.forEach((el) => {
            this.tableRows.push({
              name: el,
              min: this.findMin(this.data, el),
              max: this.findMax(this.data, el),
              average: this.findAverage(this.data, el),
            });
            this.tableRows.push({
              name: el,
              min: this.findMin(this.data2, el),
              max: this.findMax(this.data2, el),
              average: this.findAverage(this.data2, el),
            });
          });
        } else {
          filterCombos.forEach((combo) => {
            parsedKPI.forEach((el) => {
              const elName = Object.values(combo).slice(0, -1).join(' + ').concat(' [', el, '] - set 1');
              if (this.tableRows.filter((e) => e.name === elName).length === 0) {
                this.tableRows.push({
                  name: elName,
                  min: this.findMin(combo.data, el),
                  max: this.findMax(combo.data, el),
                  average: this.findAverage(combo.data, el),
                });
              }
            });
          });
          filterCombos2.forEach((combo) => {
            parsedKPI.forEach((el) => {
              const elName = Object.values(combo).slice(0, -1).join(' + ').concat(' [', el, '] - set 2');
              if (this.tableRows.filter((e) => e.name === elName).length === 0) {
                this.tableRows.push({
                  name: elName,
                  min: this.findMin(combo.data, el),
                  max: this.findMax(combo.data, el),
                  average: this.findAverage(combo.data, el),
                });
              }
            });
          });
        }
      }
      this.$emit('data', this.tableRows);
    },
    findMin(arr, el) {
      const values = [];
      arr.forEach((x) => {
        values.push(x[el]);
      });
      // eslint-disable-next-line prefer-spread
      return Math.min.apply(Math, values);
    },
    findMax(arr, el) {
      const values = [];
      arr.forEach((x) => {
        values.push(x[el]);
      });
      // eslint-disable-next-line prefer-spread
      return Math.max.apply(Math, values);
    },
    findAverage(arr, el) {
      let value = 0;
      arr.forEach((x) => {
        value += x[el];
      });
      return (value / arr.length).toFixed(3);
    },
    filterArray(array, filters) {
      const res = [];
      array.forEach((item) => {
        let canBeAdded = true;
        Object.keys(filters).forEach((key) => {
          const filterValues = Object.values(filters[key]);
          if (!filterValues.includes(item[key])) canBeAdded = false;
        });
        if (canBeAdded) res.push(item);
      });
      return res;
    },
  },
  watch: {
    filterData: {
      deep: true,
      handler() {
        this.displayData();
      },
    },
  },
  data() {
    return {
      items: [],
      tableHeaders: [
        { text: 'Name', align: 'start', value: 'name' },
        { text: 'Min', value: 'min' },
        { text: 'Max', value: 'max' },
        { text: 'Average', value: 'average' },
      ],
      tableRows: [],
    };
  },
};
</script>

<style scoped>

</style>
