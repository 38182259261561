import dashboard from '@/api/admin/dashboard';
import databaseSchema from '@/api/admin/databaseSchema';
import user from '@/api/admin/user';
import widget from '@/api/admin/widget';
import theme from '@/api/admin/theme';
import dataset from '@/api/admin/dataset';

export default {
  dashboard,
  databaseSchema,
  user,
  theme,
  widget,
  dataset,
};
