<template>
  <v-col cols="6" v-if="metas && !adminPanel">
    <!-- <v-select
      v-model="value.labels"
      :items="metas.columns"
      :item-text="(i) => `${i.name}`"
      item-value="name"
      required
      label="Etichetta"
      height="18"
      @change="updateValue"/> -->
  </v-col>
  <v-form v-else-if="metas">
    <v-row>
      <v-col cols="6">
        <v-select
          v-model="value.labels"
          :items="metas.columns"
          :item-text="(i) => `${i.name} (${i.type})`"
          item-value="name"
          required
          label="Etichetta"
          @change="updateValue"/>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="value.values"
          :items="numericValues"
          :item-text="(i) => `${i.name} (${i.type})`"
          item-value="name"
          required
          label="Values"
          @change="updateValue"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-switch
          class="col col-md-6"
          v-model="value.donut"
          @change="updateValue"
          label="Donut"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-switch
          v-model="value.filtered"
          @change="updateValue"
          label="Mostra Filtri"/>
      </v-col>
      <v-col cols="8" v-if="value.filtered">
        <v-combobox
          dense
          v-model="value.filterColumns"
          item-text="name"
          item-value="name"
          @change="updateValue"
          :items="metas.columns"
          label="Colonne filtrabili"
          multiple
          small-chips
        />
      </v-col>
    </v-row>
    <v-row>
      <v-spacer/>
      <v-btn class="rounded-pill primary font-weight-bold white--text py-1 px-2 mb-1 mr-3"
        @click="saveDefaults">
        Salva la selezione come Default
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import dataTypes from '@/tools/dataTypes';

export default {
  name: 'PieChartConfig',
  props: {
    value: {
      type: Object,
      required: true,
    },
    dataset: {
      type: Object,
      required: true,
    },
    metas: {
      type: Object,
      required: false,
    },
    adminPanel: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  watch: {
    metas(val) {
      this.metadata = val;
    },
    value: {
      deep: true,
      handler() {
        this.values = this.value.values;
      },
    },
  },
  computed: {
    // Returns the list of columns that have numeric values
    numericValues() {
      if (!this.metas?.columns) {
        return [];
      }
      return this.metas.columns
        .filter((item) => dataTypes.isNumeric(item.type));
    },
    // Returns the list of column names
    labels() {
      if (!this.metas?.columns) {
        return [];
      }
      return this.metas.columns;
    },
    // Returns the list of columns that have Textbased values
    textValues() {
      if (!this.metas?.columns) {
        return [];
      }
      return this.metas.columns
        .filter((item) => dataTypes.isText(item.type));
    },
  },
  methods: {
    updateValue() {
      this.$emit('input', this.value);
    },
    // Set the current in-widget selected filter values as its default values
    saveDefaults() {
      this.$emit('saveDef');
    },
  },
  data() {
    return {
      values: null,
    };
  },
};
</script>

<style scoped>

</style>
