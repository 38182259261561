const test = async (dataset, params) => {
  const response = await window.axios.post('/graphic/dataset/test', dataset, { params });
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const save = async (dataset) => {
  let response;
  if (!dataset.id) {
    response = await window.axios.post('/graphic/dataset', dataset);
  } else {
    response = await window.axios.put(`/graphic/dataset/${dataset.id}`, dataset);
  }
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const metadata = async (datasetId, params) => {
  const response = await window.axios.get(`/graphic/dataset/${datasetId}/metadata`, { params });
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const insertTableRow = async (tableName, item, schemaId) => {
  const response = await window.axios.post(`/graphic/dataset/insert/${tableName}/${schemaId}`, item);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const updateTableRow = async (tableName, id, item, schemaId) => {
  const response = await window.axios.post(`/graphic/dataset/update/${tableName}/${id}/${schemaId}`, item);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const updateTableBulk = async (tableName, item, schemaId) => {
  const response = await window.axios.post(`/graphic/dataset/update-bulk/${tableName}/${schemaId}`, item);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const deleteTableRow = async (tableName, id, schemaId) => {
  const response = await window.axios.post(`/graphic/dataset/delete/${tableName}/${id}/${schemaId}`);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

export default {
  test,
  save,
  metadata,
  insertTableRow,
  updateTableRow,
  updateTableBulk,
  deleteTableRow,
};
