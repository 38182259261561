<template>
  <v-dialog v-model="dialog" width="700">
    <template v-slot:activator="{on, attrs}">
      <v-btn small rounded
             color="primary"
             v-bind="attrs"
             v-on="on">
        Aggiungi Widget
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        Aggiungi un Widget
      </v-card-title>
      <v-card-text>
        <WidgetBuilder
          ref="builder"
          :schema="schema"
          :position="position"
          @created="handleCreation"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '@/api';
import WidgetBuilder from '@/graphic/components/main/DashboardEditor/builder/WidgetBuilder.vue';

export default {
  name: 'WidgetSelector',
  components: { WidgetBuilder },
  props: {
    schema: {
      type: Object,
      required: true,
    },
    position: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.loadWidgets();
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.$refs.builder.reset();
      }
    },
  },
  methods: {
    async loadWidgets() {
      this.widgets = await api.graphic.widget.list();
    },
    handleSelection() {
      this.dialog = false;
      this.$emit('selected', this.selectedWidget);
    },
    handleCreation(widget) {
      this.dialog = false;
      this.$emit('selected', widget);
    },
  },
  data() {
    return {
      dialog: false,
      tab: 0,
      selectedWidget: null,
      loading: false,
      widgets: [],
    };
  },
};
</script>

<style scoped>

</style>
