<template>
  <div style="position: relative">
    <codemirror
      ref="codemirror"
      v-if="tableLoaded && !loading"
      v-model="value"
      @input="onInput"
      :options="cmOptions"/>
    <v-progress-circular indeterminate v-else color="primary"/>
    <span v-if="error">{{error}}</span>
  </div>
</template>

<script>
import api from '@/api';

export default {
  name: 'QueryInput',
  props: {
    value: {
      type: String,
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.loadTables();
  },
  methods: {
    onInput(value) {
      if (!value) {
        this.error = 'Value should not be empty';
      }
      const params = Array.from(value.matchAll(/:([\w]+)/gi)).map((a) => a[1]);
      this.$emit('input', value, params);
    },
    async loadTables() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const tables = await api.admin.databaseSchema.listTables(this.schema.id);
      this.cmOptions.hintOptions.tables = tables.reduce((acc, item) => {
        // eslint-disable-next-line no-param-reassign
        acc[item.name] = item.columns;
        return acc;
      }, {});
      this.loading = false;
      this.tableLoaded = true;
    },
  },
  data() {
    return {
      error: null,
      loading: false,
      tableLoaded: false,
      cmOptions: {
        matchBrackets: true,
        showCursorWhenSelecting: true,
        height: 'auto',
        width: '752',
        mode: 'text/x-mysql',
        theme: 'mbo',
        smartIndent: true,
        line: true,
        fixedGutter: false,
        lineNumbers: true,
        extraKeys: {
          'Ctrl-Space': 'autocomplete',
          'Ctrl-Enter': () => { this.$emit('run'); },
        },
        autoRefresh: true,
        lineWrapping: true,
        htmlMode: true,
        matchClosing: true,
        indentWithTabs: true,
        hintOptions: {
          tables: {},
        },
      },
    };
  },
};
</script>

<style lang="scss">
  main {
    position: relative;
  }
</style>
